import { mergeMap, startWith, concatMap, catchError } from 'rxjs/operators';
import { from } from 'rxjs';
import { ofType } from 'redux-observable';
import { setLoader, handleErrors, setError } from './deps';

import {
  BALANCE_REQUEST,
  BONUS_CARD_INFO_REQUEST,
  CONCESSIONS_REQUEST,
  ORDER_CONCESSION_REQUEST,
  REWARDS_REQUEST
} from './types';

import {
  balanceSuccess,
  bonusCardInfoSuccess,
  concessionsSuccess,
  orderConcessionSuccess,
  rewardsSuccess
} from './actions';

function fetchRewards($action, $state, { api }) {
  const $apiBonuscard = api.getModuleByName('bonuscard');

  return $action.pipe(
    ofType(REWARDS_REQUEST),
    mergeMap(action =>
      from($apiBonuscard.getRewards()).pipe(
        concatMap(res => [rewardsSuccess(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      )
    )
  );
}

const fetchBalance = ($action, $state, { api }) => {
  const $apiBonuscard = api.getModuleByName('bonuscard');

  return $action.pipe(
    ofType(BALANCE_REQUEST),
    mergeMap(action =>
      from($apiBonuscard.getBalance()).pipe(
        concatMap(res => [balanceSuccess(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      )
    )
  );
};

const fetchConcessions = ($action, $state, { api }) => {
  const $apiBonuscard = api.getModuleByName('bonuscard');

  return $action.pipe(
    ofType(CONCESSIONS_REQUEST),
    mergeMap(action =>
      from($apiBonuscard.getBalanceConcessions()).pipe(
        concatMap(res => [concessionsSuccess(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      )
    )
  );
};

const fetchOrderConcession = ($action, $state, { api }) => {
  const $apiBonuscard = api.getModuleByName('bonuscard');

  return $action.pipe(
    ofType(ORDER_CONCESSION_REQUEST),
    mergeMap(action => {
      const {
        payload: { concessionId, notifyForm }
      } = action;

      return from($apiBonuscard.orderConcession(concessionId)).pipe(
        concatMap(res => {
          notifyForm();

          return [orderConcessionSuccess(res), setLoader(false)];
        }),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      );
    })
  );
};

function fetchBonusCardInfoContent($action, $state, { api }) {
  const $apiBonuscardInfo = api.getModuleByName('bonusCardInfo');

  return $action.pipe(
    ofType(BONUS_CARD_INFO_REQUEST),
    mergeMap(action =>
      from($apiBonuscardInfo.fetchAll()).pipe(
        concatMap(res => [bonusCardInfoSuccess(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      )
    )
  );
}

export {
  fetchRewards,
  fetchBalance,
  fetchConcessions,
  fetchOrderConcession,
  fetchBonusCardInfoContent
};
