export const GIFTCARDS_REQUEST = 'giftcards/GIFTCARDS_REQUEST';
export const GIFTCARDS_SUCCESS = 'giftcards/GIFTCARDS_SUCCESS';

export const ADD_GIFTCARD_REQUEST = 'giftcards/ADD_GIFTCARD_REQUEST';
export const ADD_GIFTCARD_SUCCESS = 'giftcards/ADD_GIFTCARD_SUCCESS';

export const ADD_GIFTCARD_REQUEST_LOGOUT = 'giftcards/ADD_GIFTCARD_REQUEST_LOGOUT';
export const ADD_GIFTCARD_REQUEST_LOGOUT_SUCCESS = 'giftcards/ADD_GIFTCARD_REQUEST_LOGOUT_SUCCESS';

export const ADD_GIFTCARD_REQUEST_TICKET = 'giftcards/ADD_GIFTCARD_REQUEST_TICKET';
export const ADD_GIFTCARD_REQUEST_TICKET_SUCCESS = 'giftcards/ADD_GIFTCARD_REQUEST_TICKET_SUCCESS';

export const RESTRICTIONS_REQUEST = 'giftcards/RESTRICTIONS_REQUEST';
export const RESTRICTIONS_SUCCESS = 'giftcards/RESTRICTIONS_SUCCESS';

export const GIFT_CARD_LOCATION_REQUEST = 'giftcards/GIFT_CARD_LOCATION_REQUEST';
export const GIFT_CARD_LOCATION_SET = 'giftcards/GIFT_CARD_LOCATION_SET';

export const CINEMAS_WITH_MOVIES_REQUEST_LOCATION = 'cinemas/CINEMAS_WITH_MOVIES_REQUEST_LOCATION';
export const SET_CINEMAS_WITH_MOVIES_GET_LOCATION = 'cinemas/SET_CINEMAS_WITH_MOVIES_GET_LOCATION';

export const DATES_REQUEST = 'cinemas/DATES_REQUEST';
export const SET_DATES = 'cinemas/SET_DATES';

export const REQUEST_CONTENT_DATA = 'cinemas/REQUEST_CONTENT_DATA';
export const REQUEST_CONTENT_DATA_SUCCESS = 'cinemas/REQUEST_CONTENT_DATA_SUCCESS';

export const GIFTCARD_SESSION_CLEAR = 'cinemas/GIFTCARD_SESSION_CLEAR';

export const GIFTCARD_SET_AGAIN = 'cinemas/GIFTCARD_SET_AGAIN';