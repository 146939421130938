import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { linkNormal } from 'app/theme';

const propTypes = {
  click: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.string.isRequired])
    .isRequired
};

const Button = styled.button`
  font-size: 1.6rem;
  ${linkNormal}
`;

const LinkAction = ({ click, children }) => (
  <Button type="button" onClick={click}>
    {children}
  </Button>
);

LinkAction.propTypes = propTypes;
export default React.memo(LinkAction);
