import { baseCss } from 'app/theme';
import config from './config';
import svgSprite from './svgSprite';
import 'react-pagination-bar/dist/index.css';

const env = process.env.REACT_APP_ENV;
const { rootElementId, browserError, logo } = config;
const appElement = document.createElement('div');
const stylesheet = document.createElement('style');
const error = document.createElement('div');

appElement.setAttribute('id', rootElementId);
document.body.appendChild(svgSprite(logo));
document.body.appendChild(appElement);

stylesheet.innerHTML = baseCss;
document.head.appendChild(stylesheet);

error.style.textAlign = 'center';
error.innerText = browserError;
appElement.appendChild(error);

/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable array-callback-return  */
if (env !== 'production') {
  document.title += ` - v${require('../package.json').version}`;
}

require('./app').default(config, appElement);
/* eslint-enable */
