import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Text = styled.span`
  display: inline-block;
  vertical-align: middle;
  min-width: 48px;
  padding: 0 12px;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1;
  text-align: center;
`;

export const Btn = styled.button.attrs({
  type: 'button'
})`
  vertical-align: middle;
  width: 24px;
  height: 24px;
  border-radius: 50%;

  & > svg {
    width: 100%;
    height: 100%;
    fill: ${({ theme }) => theme.color.accent};
    transition: fill 0.2s ease-out, box-shadow 0.2s ease-out;
  }

  &:hover {
    & > svg {
      fill: ${({ theme }) => theme.color.accentDark};
    }
  }

  &:disabled > svg {
    fill: ${({ theme }) => theme.color.rgbaWhite('.2')};
  }
`;
