import {
  PLAN_REQUEST,
  SET_PLAN,
  CLEAR_PLAN,
  LEGEND_REQUEST,
  SET_LEGEND,
  CLEAR_LEGEND
} from './types';

/**
 * Actions for Sitting Plan
 */
export const planRequest = (cinemaId, sessionId, orderId) => ({
  type: PLAN_REQUEST,
  payload: {
    cinemaId,
    sessionId,
    orderId
  }
});
export const setPlan = plan => ({
  type: SET_PLAN,
  payload: {
    plan
  }
});
export const clearPlan = () => ({
  type: CLEAR_PLAN
});

/**
 * Actions for Sitting Plan Legend
 */
export const legendRequest = (cinemaId, sessionId) => ({
  type: LEGEND_REQUEST,
  payload: {
    cinemaId,
    sessionId
  }
});
export const setLegend = legend => ({
  type: SET_LEGEND,
  payload: {
    legend
  }
});
export const clearLegend = () => ({
  type: CLEAR_LEGEND
});
