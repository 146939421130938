import styled from 'styled-components';

export const ContentWrapper = styled.div`
  .buttons-row {
    left: 0;
    right: 0;
  }

  .b-image-with-loader__inner {
    padding-top: 56.3%;
  }
`;

export const CloseBtn = styled.button`
  width: 33px;
  height: 33px;
  position: absolute;
  top: ${({ theme }) => theme.indent.s};
  right: ${({ theme }) => theme.indent.s};
  z-index: 1;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: rgba(25, 25, 25, 0.9);

  & > .svg-close {
    fill: ${({ theme }) => theme.color.rgbaWhite('.8')};
    width: 24px;
    height: 24px;
    transition: fill 0.2s ease-out;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  &:hover > .svg-close {
    fill: ${({ theme }) => theme.color.rgbaWhite('1')};
  }
`;
