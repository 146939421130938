import { catchError, mergeMap, concatMap, startWith } from 'rxjs/operators';
import { from } from 'rxjs';
import { ofType } from 'redux-observable';

import { setLoader, handleErrors, setError } from './deps';
import { CINEMA_RENT_INQUIRY_REQUEST_REQUEST, CINEMA_RENT_REQUEST } from './types';
import { cinemaRentSuccess, sendCinemaRentInquirySuccess } from './actions';

export const saveCinemaRentInquiry = ($action, $state, { api }) => {
  const $apiCinemaRentInquiry = api.getModuleByName('cinemaRentInquiry');

  return $action.pipe(
    ofType(CINEMA_RENT_INQUIRY_REQUEST_REQUEST),
    mergeMap(action => {
      const {
        payload: { body }
      } = action;

      return from($apiCinemaRentInquiry.sendInquiry(body)).pipe(
        concatMap(res => [sendCinemaRentInquirySuccess(res), setLoader(false)]),

        ...handleErrors(action),

        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      );
    })
  );
};

export const fetchCinemaRent = ($action, $state, { api }) => {
  const $apiCinemaRent = api.getModuleByName('cinemaRent');

  return $action.pipe(
    ofType(CINEMA_RENT_REQUEST),
    mergeMap(action => {
      return from($apiCinemaRent.fetchAll()).pipe(
        concatMap(res => [cinemaRentSuccess(res), setLoader(false)]),

        ...handleErrors(action),

        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      );
    })
  );
};
