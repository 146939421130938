import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { TimePicker } from 'app/components';
import Input from '../Input';

const propTypes = {
  id: PropTypes.string.isRequired,

  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,

  value: PropTypes.instanceOf(Date),
  inputValue: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string
};

const defaultProps = {
  inputValue: null,
  value: null,
  error: null,
  placeholder: null
};

const InputCalendar = ({
  id,
  value,
  inputValue,
  error,
  onChange,
  placeholder,
  maxDate,
  ...props
}) => {
  const [isOpen, setVisibility] = useState(false);
  const onCloseTimePicker = useCallback(() => setVisibility(false), []);
  const onOpenTimePicker = useCallback(() => setVisibility(true), []);

  return (
    <React.Fragment>
      <Input
        {...props}
        readOnly
        id={id}
        value={inputValue}
        onClick={onOpenTimePicker}
        error={error}
        placeholder={placeholder}
      />

      <TimePicker value={value} isOpen={isOpen} onClose={onCloseTimePicker} onChange={onChange} />
    </React.Fragment>
  );
};

InputCalendar.propTypes = propTypes;
InputCalendar.defaultProps = defaultProps;
export default InputCalendar;
