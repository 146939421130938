import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';

import layout from './layout';
import auth from './auth';
import cinemas from './cinemas';
import movies from './movies';
import watchlist from './watchlist';
import user from './user';
import sessions from './sessions';
import order from './order';
import payment from './payment';
import bookings from './bookings';
import search from './search';
import information from './information';
import pdf from './pdf';
import seatPlan from './seatPlan';
import events from './events';
import giftcards from './giftcards';
import bonucard from './bonuscard';
import technologies from './technologies';
import advertising from './advertising';
import cinemaRent from './cinemaRent';
import portrait from './portrait';
import survey from './survey';
import familyFilmClub from './familyFilmClub';
import newsletter from './newsletter';
import sessionsTickets from './sessionsTickets';


export const epics = combineEpics(
  ...Object.values(layout.epics),
  ...Object.values(auth.epics),
  ...Object.values(cinemas.epics),
  ...Object.values(movies.epics),
  ...Object.values(watchlist.epics),
  ...Object.values(user.epics),
  ...Object.values(sessions.epics),
  ...Object.values(order.epics),
  ...Object.values(payment.epics),
  ...Object.values(bookings.epics),
  ...Object.values(search.epics),
  ...Object.values(information.epics),
  ...Object.values(pdf.epics),
  ...Object.values(seatPlan.epics),
  ...Object.values(events.epics),
  ...Object.values(giftcards.epics),
  ...Object.values(bonucard.epics),
  ...Object.values(technologies.epics),
  ...Object.values(advertising.epics),
  ...Object.values(cinemaRent.epics),
  ...Object.values(portrait.epics),
  ...Object.values(survey.epics),
  ...Object.values(familyFilmClub.epics),
  ...Object.values(newsletter.epics),
  ...Object.values(sessionsTickets.epics)
);

export const reducers = combineReducers({
  [layout.NAME]: layout.reducer,
  [cinemas.NAME]: cinemas.reducer,
  [movies.NAME]: movies.reducer,
  [watchlist.NAME]: watchlist.reducer,
  [user.NAME]: user.reducer,
  [sessions.NAME]: sessions.reducer,
  [order.NAME]: order.reducer,
  [payment.NAME]: payment.reducer,
  [bookings.NAME]: bookings.reducer,
  [search.NAME]: search.reducer,
  [information.NAME]: information.reducer,
  [pdf.NAME]: pdf.reducer,
  [seatPlan.NAME]: seatPlan.reducer,
  [events.NAME]: events.reducer,
  [giftcards.NAME]: giftcards.reducer,
  [bonucard.NAME]: bonucard.reducer,
  [technologies.NAME]: technologies.reducer,
  [advertising.NAME]: advertising.reducer,
  [cinemaRent.NAME]: cinemaRent.reducer,
  [portrait.NAME]: portrait.reducer,
  [survey.NAME]: survey.reducer,
  [familyFilmClub.NAME]: familyFilmClub.reducer,
  [newsletter.NAME]: newsletter.reducer,
  [sessionsTickets.NAME]: sessionsTickets.reducer
});
