export default `
  <symbol id="svg-logo" viewBox="0 0 100 44">
   <path fill="#DE1619" fill-rule="evenodd" d="M96.658 21.809l-7.965 10.479.306-4.769h-3.486l.023 7.913-6.837 8.431h3.487l3.745-5.05-.425 5.05h3.592l-.075-8.431L100 21.809h-3.342zM76.497 35.396l6.64-7.912h-3.342l-3.629 4.767.308-4.767h-3.488l.023 7.912-6.837 8.43h3.487l3.746-5.05-.424 5.05h3.591l-.075-8.43zm-7.204-4.878l1.265-3.034H64.33l-6.814 16.343h6.268l1.266-3.035h-3.176l1.536-3.684h2.761l1.265-3.035h-2.76l1.482-3.555h3.134zm-.351-24.2c0-.672-.541-1.219-1.208-1.219-.668 0-1.208.547-1.208 1.219 0 .675.54 1.219 1.208 1.219.667 0 1.208-.544 1.208-1.219zm-.458 7.375a62.14 62.14 0 0 0-4.588-.043c-1.154-1.41-8.417-9.136-24.643-3.177 11.205-3.638 17.163-1.175 21.4 3.33-14.426 1.003-27.164 5.963-27.164 5.963 18.118-5.728 34.995-6.073 34.995-6.073zM56.422.643a.64.64 0 0 0-1.277 0c0 .357.287.646.64.646.35 0 .637-.289.637-.645zM44.3 2.055a.949.949 0 0 0-.943-.951.948.948 0 0 0-.942.951c0 .526.424.951.942.951a.949.949 0 0 0 .943-.95zM33.664 12.108c0-.837-.676-1.52-1.51-1.52-.83 0-1.505.683-1.505 1.52 0 .843.674 1.525 1.506 1.525.833 0 1.509-.682 1.509-1.525zm-5.237 15.376l-3.904 9.862.25-9.862h-2.967L14.99 43.827h3.093l3.93-9.819-.235 9.819h2.928l6.814-16.343h-3.094zM11.631 43.827l6.814-16.343h-3.093L8.538 43.827h3.093zm-.297-13.266l1.238-2.97a3.507 3.507 0 0 0-1.451-.303c-4.213 0-8.522 4.162-10.213 8.215C-.881 39.795-.164 44 4.298 44a4.52 4.52 0 0 0 1.55-.28l1.275-3.058c-.45.132-.888.238-1.282.238-2.7 0-2.635-2.99-1.623-5.418 1.048-2.514 3.307-5.093 6.026-5.093.394 0 .74.063 1.09.172zm16.712 13.266h6.268l1.266-3.035h-3.177l1.536-3.684h2.762l1.265-3.035h-2.76l1.48-3.555h3.136l1.264-3.034H34.86l-6.813 16.343zm22.63-11.51c2.195-5.266-2.635-4.847-3.3-4.847l-3.218.013-6.814 16.344h3.093l2.81-6.741.668.043c2.865 0 5.486-1.756 6.76-4.812zm-3.075-.043c-.696 1.669-1.792 2.255-3.26 2.19l1.8-4.314h.58c1.33 0 1.367.954.88 2.124zm7.827-4.79l-6.813 16.343h6.33l1.265-3.035h-3.237l5.548-13.308h-3.093z"/>
 </symbol>
 
 <symbol id="svg-logo-md"  viewBox="0 0 90 20">
  <path fill="#DE1619" fill-rule="evenodd" d="M86.992 0l-7.168 9.444.275-4.297h-3.137l.02 7.13-6.153 7.6h3.139l3.37-4.552-.382 4.552h3.232l-.067-7.6L90 0h-3.008zM68.847 12.245l5.976-7.13h-3.008L68.55 9.41l.276-4.297h-3.138l.02 7.131-6.153 7.599h3.138l3.371-4.551-.381 4.551h3.232l-.068-7.599zM62.364 7.85l1.138-2.735h-5.604l-6.133 14.73h5.641l1.14-2.735h-2.859l1.383-3.32h2.485l1.139-2.736h-2.485l1.334-3.204h2.82zm-36.78-2.735l-3.514 8.889.226-8.889h-2.67l-6.134 14.73h2.784l3.536-8.85-.21 8.85h2.634l6.133-14.73h-2.785zm-15.116 14.73L16.6 5.114h-2.783l-6.133 14.73h2.784zm-.267-11.956l1.114-2.676a3.152 3.152 0 0 0-1.306-.274c-3.792 0-7.67 3.751-9.192 7.404C-.793 16.21-.148 20 3.868 20c.45 0 .911-.078 1.396-.253l1.146-2.755c-.404.118-.798.214-1.153.214-2.43 0-2.372-2.696-1.46-4.883.943-2.266 2.975-4.59 5.423-4.59.354 0 .666.057.98.155zm15.04 11.956h5.642l1.139-2.735h-2.859l1.382-3.32h2.486l1.138-2.736h-2.484l1.333-3.204h2.821l1.139-2.735h-5.604l-6.133 14.73zM45.608 9.471c1.976-4.747-2.371-4.37-2.97-4.37l-2.896.013-6.132 14.73h2.784l2.529-6.076.6.04c2.58 0 4.938-1.583 6.085-4.337zm-2.768-.04c-.626 1.505-1.612 2.033-2.933 1.974l1.62-3.888h.521c1.197 0 1.231.86.792 1.915zm7.046-4.317l-6.133 14.73h5.697l1.14-2.735h-2.914l4.993-11.995h-2.783z"/>
</symbol>

  <symbol id="svg-logo-new" viewBox="0 0 1560 353">
    <path fill="#DF1B12" fill-rule="evenodd" d="M868.6,91.9L764.4,339.3h96.8l19.3-45.9h-49.5l84.8-201.4H868.6L868.6,91.9z M748.9,164.4
    c-10.6,25.3-27.4,34.1-49.8,33.1l27.5-65.3h8.9C755.8,132.3,756.4,146.7,748.9,164.4 M795.9,165.1c33.6-79.7-40.3-73.4-50.4-73.4
    l-49.2,0.2L592.1,339.3h47.3l43-102l10.2,0.7C736.4,237.9,776.4,211.3,795.9,165.1 M449.9,339.3h95.8l19.4-45.9h-48.6l23.5-55.8
    h42.2l19.4-45.9h-42.2l22.6-53.8H630l19.3-45.9h-95.2L449.9,339.3L449.9,339.3z M194.4,138.5l18.9-44.9c-6.7-2.9-13.9-4.6-22.2-4.6
    c-64.4,0-130.3,63-156.2,124.4c-27.3,65-16.4,128.6,51.8,128.6c7.6,0,15.5-1.3,23.7-4.3l19.5-46.3c-6.9,2-13.6,3.6-19.6,3.6
    c-41.3,0-40.3-45.3-24.8-82c16-38.1,50.6-77.1,92.1-77.1C183.8,135.9,189.1,136.8,194.4,138.5 M199,339.3L303.2,91.9h-47.3
    L151.7,339.3H199L199,339.3z M455.8,91.9l-59.7,149.3l3.8-149.3h-45.4L250.4,339.3h47.3l60.1-148.6l-3.6,148.6h44.8L503.1,91.9
    H455.8L455.8,91.9z M1080.6,137.8l19.3-45.9h-95.2L900.5,339.3h95.8l19.4-45.9h-48.6l23.5-55.8h42.2l19.3-45.9H1010l22.7-53.8
    H1080.6L1080.6,137.8z M1190.7,211.7l101.5-119.8h-51.1l-55.5,72.2l4.7-72.2H1137l0.3,119.8l-104.5,127.6h53.3l57.3-76.4l-6.5,76.4
    h54.9L1190.7,211.7L1190.7,211.7z M1499,6l-121.8,158.6l4.7-72.2h-53.3l0.3,119.8l-104.5,127.6h53.3l57.3-76.4l-6.5,76.4h54.9
    l-1.1-127.6L1550.1,6H1499L1499,6z"/>
  </symbol>

  <symbol id="svg-logo-new-md" viewBox="0 0 1560 353">
    <path fill="#DF1B12" fill-rule="evenodd" d="M868.6,91.9L764.4,339.3h96.8l19.3-45.9h-49.5l84.8-201.4H868.6L868.6,91.9z M748.9,164.4
    c-10.6,25.3-27.4,34.1-49.8,33.1l27.5-65.3h8.9C755.8,132.3,756.4,146.7,748.9,164.4 M795.9,165.1c33.6-79.7-40.3-73.4-50.4-73.4
    l-49.2,0.2L592.1,339.3h47.3l43-102l10.2,0.7C736.4,237.9,776.4,211.3,795.9,165.1 M449.9,339.3h95.8l19.4-45.9h-48.6l23.5-55.8
    h42.2l19.4-45.9h-42.2l22.6-53.8H630l19.3-45.9h-95.2L449.9,339.3L449.9,339.3z M194.4,138.5l18.9-44.9c-6.7-2.9-13.9-4.6-22.2-4.6
    c-64.4,0-130.3,63-156.2,124.4c-27.3,65-16.4,128.6,51.8,128.6c7.6,0,15.5-1.3,23.7-4.3l19.5-46.3c-6.9,2-13.6,3.6-19.6,3.6
    c-41.3,0-40.3-45.3-24.8-82c16-38.1,50.6-77.1,92.1-77.1C183.8,135.9,189.1,136.8,194.4,138.5 M199,339.3L303.2,91.9h-47.3
    L151.7,339.3H199L199,339.3z M455.8,91.9l-59.7,149.3l3.8-149.3h-45.4L250.4,339.3h47.3l60.1-148.6l-3.6,148.6h44.8L503.1,91.9
    H455.8L455.8,91.9z M1080.6,137.8l19.3-45.9h-95.2L900.5,339.3h95.8l19.4-45.9h-48.6l23.5-55.8h42.2l19.3-45.9H1010l22.7-53.8
    H1080.6L1080.6,137.8z M1190.7,211.7l101.5-119.8h-51.1l-55.5,72.2l4.7-72.2H1137l0.3,119.8l-104.5,127.6h53.3l57.3-76.4l-6.5,76.4
    h54.9L1190.7,211.7L1190.7,211.7z M1499,6l-121.8,158.6l4.7-72.2h-53.3l0.3,119.8l-104.5,127.6h53.3l57.3-76.4l-6.5,76.4h54.9
    l-1.1-127.6L1550.1,6H1499L1499,6z"/>
  </symbol>
`;
