import BaseAPI from '../BaseAPI';

class BonusCardInfo extends BaseAPI {
  constructor(props) {
    super(props);
    this.baseUrl = `/list?country=${process.env.REACT_APP_COUNTRY?.toUpperCase()}`;
  }

  /**
   * Gets a list of competitions
   *
   * @returns {Promise<*|Error>}
   */
  fetchAll() {
    const url = `/bonus-card-info${this.baseUrl}`;

    return this.get({ url });
  }
}

export default BonusCardInfo;
