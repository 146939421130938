import {
  TOP_MOVIES_REQUEST,
  NOW_PLAYING_MOVIES_REQUEST,
  UPCOMING_MOVIES_REQUEST,
  MOVIES_FETCH_SUCCESS,
  SET_UPCOMING_MOVIES,
  MOVIES_SET_DATE,
  MOVIES_RESET_STATE,
  MOVIES_FILTER,
  MOVIES_FILTER_SUCCESS,
  SET_DAYS_SESSIONS,
  MOVIE_REQUEST,
  SET_MOVIE,
  DATE_LIST_REQUEST,
  SET_DATE_LIST,
  SESSIONS_REQUEST,
  SET_SESSIONS,
  NEW_SESSIONS_REQUEST,
  SET_NEW_SESSIONS,
  DAYS_SESSIONS_REQUEST,
  EVENTS_SESSIONS_REQUEST,
  ADVERTISEMENT_REQUEST,
  ADVERTISEMENT_SUCCESS,
  EVENT_WEB_SEND,
  EVENT_WEB_SEND_SUCCESS
} from './types';

export const requestTopMovies = params => ({
  type: TOP_MOVIES_REQUEST,
  payload: { params }
});

export const requestNowPlayingMovies = params => ({
  type: NOW_PLAYING_MOVIES_REQUEST,
  payload: { params }
});
export const requestUpcomingMovies = params => ({
  type: UPCOMING_MOVIES_REQUEST,
  payload: { params }
});

export const successMoviesRequest = data => ({
  type: MOVIES_FETCH_SUCCESS,
  payload: data
});
export const setUpcomingMovies = ({ films, upcomingDates }) => ({
  type: SET_UPCOMING_MOVIES,
  payload: { films, upcomingDates }
});
export const moviesFilter = (pathname, id, title) => ({
  type: MOVIES_FILTER,
  payload: { pathname, id, title }
});
export const moviesFilterSuccess = (movies) => ({
  type: MOVIES_FILTER_SUCCESS,
  payload: { movies }
});
export const setDate = date => ({
  type: MOVIES_SET_DATE,
  payload: {
    date
  }
});

export const resetStateMovies = () => ({
  type: MOVIES_RESET_STATE
});

export const requestEventSessions = ({ eventId, movieId }) => ({
  type: EVENTS_SESSIONS_REQUEST,
  payload: {
    eventId,
    movieId
  }
});
export const requestDaysSessions = ({ movieId, location }) => ({
  type: DAYS_SESSIONS_REQUEST,
  payload: {
    movieId,
    location
  }
});
export const setDaysSessions = sessions => ({
  type: SET_DAYS_SESSIONS,
  payload: {
    sessions
  }
});

export const requestMovie = id => ({
  type: MOVIE_REQUEST,
  payload: {
    id
  }
});
export const setMovie = movie => ({
  type: SET_MOVIE,
  payload: {
    movie
  }
});

export const requestDateList = ({ movieId, cinemaId }) => ({
  type: DATE_LIST_REQUEST,
  payload: {
    movieId,
    cinemaId
  }
});
export const setDateList = dates => ({
  type: SET_DATE_LIST,
  payload: {
    dates
  }
});

export const requestSessions = ({ movieId, date }) => ({
  type: SESSIONS_REQUEST,
  payload: {
    movieId,
    date
  }
});

export const requestNewSessions = ({ movieId, eventId }) => ({
  type: NEW_SESSIONS_REQUEST,
  payload: {
    movieId,
    eventId
  }
});

export const setNewSessions = sessions => ({
  type: SET_NEW_SESSIONS,
  payload: {
    sessions
  }
});

export const setSessions = sessions => ({
  type: SET_SESSIONS,
  payload: {
    sessions
  }
});

export const advertiseBanner = () => ({
  type: ADVERTISEMENT_REQUEST,
});

export const setAdvertisementData = params => ({
  type: ADVERTISEMENT_SUCCESS,
  payload: { params }
});

export const eventsWebFetchGet = (params) => ({
  type: EVENT_WEB_SEND,
  payload: { params }
});

export const setWebEventData = eventWeb => ({
  type: EVENT_WEB_SEND_SUCCESS,
  payload: { eventWeb }
});