import 'mdn-polyfills/Object.values';
import 'mdn-polyfills/Array.prototype.includes';
import 'mdn-polyfills/Array.prototype.find';
import 'mdn-polyfills/CustomEvent';
import 'url-search-params-polyfill';

const isIe11 = document.documentMode === 11;

if (isIe11) {
  Object.preventExtensions = null;
}
