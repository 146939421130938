import styled from 'styled-components';
import { textTransform } from 'utils';

export default styled.h4`
  color: ${({ theme }) => theme.color.accent};
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: ${textTransform};
`;
