import BaseAPI from '../BaseAPI';

class Events extends BaseAPI {
  constructor(props) {
    super(props);
    this.baseUrl = '/events';
  }

  /**
   * Gets a list of events
   *
   * @returns {Promise<*|Error>}
   */
  fetchAll(params) {
    const url = this.baseUrl;

    return this.get({ url, params });
  }

  /**
   * Gets event details, but without its movies
   *
   * @param eventId {string}
   * @returns {Promise<*|Error>}
   */
  fetchById(eventId) {
    const url = `${this.baseUrl}/${eventId}`;

    return this.get({ url });
  }

  /**
   * Gets movies belong to the event
   *
   * @param id {string} - event id
   * @returns {Promise<*|Error>}
   */
  fetchMovies(id) {
    const url = `${this.baseUrl}/${id}/movies`;
    const version = `/api/v2`;

    return this.get({ url, version });
  }

  /**
   * Fetch movie sessions that is connected with an event
   *
   * @param id {string} - event id
   * @param movieId {string} - movie id
   * @returns {Promise<*|Error>}
   */
  fetchMovieSessions(id, movieId) {
    const url = `${this.baseUrl}/${id}/movies/${movieId}/sessions`;
    const version = '/api/v2';

    return this.get({ url, version });
  }

  fetchMonthsList() {
    const url = `${this.baseUrl}/filters/months/list`;

    return this.get({ url });
  }
}

export default Events;
