import React from 'react';
import PropTypes from 'prop-types';
import { Svg } from 'app/components';
import { ListSocial, ListSocialItem } from './styles';

const propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired
};

const ListSocialComponent = ({ items }) => (
  <ListSocial>
    {items.map(item => (
      <ListSocialItem key={item.title}>
        <a
          href={item.url}
          target="_blank"
          className="l-social__item-link"
          rel="noopener noreferrer"
        >
          <Svg id={item.title} />
        </a>
      </ListSocialItem>
    ))}
  </ListSocial>
);

ListSocialComponent.propTypes = propTypes;
export default ListSocialComponent;
