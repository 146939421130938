import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Svg from 'app/components/Svg';
import Checkbox from '../Checkbox';
import { ListOptions, ListOptionsItem } from './styles';

const propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })
  ),

  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      key: PropTypes.string.isRequired
    })
  ),

  multi: PropTypes.bool,
  isVersions: PropTypes.bool
};

const defaultProps = {
  options: [],
  values: [],
  multi: false,
  isVersions: false
};

const optionClickHandler = (methods, option, multi, isActive) => {
  const { addItem, removeItem } = methods;

  return () => {
    if (isActive) {
      if (!multi) {
        methods.dropDown('close');

        return;
      }
      removeItem(null, option);
    } else {
      addItem(option);
    }
  };
};

const Options = (props, state, methods) => {
  const { multi, options, values: propsValues, isVersions, previousValues } = props;
  const { values: stateValues } = state;
  const values = stateValues || propsValues || [];

  let editable = useRef([])


  const uniqueMergedArray = (arr1, arr2) => {
    const mergeArray = arr1.concat(arr2)
    const uniqueArray = mergeArray.filter((item, index, self) => index === self.findIndex(t => (t.value === item?.value && t.label === item.label)))

    return uniqueArray
  }

  useEffect(() => {
    if (previousValues?.length && propsValues?.length) {
      const unique = uniqueMergedArray(previousValues, propsValues)
      editable.current = uniqueMergedArray(previousValues, propsValues)
      const { addItem } = methods;
      const uniqueArr = unique?.map(item => addItem(item))
    }

  }, [previousValues])

  return (
    <ListOptions>
      {options.map(option => {
        const isActive = values.find(v => v.value === option.value);
        const isChecked = editable.current?.length && editable.current.find(v => v.value === option.value);

        return (
          <ListOptionsItem
            key={option.value}
            selected={isActive}
            group={option.disabled}
            onClick={optionClickHandler(methods, option, multi, isActive)}
          >
            <div className="l-options__item-inner">
              {/* <span className="l-options__item-label">{isVersions? option.label : option.label.toLowerCase()}</span> */}
              <span className="l-options__item-label">{option.label}</span>
              <div className="l-options__item-mark">
                {isActive && !multi && <Svg id="checkmark" />}
                {multi && <Checkbox readOnly checked={isActive || isChecked} />}
              </div>
            </div>
          </ListOptionsItem>
        );
      })}
    </ListOptions>
  );
};

Options.propTypes = propTypes;
Options.defaultProps = defaultProps;
export default Options;
