import { ofType } from 'redux-observable';
import { catchError, concatMap, mergeMap, startWith } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { successLocations, setLoader, createToast } from './actions';
import { REQUEST_LOCATIONS } from './types';
import { handleErrors } from './deps';

// eslint-disable-next-line
export const fetchLocations = ($action, $state, { api }) => {
  const $apiMovies = api.getModuleByName('movies');

  return $action.pipe(
    ofType(REQUEST_LOCATIONS),
    mergeMap(action =>
      from($apiMovies.fetchLocations()).pipe(
        concatMap(res => [successLocations(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err =>
          of(
            createToast('warning', err.message.replace(':', '')),
            setLoader(false)
          )
        ),

        startWith(setLoader(true))
      )
    )
  );
};
