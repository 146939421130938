import { connect } from 'react-redux';
import layoutModule from 'app/store/layout';
import Toasters from './Toasters.component';

const { removeToast, createToast } = layoutModule.actions;

const mapStateToProps = ({ layout }) => ({
  toasters: layout.toasters
});

const mapDispatchToProps = {
  remove: removeToast,
  create: createToast
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Toasters);
