export const TOP_MOVIES_REQUEST = 'movies/TOP_MOVIES_REQUEST';
export const NOW_PLAYING_MOVIES_REQUEST = 'movies/NOW_PLAYING_MOVIES_REQUEST';
export const UPCOMING_MOVIES_REQUEST = 'movies/UPCOMING_MOVIES_REQUEST';

export const MOVIES_FETCH_SUCCESS = 'movies/FETCH_SUCCESS';
export const SET_UPCOMING_MOVIES = 'movies/SET_UPCOMING_MOVIES';

export const MOVIES_SET_DATE = 'movies/SET_DATE';

export const MOVIES_RESET_STATE = 'movies/RESET_STATE';
export const MOVIES_FILTER = 'movies/MOVIES_FILTER';
export const MOVIES_FILTER_SUCCESS = 'movies/MOVIES_FILTER_SUCCESS';


export const EVENTS_SESSIONS_REQUEST = 'movie/EVENTS_SESSIONS_REQUEST';
export const DAYS_SESSIONS_REQUEST = 'movies/DAYS_SESSIONS_REQUEST';
export const SET_DAYS_SESSIONS = 'movie/SET_DAYS_SESSIONS';

export const MOVIE_REQUEST = 'movie/MOVIE_REQUEST';
export const SET_MOVIE = 'movie/SET_MOVIE';

export const DATE_LIST_REQUEST = 'movie/DATE_LIST_REQUEST';
export const SET_DATE_LIST = 'movie/SET_DATE_LIST';

export const SESSIONS_REQUEST = 'movie/SESSIONS_REQUEST';
export const SET_SESSIONS = 'movie/SET_SESSIONS';

export const NEW_SESSIONS_REQUEST = 'movie/NEW_SESSIONS_REQUEST';
export const SET_NEW_SESSIONS = 'movie/SET_NEW_SESSIONS';

export const ADVERTISEMENT_REQUEST = 'movie/ADVERTISEMENT_REQUEST';
export const ADVERTISEMENT_SUCCESS = 'movie/ADVERTISEMENT_SUCCESS';

export const EVENT_WEB_SEND = 'movie/EVENT_WEB_SEND';
export const EVENT_WEB_SEND_SUCCESS = 'movie/EVENT_WEB_SEND_SUCCESS';
