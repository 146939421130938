// import { createMuiTheme } from '@material-ui/core';
import { createTheme } from '@mui/material/styles';
import theme from 'app/theme';
import { textTransform } from 'utils';

export default createTheme({
  typography: {
    useNextVariants: true
  },

  // overrides: {
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: { display: 'none' },
      },
    },


    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: theme.color.bgSecondary
        },

      },
    },

    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          height: '95px',
          backgroundColor: '#343434'
        }
      }
    },

    MuiPickersPopper: {
      styleOverrides: {
        root: {
          position: 'absolute'
        }
      },
    },

    MuiPickersCalendarHeader: {
      styleOverrides: {
        labelContainer: {
          fontSize: '1.2rem',
          fontWeight: '500',
          color: theme.color.rgbaWhite('.95'),
          overflow: 'initial'
        },

        switchViewButton: {
          margin: '0',
          padding: '3px 5px 5px'
        },

        switchViewIcon: {
          backgroundColor: 'transparent',

          '&:hover': {
            backgroundColor: 'transparent'
          },

          '&:hover svg': {
            fill: theme.color.rgbaWhite('1')
          },

          '& > span': {
            backgroundColor: 'transparent'
          }
        },
      },
    },

    MuiPickersArrowSwitcher: {
      styleOverrides: {
        root: {
          opacity: '1 !important',
          visibility: 'initial !important'
        }
      },
    },

    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: theme.color.rgbaWhite('.4'),
          fontSize: '19px'
        }
      },
    },

    MuiTypography: {
      styleOverrides: {
        h4: {
          color: `${theme.color.rgbaWhite('.54')} !important`,
          fontSize: '2.125rem'
        },

        overline: {
          display: 'none'
        },
      }
    },

    MuiPickersYear: {
      styleOverrides: {
        root: {
          transition: 'color .2s ease-out',
        },

        yearButton: {
          width: '40px',
          height: '38px',
          color: theme.color.rgbaWhite('.87'),
          fontSize: '1.2rem',

          '&.Mui-selected': {
            margin: '5px 0',
            color: theme.color.accentDark,
            fontWeight: '700',
            backgroundColor: 'transparent !important',
            fontSize: '1.5rem'
          },

          '&:hover': {
            color: theme.color.accent,
            backgroundColor: 'transparent',
          },

          '&:focus': {
            color: theme.color.accent,
            backgroundColor: 'transparent',
          }
        }
      },
    },

    MuiPickersMonth: {
      styleOverrides: {
        root: {
          transition: 'color .2s ease-out',
        },

        monthButton: {
          width: '40px',
          height: '38px',
          color: theme.color.rgbaWhite('.87'),
          fontSize: '1.2rem',

          '&.Mui-selected': {
            margin: '5px 0',
            color: theme.color.accentDark,
            fontWeight: '700',
            backgroundColor: 'transparent !important',
            fontSize: '1.5rem'
          },

          '&.Mui-disabled': {
            color: `${theme.color.rgbaWhite('.2')} !important`
          },

          '&:hover': {
            color: theme.color.accent,
            backgroundColor: 'transparent',
          },

          '&:focus': {
            color: theme.color.accent,
            backgroundColor: 'transparent',
          }
        }
      },
    },

    MuiPickersDay: {
      styleOverrides: {
        root: {
          width: '40px',
          height: '38px',
          color: theme.color.rgbaWhite('.87'),
          fontSize: '1.2rem',
          '&.Mui-selected': {
            backgroundColor: `${theme.color.accentDark} !important`,
            pointerEvents: 'none'
          },
          '&:hover': {
            backgroundColor: theme.color.accent
          },
          '&.Mui-disabled': {
            color: `${theme.color.rgbaWhite('.2')} !important`
          }

        },


        today: {
          color: theme.color.textPrimary,
          backgroundColor: theme.color.rgbaAccent('.3'),
          border: `${theme.color.rgbaAccent('.3')} !important`
        }
      },

      MuiPickersToolbarButton: {
        toolbarBtnSelected: {
          color: theme.color.textPrimary,
          fontSize: '3.4rem',
          fontWeight: '500'
        }
      },


    },

    MuiDayCalendar: {
      styleOverrides: {
        weekDayLabel: {
          color: `${theme.color.rgbaWhite('.38')} !important`,
          fontSize: '1.2rem',
          fontWeight: 500
        }
      }
    },

    MuiButton: {
      styleOverrides: {
        textPrimary: {
          // textTransform: 'uppercase',
          textTransform: `${textTransform}`,
          fontSize: '1.4rem',
          fontWeight: '700',
          color: theme.color.textPrimary,
          borderRadius: '20px',
          '&:hover': {
            backgroundColor: '#343434'
          },

          '&:first-child': {
            marginRight: '10px'
          }
        },
      },
    },

    MuiPickersModal: {
      styleOverrides: {
        dialogRoot: {
          minWidth: '328px',
          minHeight: '470px',
          boxShadow: '0 15px 12px 0 rgba(0, 0, 0, 0.22)',
          backgroundColor: theme.color.bgPopup,
          color: theme.color.textPrimary,

          '@media screen and (max-width: 740px)': {
            minWidth: '300px',
            minHeight: '465px'
          }
        }
      }
    },
  }
});
