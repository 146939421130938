import { mergeMap, startWith, concatMap, catchError } from 'rxjs/operators';
import { from } from 'rxjs';
import { ofType } from 'redux-observable';
import { setLoader, handleErrors, setError } from './deps';
import { TECHNOLOGIES_REQUEST, TECHNOLOGY_EVENTS_REQUEST, NEWSLETTER_POPUP_REQUEST, TECHNOLOGY_REDIRECT_REQUEST, PRIVACY_POLICY_REQUEST } from './types';
import { successTechnologies, successTechnologyEvents, successNewsLetter, successRedirectDomainURL, successPrivacyPolicy } from './actions';

export function fetchTechnologies($action, $state, { api }) {
  const $apiTechnologies = api.getModuleByName('technologies');

  return $action.pipe(
    ofType(TECHNOLOGIES_REQUEST),
    mergeMap(action =>
      from($apiTechnologies.fetchAll()).pipe(
        concatMap(res => [successTechnologies(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      )
    )
  );
}

export function fetchRedirectUrl($action, $state, { api }) {
  const $apiTechnologies = api.getModuleByName('technologies');

  return $action.pipe(
    ofType(TECHNOLOGY_REDIRECT_REQUEST),
    mergeMap(action =>
      from($apiTechnologies.fetchRedirectUrlAll()).pipe(
        concatMap(res => [successRedirectDomainURL(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      )
    )
  );
}

export function fetchTechnologyEvents($action, $state, { api }) {
  const $apiTechnologies = api.getModuleByName('technologies');

  return $action.pipe(
    ofType(TECHNOLOGY_EVENTS_REQUEST),
    mergeMap(action =>
      from($apiTechnologies.fetchEvents()).pipe(
        concatMap(res => [successTechnologyEvents(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      )
    )
  );
}

export function fetchNewsLetterPopup($action, $state, { api }) {
  const $apiTechnologies = api.getModuleByName('technologies');

  return $action.pipe(
    ofType(NEWSLETTER_POPUP_REQUEST),
    mergeMap(action =>
      from($apiTechnologies.fetchNewsLetterContent()).pipe(
        concatMap(res => [successNewsLetter(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      )
    )
  );
}
export function fetchPrivacyPolicy($action, $state, { api }) {
  const $apiTechnologies = api.getModuleByName('technologies');

  return $action.pipe(
    ofType(PRIVACY_POLICY_REQUEST),
    mergeMap(action =>
      from($apiTechnologies.fetchPrivacyPolicy()).pipe(
        concatMap(res => [successPrivacyPolicy(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      )
    )
  );
}
