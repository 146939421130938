import { GIFTCARDS_SUCCESS, ADD_GIFTCARD_SUCCESS, RESTRICTIONS_SUCCESS, GIFT_CARD_LOCATION_SET, SET_CINEMAS_WITH_MOVIES_GET_LOCATION, SET_DATES, REQUEST_CONTENT_DATA_SUCCESS, ADD_GIFTCARD_REQUEST_LOGOUT_SUCCESS, GIFTCARD_SESSION_CLEAR, ADD_GIFTCARD_REQUEST_TICKET_SUCCESS, GIFTCARD_SET_AGAIN } from './types';

const initialState = {
  giftcards: null,
  restrictions: [],
  content: [],
  newGiftcardAdd: false
  // newGiftcardWithoutLogin: []
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GIFTCARDS_SUCCESS: {
      return {
        ...state,
        giftcards: payload.giftcards
      };
    }

    // case ADD_GIFTCARD_SUCCESS: {
    //   return {
    //     ...state,
    //     giftcards: [...state.giftcards, payload.giftcard],
    //     newGiftcard: payload.giftcard
    //   };
    // }

    case ADD_GIFTCARD_SUCCESS: {
      let array = [];
      let payloadValue = payload?.giftcard;
      if (state.giftcards !== null) {
        array.push(...state.giftcards, payloadValue)
      } else {
        array.push(payloadValue)
      }
      return {
        ...state,
        // giftcards: array.push(...state.giftcards, payload.giftcard),
        giftcards: array,
        newGiftcard: payload.giftcard,
        newGiftcardAdd: true
      };
    }

    case ADD_GIFTCARD_REQUEST_LOGOUT_SUCCESS: {
      let array = [];
      let payloadValue = payload?.giftcardData;
      if (state.giftcards !== null) {
        array.push(...state.giftcards, payloadValue)
      } else {
        array.push(payloadValue)
      }
      return {
        ...state,
        giftcards: array
      };
    }

    case ADD_GIFTCARD_REQUEST_TICKET_SUCCESS: {
      let array = [];
      let payloadValue = payload?.giftcardData;
      if (state.giftcards !== null) {
        array.push(...state.giftcards, payloadValue)
      } else {
        array.push(payloadValue)
      }
      return {
        ...state,
        giftcards: array
      };
    }

    case RESTRICTIONS_SUCCESS: {
      return {
        ...state,
        restrictions: payload.restrictions
      };
    }

    case GIFT_CARD_LOCATION_SET: {
      return {
        ...state,
        locations: payload.locations
      };
    }

    case SET_DATES: {
      return {
        ...state,
        dates: payload.dates || []
      };
    }

    case SET_CINEMAS_WITH_MOVIES_GET_LOCATION: {
      return {
        ...state,
        withMovies: payload.cinemas || []
      };
    }

    case REQUEST_CONTENT_DATA_SUCCESS: {
      return {
        ...state,
        content: payload?.data?.data
      };
    }

    case GIFTCARD_SET_AGAIN: {
      return {
        ...state,
        giftcards: payload?.giftcardDataAgain
      };
    }

    case GIFTCARD_SESSION_CLEAR: {
      return {
        ...state,
        giftcards: null
      };
    }

    default: {
      return state;
    }
  }
}
