import { useCallback, useState } from 'react';

export default function(location, savedLocation, onLocationSelected) {
  const [redLabelLocation, setRedLabelLocation] = useState(false);

  const onSelectLocation = useCallback(
    valueFromSelect => {
      if (!valueFromSelect || !valueFromSelect.length) return;

      const { value } = valueFromSelect[0];

      setRedLabelLocation(value !== 'all');

      if (value.toString() === location && value.toString() === savedLocation) {
        return;
      }

      onLocationSelected(value);
    },
    [location, savedLocation, onLocationSelected]
  );

  return {
    redLabelLocation,
    onSelectLocation
  };
}
