import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Svg from 'app/components/Svg';
import { BlockImageWithLoader, BlockImageWithLoaderInner } from './styles';

const propTypes = {
  type: PropTypes.string,
  imgSrc: PropTypes.string,
  imgTitle: PropTypes.string
};

const defaultProps = {
  type: 'movie',
  imgSrc: '',
  imgTitle: ''
};

// const printIcon = type => {
//   if (type === 'movie') {
//     return <Svg id="movie" />;
//   }
//   return <Svg id="no-cinema" />;
// };

const ImageWithLoader = ({ type, imgSrc, imgTitle, ...props }) => {
  const [isLoaded, setStatusLoad] = useState(false);
  const [isLoadError, setStatusError] = useState(false);
  const onLoadImage = () => setStatusLoad(true);
  const onErrorImage = () => setStatusError(true);

  useEffect(() => {
    // setStatusLoad(true);
    // setStatusError(true);
  }, [isLoaded, isLoadError]);

  return (
    <BlockImageWithLoader {...props}>
      <BlockImageWithLoaderInner type={type}>
        {isLoaded && isLoadError && <Svg id="preloader" />}
        {/* {isLoadError && printIcon(type)} */}
        {imgSrc && (
          <img
            src={imgSrc}
            alt={imgTitle}
            onLoad={onLoadImage}
            onError={onErrorImage}
            className="b-image-with-loader__img"
          />
        )}
      </BlockImageWithLoaderInner>
    </BlockImageWithLoader>
  );
};

ImageWithLoader.propTypes = propTypes;
ImageWithLoader.defaultProps = defaultProps;
export default ImageWithLoader;
