import React from 'react';
import { useTranslation } from 'react-i18next';
import useConfig from 'app/hooks/useConfig';
import { Svg } from 'app/components';
import { SignInLink } from './styles';

export default function LoginButton() {
  const [t] = useTranslation();
  const { routes } = useConfig();
  
  return (
    <SignInLink to={routes.login.pattern} activeClassName="_active">
      <Svg id="user" />
      <span className='login-name'>{t('common.login')}</span>
    </SignInLink>
  );
}
