import BaseAPI from '../BaseAPI';

class Competitions extends BaseAPI {
  constructor(props) {
    super(props);
    this.baseUrl = `/list?country=${process.env.REACT_APP_COUNTRY?.toUpperCase()}`;
  }

  /**
   * Gets a list of competitions
   *
   * @returns {Promise<*|Error>}
   */
  fetchAll() {
    const url = `/family-film-club${this.baseUrl}`;

    return this.get({ url });
  }

  /**
   * Gets a list of main events
   *
   * @returns {Promise<*|Error>}
   */

  fetchBirthdayRoomContent() {
    const url = `/birthday-room${this.baseUrl}`;

    return this.get({ url });
  }
}

export default Competitions;
