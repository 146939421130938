import React from 'react';
import styled from 'styled-components';
import { Svg } from 'app/components';

const Wrapper = styled.div.attrs({
  className: 'b-preloader'
})`
  @keyframes preloader-appearance {
    to {
      pointer-events: auto;
      visibility: visible;
    }
  }

  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  visibility: hidden;
  pointer-events: none;
  background-color: ${({ theme }) => theme.color.rgbaBlack(0.2)};
  animation: preloader-appearance 0s 1s both;

  & > .svg-preloader {
    width: 40px;
    height: 40px;
    margin: auto;
  }

  &::before {
    content: '';
    width: 72px;
    height: 72px;
    margin: auto;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.color.rgbaBlack(0.7)};
  }
`;

const Loader = () => (
  <Wrapper>
    <Svg id="preloader" />
  </Wrapper>
);

export default Loader;
