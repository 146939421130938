import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import { Image } from 'app/components';
import { CustomDotWrapper } from './styles';

const propTypes = {
  images: PropTypes.oneOfType(PropTypes.arrayOf(PropTypes.object), PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired
};

const CustomDot = ({ images, index, onClick, active }) => {
  const imageList = images?.map((item, idx) => (
    // <Image key={idx} src={item} className="custom-dot-wrapper__custom-image" />
    <div key={idx} className="custom-dot-wrapper__custom-image" style={{
      backgroundImage: `url(${item})`
    }} />
  ));

  const handleImageClick = e => {
    onClick();
    e.preventDefault();
  };

  return (
    <CustomDotWrapper>
      <button
        type="button"
        onClick={handleImageClick}
        className={classNames('custom-dot-wrapper__custom-dot', {
          'custom-dot-wrapper__custom-dot--active': active
        })}
      >
        {React.Children.toArray(imageList)[index]}
      </button>
    </CustomDotWrapper>
  );
};

CustomDot.propTypes = propTypes;

export default CustomDot;
