import {
  BUY_SUCCESS,
  SET_PAYMENT_STATUS,
  BUY_WITH_BONUS_SUCCESS,
  BUY_WITH_GIFT_SUCCESS,
  APPLY_GIFTCARD_SUCCESS,
  TICKET_SESSION_CLEAR,
  PAYMENT_CLEAR,
  STEP_CHECKOUT_SUCCESS
} from './types';

const initialState = {
  payment: null,
  bonusCardSimplePayment: {},
  giftCardpayment: false,
  status: null,
  appliedGiftcard: null,
  paymentGiftcardData: {},
  description: [],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case BUY_SUCCESS: {
      return {
        ...state,
        payment: payload.payment
      };
    }

    case SET_PAYMENT_STATUS: {
      return {
        ...state,
        status: payload.status || null
      };
    }

    case BUY_WITH_BONUS_SUCCESS: {
      return {
        ...state,
        // payment: payload.payment
        bonusCardSimplePayment: payload.payment
      };
    }

    case BUY_WITH_GIFT_SUCCESS: {
      return {
        ...state,
        paymentGiftcardData: payload.payment,
        giftCardpayment: true
      };
    }

    case APPLY_GIFTCARD_SUCCESS: {
      return {
        ...state,
        appliedGiftcard: !!payload.appliedGiftcard
      };
    }

    case TICKET_SESSION_CLEAR: {
      return {
        ...state,
        paymentGiftcardData: {}
      };
    }

    case PAYMENT_CLEAR: {
      return {
        ...state,
        payment: null
      };
    }
    case STEP_CHECKOUT_SUCCESS: {
      return {
        ...state,
        description: payload?.params?.data
      };
    }

    default: {
      return state;
    }
  }
}
