import styled from 'styled-components';
import bgba from '../../assets/images/bgba.jpg';

export default styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: inherit;
  padding-top: ${({ theme }) => theme.breakPoints.md.header};

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    padding: ${({ theme }) => theme.breakPoints.sm.header} 0 52px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
    padding-top: ${({ theme }) => theme.breakPoints.xs.header};
  }
`;

export const NoContent = styled.div`
  background-image: url(${bgba});
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  @media (max-width: 425px) {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
`;
