import React from 'react';
import PropTypes from 'prop-types';
import { Svg } from 'app/components';
import CinemaInfo from './styles';

const propTypes = {
  message: PropTypes.string
};

const defaultProps = {
  message: null
};

const CinemaInfoComponent = ({ message }) =>
  message ? (
    <CinemaInfo>
      <span className="b-cinema-info__icon">
        <Svg id="info" />
      </span>
      <span className="b-cinema-info__text" dangerouslySetInnerHTML={{ __html: message }} />
    </CinemaInfo>
  ) : null;

CinemaInfoComponent.defaultProps = defaultProps;
CinemaInfoComponent.propTypes = propTypes;
CinemaInfoComponent.defaultProps = defaultProps;
export default CinemaInfoComponent;
