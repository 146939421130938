import { CLEAR_PDF, SET_PFD } from './types';

const initialState = null;

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case SET_PFD: {
      return payload.pdf;
    }

    case CLEAR_PDF: {
      return null;
    }

    default: {
      return state;
    }
  }
}
