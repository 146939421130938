import { BIRTHDAY_ROOM_SUCCESS, FAMILY_FILM_CLUB_SUCCESS } from './types';

const initialState = {
  birthdayRoom: [],
  content: []
};

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case BIRTHDAY_ROOM_SUCCESS: {
      return {
        ...state,
        birthdayRoom: payload.birthdayRoom?.data
      };
    }

    case FAMILY_FILM_CLUB_SUCCESS: {
      return {
        ...state,
        content: payload.familyFilmClub?.data
      };
    }

    default: {
      return state;
    }
  }
}
