import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Error from '../Error';

const propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

const defaultProps = {
  error: null
};

const TextArea = styled.textarea.attrs({
  cols: 30,
  rows: 4
})`
  padding: 7px 16px;
  line-height: 1.4;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.color.textPrimary};
  border: 1px solid ${({ theme }) => theme.color.rgbaWhite('.4')};
  border-radius: 1.2em;
  transition: border-color 0.2s ease-out, box-shadow 0.2s ease-out;

  ${props =>
    props.error &&
    css`
      border-color: ${({ theme }) => theme.color.rgbaAccent('.8')};
      box-shadow: 0 0 8px 0 ${({ theme }) => theme.color.rgbaAccent('.7')};
    `}

  ${props =>
    !props.error &&
    css`
      &:focus {
        box-shadow: 0 0 8px 0 ${({ theme }) => theme.color.rgbaWhite('.24')};
        border-color: ${({ theme }) => theme.color.rgbaWhite('.8')};
      }
    `}
`;

const Area = props => {
  const { error } = props;

  return (
    <Fragment>
      <TextArea {...props} />
      {error && <Error>{error}</Error>}
    </Fragment>
  );
};

Area.propTypes = propTypes;
Area.defaultProps = defaultProps;
export default Area;
