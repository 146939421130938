import {
  CINEMA_ADVERTISING_REQUEST,
  CINEMA_ADVERTISING_SUCCESS,
  ONLINE_ADVERTISING_REQUEST,
  ONLINE_ADVERTISING_SUCCESS,
  PRESS_INFO_REQUEST,
  PRESS_INFO_SUCCESS
} from './types';

export const cinemaAdvertisingRequest = () => ({
  type: CINEMA_ADVERTISING_REQUEST
});

export const cinemaAdvertisingSuccess = cinemaAdvertising => ({
  type: CINEMA_ADVERTISING_SUCCESS,
  payload: { cinemaAdvertising }
});

export const onlineAdvertisingRequest = () => ({
  type: ONLINE_ADVERTISING_REQUEST
});

export const onlineAdvertisingSuccess = onlineAdvertising => ({
  type: ONLINE_ADVERTISING_SUCCESS,
  payload: { onlineAdvertising }
});

export const pressInfoRequest = () => ({
  type: PRESS_INFO_REQUEST
});

export const pressInfoSuccess = pressInfo => ({
  type: PRESS_INFO_SUCCESS,
  payload: { pressInfo }
});
