import styled from 'styled-components';
import { withChevron } from 'app/theme';

export default styled.button.attrs({
  type: 'button',
  className: 'btn-toggle-expand'
})`
  vertical-align: middle;
  margin-left: 11px;
  padding: 4px;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.color.borderLight};
    transition: border-color 0.2s ease-out;
  }

  ${withChevron}

  ._with-chevron {
    padding: 0;
    position: static;
    cursor: pointer;

    &::after {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      transform: rotate(135deg) translate(-0.1em, 0.05em);

      ${({ isExpanded }) =>
        isExpanded &&
        `
        transform: rotate(315deg) translate(-0.05em, 0.1em);
      `}
    }
  }

  &:hover {
    &::before {
      border-color: ${({ theme }) => theme.color.textPrimary};
    }

    ._with-chevron::after {
      border-color: ${({ theme }) => theme.color.textPrimary};
    }
  }
`;
