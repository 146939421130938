import { PORTRAIT_SUCCESS } from './types';

const initialState = {
  portrait: []
};

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case PORTRAIT_SUCCESS: {
      return {
        ...state,
        portrait: payload.portrait?.data
      };
    }

    default: {
      return state;
    }
  }
}
