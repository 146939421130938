import styled from 'styled-components';

export default styled.div.attrs({
  className: 'f-default__row'
})`
  display: flex;
  flex-wrap: wrap;
  margin: ${({ theme }) => `-${theme.indent.m} -12px ${theme.indent.l}`};

  & > .f-default__field {
    width: ${({ col }) => (col ? 100 / col : 100)}%;
    margin-top: ${({ theme }) => theme.indent.m};
    padding: 0 12px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
    & > .f-default__field {
      width: 100%;
    }
  }
`;
