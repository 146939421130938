/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable array-callback-return  */
/* eslint-disable jsx-a11y/anchor-is-valid  */
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { loadScript } from 'utils';
import useConfig from 'app/hooks/useConfig';
import { Loader, Button } from 'app/components';
import { Wrapper, Error } from './styles';
import PlayerVendor from './PlayerVendor';

const propTypes = {
  id: PropTypes.string.isRequired,
  keyFrameUrl: PropTypes.string.isRequired
};

const Player = ({ id, keyFrameUrl }) => {
  const {
    player: { jquery, url }
  } = useConfig();
  const [t] = useTranslation();
  const [isLoaded, setLoaded] = useState(false);
  const [isLoader, setLoader] = useState(true);
  const [isError, setError] = useState(false);

  const videoLoadedCb = useCallback(setLoader.bind(null, false), []);

  const setErrorCb = useCallback(() => {
    setLoader(false);
    setError(true);
  }, []);

  const retryCb = useCallback(() => {
    setError(false);
    setLoader(true);
    setLoaded(false);
  }, []);

  /**
   * Load Vendor Player Scripts
   */
  useEffect(() => {
    if (isLoader && !window.uvp_loaded) {
      loadScript(jquery)
        .then(loadScript.bind(null, url))
        .then(() => setLoaded(true))
        .catch(setErrorCb);
    }

    if (isLoader && window.uvp_loaded) {
      setLoaded(true);
    }
  }, [url, jquery, isLoader]);

  return (
    <Wrapper>
      {isLoader ? (
        <div className="b-vendor-video__thumb">
          <img src={keyFrameUrl} className="b-vendor-video__thumb-img" alt="" />
        </div>
      ) : null}

      {isLoaded ? (
        <PlayerVendor isHidden={isLoader} id={id} onVideoLoaded={videoLoadedCb} onError={setErrorCb} />
      ) : null}

      {isError ? (
        <Error>
          <h5 className="b-vendor-video__error-title">{t('player.message')}</h5>
          <Button isDefault onClick={retryCb}>
            {t('player.btn')}
          </Button>
        </Error>
      ) : null}

      {isLoader ? <Loader /> : null}
    </Wrapper>
  );
};

Player.propTypes = propTypes;
export default Player;
