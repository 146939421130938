import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { textTransform } from 'utils';

export const TicketsList = styled.ul.attrs({
  className: 'l-tickets'
})`
  display: flex;
  flex-wrap: wrap;
  margin: ${({ theme }) => `${theme.indent.m} -${theme.indent.s} 0`};
`;

export const TicketsListItem = styled.li.attrs({
  className: 'l-tickets__item'
})`
  width: 25%;
  padding: 0 ${({ theme }) => theme.indent.s};

  &:nth-child(4) ~ .l-tickets__item {
    margin-top: ${({ theme }) => theme.indent.m};
  }


  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    width: 33.3333%;
    
    &:nth-child(3) ~ .l-tickets__item {
      margin-top: ${({ theme }) => theme.indent.m};
    }
  }
  
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {  
    width: 100%;

    &:first-child ~ .l-tickets__item {
      margin-top: ${({ theme }) => theme.indent.s};
    }
  }
`;

export const TicketsListItemLink = styled(({ isLocked, ...props }) => <NavLink {...props} />)
  .attrs({
    className: 'l-tickets__item-link'
  })`
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.indent.s};
  border: 1px solid ${({ theme }) => theme.color.rgbaWhite('.3')};
  border-radius: 8px;
  position: relative;
  text-align: center;
  text-transform: ${textTransform};
  transition: background-color 0.2s ease-out, border-color 0.2s ease-out;

  ${({ status }) =>
    status === 'grey' || status === 'red'
      ? `
      opacity: .4;
      pointer-events: none;
      user-select: none;
    `
      : ''}

  &:hover {
    border-color: ${({ theme }) => theme.color.rgbaWhite('.02')};
    background-color: ${({ theme }) => theme.color.rgbaWhite('.08')};
  }

  .svg-tickets-noreserve,
  .svg-tickets-desk,
  .svg-tickets-locked {
    width: 24px;
    height: 24px;
    position: absolute;
    top: ${({ theme }) => theme.indent.s};
    right: ${({ theme }) => theme.indent.s};
  }

  .l-tickets__item-time {
    display: inline-block;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.4;
    color: ${({ theme }) => theme.color.textPrimary};
  }

  .l-tickets__item-cinema {
    font-size: 1.4rem;
    font-weight: 500;
    color: ${({ theme }) => theme.color.textPrimary};
  }

  .l-tickets__item-info {
    font-size: 1.4rem;
    color: ${({ theme }) => theme.color.textSecondary};
  }
  
  .l-tickets__inner-bottom {
    margin: 4px 0 0 0;

    & > p + p {
      margin-top: 4px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    
    .l-tickets__inner-top {
      flex-shrink: 0;
      max-width: 75px;
      text-align: right;
    }

    .l-tickets__inner-bottom {
      width: 100%;
      margin: 0;
      padding-right: ${({ theme }) => theme.indent.m};
      text-align: left;
    }
    
    .l-tickets__item-time {
      min-width: 50px;
      line-height: 1;
    }

    .svg-tickets-noreserve,
    .svg-tickets-desk,
    .svg-tickets-locked {
      vertical-align: middle;
      position: static;
      
      & + .l-tickets__item-time {
        vertical-align: middle;
      }
    }
  }
`;
