import {
  SET_ALL_EVENTS,
  SET_EVENT_DETAILS,
  SET_EVENTS_DATE,
  RESET_ALL_EVENTS,
  RESET_EVENT_DETAILS
} from './types';

const initialState = {
  allEvents: [],
  monthsList: [],
  eventDetails: {},
  movies: [],
  date: null
};

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case SET_ALL_EVENTS: {
      return {
        ...state,
        allEvents: payload.allEvents || [],
        monthsList: payload.monthsList || []
      };
    }

    case SET_EVENT_DETAILS: {
      return {
        ...state,
        eventDetails: payload.eventDetails || {},
        movies: payload.movies || []
      };
    }

    case SET_EVENTS_DATE: {
      return {
        ...state,
        date: payload.date
      };
    }

    case RESET_ALL_EVENTS: {
      return {
        ...state,
        allEvents: [],
        monthsList: []
      };
    }

    case RESET_EVENT_DETAILS: {
      return {
        ...state,
        eventDetails: {},
        movies: []
      };
    }

    default: {
      return state;
    }
  }
}
