import BaseAPI from '../BaseAPI';

class CinemaRent extends BaseAPI {
  constructor(props) {
    super(props);

    this.baseUrl = `/list?country=${process.env.REACT_APP_COUNTRY?.toUpperCase()}`;
  }

  /**
   * sends the cinema rent inquiry
   * @returns {Promise<*|Error>}
   */
  fetchAll() {
    const url = `/cinema-rent-main-site${this.baseUrl}`;

    return this.get({ url });
  }
}

export default CinemaRent;
