/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable array-callback-return  */
/* eslint-disable jsx-a11y/anchor-is-valid  */
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import layout from 'app/store/layout';

const { setModal } = layout.actions;

export default function(modalName) {
  const dispatch = useDispatch();
  const modal = useSelector(state => state.layout.modals[modalName]);

  const showModal = useCallback(() => {
    dispatch(setModal(modalName, true));
  }, [modalName]);

  const hideModal = useCallback(() => {
    dispatch(setModal(modalName, false));
  }, [modalName]);

  return [modal, showModal, hideModal];
}
