import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, generatePath } from 'react-router-dom';
import { Container, Svg } from 'app/components';
import useUrlParams from 'app/hooks/useUrlParams';
import useConfig from 'app/hooks/useConfig';
import MobileNav from './styles';
import { useTranslation } from 'react-i18next';
import ToastMenu from '../Header/components/MobileNav';


const propTypes = {
  cinemas: PropTypes.arrayOf(PropTypes.object).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  savedLocation: PropTypes.string.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  savedLocation: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  userName: PropTypes.string,
};

const MobileNavComponent = ({ savedLocation, isVisible, cinemas, locations, pathname, userName, isLoggedIn }) => {
  const { routes } = useConfig();
  const { date } = useUrlParams('date');
  const [t] = useTranslation()



  return (
    <MobileNav isVisible={isVisible}>
      <Container>
        <nav className="b-mobile-nav__inner">
          <NavLink
            to={{
              pathname: generatePath(routes.movies.pattern),
              search: `&location=${savedLocation}${date ? `&date=${date}` : ''}`
            }}
            title={routes.movies.name}
            className="b-mobile-nav__link"
            exact
          >
            <Svg id="movie" />
            <div className='svg-text'>{t('common.movies')}</div>
          </NavLink>
          <NavLink
            to={{
              pathname: generatePath(routes.cinemas.pattern),
              search: `&location=${savedLocation}${date ? `&date=${date}` : ''}`
            }}
            title={routes.cinemas.name}
            className="b-mobile-nav__link"
            exact
          >
            <Svg id="pin" />
            <div className='svg-text'>{t('common.cinemas')}</div>
          </NavLink>
          <NavLink
            to={generatePath(routes.search.pattern)}
            title={routes.search.name}
            className="b-mobile-nav__link"
          >
            <Svg id="search" />
            <div className='svg-text'>{t('common.search')}</div>
          </NavLink>
          <NavLink
            to={{
              pathname: generatePath(routes.events.pattern),
              search: `&location=${savedLocation}date=all`
            }}
            title={routes.search.name}
            className="b-mobile-nav__link"
          >
            <Svg id="events" />
            <div className='svg-text'>{t('common.event_title')}</div>
          </NavLink>
          {/* <NavLink
            to={generatePath(routes.account.pattern)}
            title={routes.account.name}
            className="b-mobile-nav__link"
          >
            <Svg id="user" />
            <div className='svg-text'>{t('faq_form.account')}</div>
          </NavLink> */}      
          <div  className="b-mobile-nav__link toast-menu-icon">               
          <ToastMenu
            cinemas={cinemas}
            locations={locations}
            savedLocation={savedLocation}
            isLoggedIn={isLoggedIn}
            pathname={pathname}
            userName={userName}
          />          
         <div className='svg-text'>{t('common.more')}</div>
         </div>
        </nav>
      </Container>
    </MobileNav>
  );
};

MobileNavComponent.propTypes = propTypes;
export default MobileNavComponent;
