import * as actions from './actions';
import * as types from './types';
import * as epics from './epics';
import reducer from './reducer';

const NAME = 'survey';

export default {
  NAME,
  actions,
  types,
  epics,
  reducer
};
