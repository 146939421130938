import React from 'react';
import PropTypes from 'prop-types';
import { NavTabsList, NavTabsLinkBtn } from './styles';

const propTypes = {
  to: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired
  }),
  onClick: PropTypes.func,
  isSelected: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired
};

const defaultProps = {
  to: null,
  onClick: null
};

const NavTabsItem = ({ to, onClick, isSelected, text }) => {
  return (
    <li>
      <NavTabsLinkBtn to={to} onClick={onClick} isSelected={isSelected}>
        <span className="l-tabs__item-text">{text}</span>
      </NavTabsLinkBtn>
    </li>
  );
};

NavTabsItem.propTypes = propTypes;
NavTabsItem.defaultProps = defaultProps;
export default { NavTabsList, NavTabsItem };
