import React from 'react';
import PropTypes from 'prop-types';
import { Svg } from 'app/components';
import NoContent from './styles';

const propTypes = {
  svgId: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string
};

const defaultProps = {
  type: null
};

const NoContentComponent = ({ svgId, text, type }) => (
  <NoContent type={type}>
    <Svg id={svgId} />
    <p className="b-no-content__text">{text}</p>
  </NoContent>
);

NoContentComponent.propTypes = propTypes;
NoContentComponent.defaultProps = defaultProps;
export default NoContentComponent;
