import BaseAPI from '../BaseAPI';

class CinemaRentInquiry extends BaseAPI {
  constructor(props) {
    super(props);

    this.baseUrl = '/cinemarentrequest/request';
  }

  /**
   * sends the cinema rent inquiry
   * @body payload data
   * @returns {Promise<*|Error>}
   */
  sendInquiry(body) {
    const url = this.baseUrl;
    return this.post({ url, body });
  }
}

export default CinemaRentInquiry;
