export const LOADING = 'layout/LOADING';
export const MODAL = 'layout/MODAL';

export const CREATE_TOAST = 'layout/CREATE_TOAST';
export const REMOVE_TOAST = 'layout/REMOVE_TOAST';

export const SET_ERROR = 'layout/SET_ERROR';
export const CLEAR_ERROR = 'layout/CLEAR_ERROR';
export const REPORT_ERROR = 'layout/REPORT_ERROR';

export const SET_ERROR_API = 'layout/SET_ERROR_API';
export const CLEAR_ERROR_API = 'layout/CLEAR_ERROR_API';

export const REQUEST_LOCATIONS = 'layout/REQUEST_LOCATIONS';
export const SUCCESS_LOCATIONS = 'layout/SUCCESS_LOCATIONS';

export const SET_ELEMENTS_VISIBILITY = 'layout/SET_ELEMENTS_VISIBILITY';
