import BaseAPI from '../BaseAPI';

/**
 * Api class for processing orders
 */
class Orders extends BaseAPI {
  /**
   * Creates Orders instance
   *
   * @param props {Object}
   * @return {Orders}
   */
  constructor(props) {
    super(props);

    this.baseUrl = '/orders';
  }

  /**
   * Creates orders before seats selection
   *
   * @param cinemaId {string}
   * @return {Promise<*|Error>}
   */
  initOrder(cinemaId) {
    const url = `${this.baseUrl}`;
    const body = {
      cinemaId
    };

    return this.post({ url, body });
  }

  /**
   * Deletes order if need to cancel it
   *
   * @param orderId {string}
   * @return {Promise<*|Error>}
   */
  cancelOrder(orderId) {
    const url = `${this.baseUrl}/${orderId}`;

    return this.remove({ url });
  }

  /**
   * Ordering a selected seats
   *
   * @param orderId {string}
   * @param sessionId {string}

   * @param selection {Object}
   * @param selection.areaNumber {number}
   * @param selection.rowIndex {number}
   * @param selection.columnIndex {number}
   *
   * @param wizard {Object}
   * @param wizard.isBonus {boolean}
   * @param wizard.adult {number}
   * @param wizard.child {number}
   * @param wizard.reduced {number}   *
   * @param wizard.packageRegular {number}
   * @param wizard.packageReduced {number}
   * @param wizard.packageChild {number}
   * @param wizard.packageClub {number}
   * @return {Promise<*|Error>}
   */
  orderTickets(orderId, sessionId, selection, wizard) {
    const url = `${this.baseUrl}/${orderId}/sessions/${sessionId}/tickets-on-server`;
    const { isBonus, adult, child, reduced, packageRegular, packageChild, packageReduced, packageClub } = wizard;

    const body = {
      isBonusCard: isBonus,
      numberOfSeats: adult,
      numberOfChildSeats: child,
      numberOfReducedSeats: reduced,
      numberOfPackageRegularSeats: packageRegular,
      numberOfPackageReducedSeats: packageReduced,
      numberOfPackageClubSeats: packageClub,
      numberOfPackageChildSeats: packageChild,
      seats: [selection]
    };

    return this.post({ url, body });
  }
  orderSessionTickets(orderId, wizard, sessionId, permotionId, sessionSeatPlanValue, sessionBookingCountData, email) {
    const url = `${this.baseUrl}/${orderId}/promotion/${permotionId}/season-pass-on-server/${encodeURI(email)}`;
    const { isBonus, adult, child, reduced, packageRegular, packageChild, packageReduced, packageClub } = wizard;

    const body = {
      isBonusCard: isBonus,
      numberOfSeats: sessionBookingCountData,
      numberOfChildSeats: child,
      numberOfReducedSeats: reduced,
      numberOfPackageRegularSeats: packageRegular,
      numberOfPackageReducedSeats: packageReduced,
      numberOfPackageClubSeats: packageClub,
      numberOfPackageChildSeats: packageChild,
      seats: sessionSeatPlanValue
    };
    return this.post({ url, body });
  }
  orderSessionTicketsPh2(orderId, wizard, sessionId, selection, aboId) {
    const url = `${this.baseUrl}/${orderId}/promotion/${aboId}/season-pass-on-server-ph2`;
    const { isBonus, adult, child, reduced, packageRegular, packageChild, packageReduced, packageClub } = wizard;

    const body = {
      isBonusCard: isBonus,
      numberOfSeats: adult,
      numberOfChildSeats: child,
      numberOfReducedSeats: reduced,
      numberOfPackageRegularSeats: packageRegular,
      numberOfPackageReducedSeats: packageReduced,
      numberOfPackageClubSeats: packageClub,
      numberOfPackageChildSeats: packageChild,
      seats: [selection]
    };
    return this.post({ url, body });
  }
  /**
   * Fetches order
   *
   * @param userSessionId
   * @return {Promise<*|Error>}
   */
  getOrder(userSessionId) {
    const url = `${this.baseUrl}/${userSessionId}`;

    return this.get({ url });
  }
}

export default Orders;
