import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.element.isRequired,
  ErrorComponent: PropTypes.shape({
    error: PropTypes.object
  }).isRequired
};

class ErrorBoundary extends React.Component {
  state = {
    error: null
  };

  static getDerivedStateFromError(error) {
    return { error };
  }

  render() {
    const { children, ErrorComponent } = this.props;
    const { error } = this.state;

    if (error) {
      return <ErrorComponent dev={error} />;
    }

    return children;
  }
}

ErrorBoundary.propTypes = propTypes;
export default ErrorBoundary;
