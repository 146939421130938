import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Options from './Options';
import Handle from './Handle';
import { StyledSelect } from './styles';
import Error from '../Error';

const propTypes = {
  error: PropTypes.string
};

const defaultProps = {
  error: null
};

const Select = (props) => {
  const { error } = props;

  return (
    <Fragment>
      <StyledSelect dropdownHandleRenderer={Handle} dropdownRenderer={Options} {...props} />
      {error && <Error>{error}</Error>}
    </Fragment>
  );
};

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;
export default Select;
