import isEmpty from 'validator/lib/isEmpty';
import isLength from 'validator/lib/isLength';
import isEmail from 'validator/lib/isEmail';
import isNumeric from 'validator/lib/isNumeric';
import equals from 'validator/lib/equals';

const required = value => isEmpty(value);
const minLength = (value, { min }) => !isLength(value, { min });
const maxLength = (value, { max }) => !isLength(value, { max });
const email = value => !isEmail(value);

const equal = (value, { field: { name } }, values) => {
  const comparison = values[name] || '';

  return !equals(value, comparison);
};

const requiredArray = value => !value.length;

const requiredDate = value => !(value && value instanceof Date);

// const password = value => !/^[A-Za-z0-9!§$%&/()=?/\\,.-_:;+*~|@#]+$/g.test(value);
const password = value => /^(?=.*[a-zà-ÿ])(?=.*[A-ZÀ-ß])(?=.*\d)(?=.*[!@#$])$/g.test(value);

// const numeric = value => !isNumeric(value, { no_symbols: true }) && !isEmpty(value);

const numeric = value => {
  const numericRegex = /^\+?\d*$/;
  return !numericRegex.test(value) && !isEmpty(value);
};

const zip = (value, { amount }) => {
  const expression = `^[0-9]{${amount},}(?:-[0-9]{5})?$`;
  return !new RegExp(expression).test(value) && !isEmpty(value);
};

export default {
  required,
  minLength,
  maxLength,
  email,
  equal,
  requiredArray,
  requiredDate,
  password,
  numeric,
  zip
};
