import styled from 'styled-components';

export const BlockImageWithLoader = styled.div.attrs({
  className: 'b-image-with-loader'
})`
  overflow: hidden;
`;

export const BlockImageWithLoaderInner = styled.div.attrs({
  className: 'b-image-with-loader__inner'
})`
  padding-top: ${({ type }) => {
    if (type === 'cinema') {
      return '56.2';
    }

    return '145';
  }}%;

  position: relative;
  background-color: ${({ theme }) => theme.color.bgSecondary};
  
  .b-image-with-loader__img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
  }
    
  .svg-preloader {
    width: 30%;
    height: 20.5%;
  }

  .svg-movie {
    width: 35%;
    height: 24%;
  }

  .svg-no-cinema {
    width: 24%;
    height: 43%;
  }

  .svg-preloader,
  .svg-movie,
  .svg-no-cinema {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    fill: ${({ theme }) => theme.color.rgbaWhite('.15')};

    & + .b-image-with-loader__img {
      display: none;
    }
  }
`;
