import { from, concat } from 'rxjs';
import { mergeMap, startWith, concatMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { handleErrors, setLoader, setError } from './deps';
import { orderSessionRequestSuccess, requestSesseionsTickets, setSesseionsBookingCount, setSesseionsSeatPlan, setSesseionsTickets } from "./actions"

import { ORDER_SESSION_REQUEST, SESSEIONS_BOOKING_COUNT_REQUEST, SESSEIONS_SEAT_PLAN_REQUEST, SESSEIONS_TICKETS_REQUEST } from './types';
import { createToast } from '../layout/actions';

/* eslint-disable-next-line */
export function fetchSessionTickets($action, $state, { api }) {
  const $api = api.getModuleByName('sessionsTickets');

  return $action.pipe(
    ofType(SESSEIONS_TICKETS_REQUEST),
    mergeMap(action => {
      const { permotionId, cinemaId } = action.payload;
      return from($api.fetchSessionTickets({ permotionId, cinemaId })).pipe(
        concatMap(cinemas => [setSesseionsTickets(cinemas), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [createToast('warning', err.message), setLoader(false)]),

        startWith(setLoader(true))
      );
    })
  );
}
export function fetchSessionBookingCount($action, $state, { api }) {
  const $api = api.getModuleByName('sessionsTickets');

  return $action.pipe(
    ofType(SESSEIONS_BOOKING_COUNT_REQUEST),
    mergeMap(action => {
      const { cinemaId, email, promotionId } = action.payload;
      return from($api.fetchSessionBookingCount({ cinemaId, email, promotionId })).pipe(
        concatMap(cinemas => [setSesseionsBookingCount(cinemas), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [createToast('warning', err.message), setLoader(false)]),

        startWith(setLoader(true))
      );
    })
  );
}
export function fetchSessionSeatPlan($action, $state, { api }) {
  const $api = api.getModuleByName('sessionsTickets');

  return $action.pipe(
    ofType(SESSEIONS_SEAT_PLAN_REQUEST),
    mergeMap(action => {
      const { cinemaId, email, promotionId } = action.payload;
      return from($api.fetchSessionSeatPlan({ cinemaId, email, promotionId })).pipe(
        concatMap(cinemas => [setSesseionsSeatPlan(cinemas), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [createToast('warning', err.message), setLoader(false)]),

        startWith(setLoader(true))
      );
    })
  );
}