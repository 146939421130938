import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 450;
  font-size: 18px;
  line-height: 20px;
  padding: 0.2rem;
  margin-top: 69px;
`;

// min-width: 288px;
export const PageContainer = styled.div.attrs({
  className: 'page-conainer'
})`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background: #222222;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5), 0px 4px 8px rgba(0, 0, 0, 0.5);
  border-radius: 8px;

  .page-conainer__dots {
    margin-left: 28px;
  }
`;

export const Page = styled.div`
    padding: 0.2rem;
    // font-weight: ${({ isActive }) => isActive && 'bold'};
    color: ${({ isActive }) => isActive && '#DE1619'};
    margin-left: 12px;
    cursor: pointer;

    &:first-child {
        margin-left: 0;
    }
`;

// export const PageInfo = styled.div`
//     display: grid;
//     grid-template-columns: auto auto 1fr;
//     grid-gap: 0.4rem;
//     align-items: center;
// `;

export const AllRight = styled.div`
  margin-left: 0px;
  display: flex;
  cursor: pointer;

  & > .svg-right-arrow {
    height: 32px;
    width: 32px;
  }
`;

export const Right = styled.div`
  display: flex;
  cursor: pointer;

  & > .svg-double-right-arrow {
    height: 32px;
    width: 43px;
  }
`;
export const AllLeft = styled.div`
  margin-left: 0px;
  display: flex;
  cursor: pointer;

  & > .svg-right-arrow {
    height: 32px;
    width: 32px;
    transform: rotate(180deg);
  }
`;

export const Left = styled.div`
  display: flex;
  cursor: pointer;
  
  & > .svg-double-right-arrow {
    height: 32px;
    width: 43px;
    transform: rotate(180deg);
  }
`;

export default Container;
