import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const BlockMain = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 22px; */
  margin-top: 16px;
  width: 100%;
  margin-left: ${({ isLoggedIn }) => (isLoggedIn ? '30px' : '6px')};
  ${({ isVisible }) => !isVisible && 'display: flex; visibility: hidden;'}
  
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    justify-content: flex-end;
    margin-left: 0;
    margin-top: 6px;
  }
`;

export const HeaderTag = styled.header.attrs({
  className: 'header'
})`
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  font-size: 0;
  position: fixed;
  min-width: 320px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  height: ${({ theme }) => theme.breakPoints.md.header};
  background-color: ${({ theme }) => theme.color.bgSecondary};
  
  & > .container {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
  }

  & + .footer {
    margin-top: auto;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    height: ${({ theme }) => theme.breakPoints.sm.header};
    margin-top: 0px;
  }
`;

export const LogoLink = styled(NavLink)`
.logo{
  display: flex;
}
  & > .svg-logo-new {
    width: 154px;
    height: 35px;
  }

  & > .svg-logo-new-md {
    width: 90px;
    height: 20px;
    display: none;
  }
  .heading-movies-details {
    font-size: 1rem;
    color: #be0003;
    width: 100%;
    padding-left: 30px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    margin: 0 auto;
    & > .svg-logo-new {
      display: none;
    }

    & > .svg-logo-new-md {
      display: block;
    }
  }
`;
export const CinemaDetailLogo = styled.div`
  .logo-new-details-md {   
    height: 20px;
    display: none;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {

   .logo-new-details-md {
  
    display: flex;
    color: red;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.xs.width}) {
  
  .logo-new-details-md {
      font-size:20px;
    }
   
  }
  @media screen and (max-width:320px) {
   
    & > .logo-new-details-md {
      font-size: 12px;
    }
    
  }


`;
