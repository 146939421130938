import { catchError, mergeMap, startWith, delay, retryWhen, retry, concatMap } from 'rxjs/operators';
import { from, concat, throwError, of } from 'rxjs';
import { ofType } from 'redux-observable';

import { setLoader, handleErrors, createToast, setErrorApi, setBooking } from './deps';
import {
  BUY_REQUEST,
  PURCHASE_REQUEST,
  PURCHASE_STATUS_REQUEST,
  RESERVE_REQUEST,
  PAYMENT_STATUS_REQUEST,
  BUY_WITH_BONUS_REQUEST,
  APPLY_GIFTCARD_REQUEST,
  BUY_WITH_GIFT_REQUEST,
  STEP_CHECKOUT_REQUEST
} from './types';
import {
  buySuccess,
  purchaseSuccess,
  purchaseRequest,
  setPaymentStatus,
  buyWithBonusSuccess,
  applyGiftcardSuccess,
  buyWithGiftSuccess,
  stepCheckoutSuccess
} from './actions';

export const reserve = ($action, $state, { api }) => {
  const $api = api.getModuleByName('payment');

  return $action.pipe(
    ofType(RESERVE_REQUEST),
    mergeMap(action => {
      const { userSessionId } = action.payload;

      return from($api.reserve(userSessionId)).pipe(
        mergeMap(booking =>
          concat([setBooking(booking), purchaseSuccess(booking), setLoader(false)])
        ),

        ...handleErrors(action),

        catchError(err => concat([createToast('warning', err.message), setLoader(false)])),

        startWith(setLoader(true))
      );
    })
  );
};

export const buy = ($action, $state, { api }) => {
  const $api = api.getModuleByName('payment');

  return $action.pipe(
    ofType(BUY_REQUEST),
    mergeMap(action => {
      const { userSessionId, email, notifyForm = () => { } } = action.payload;
      return from($api.buy(userSessionId, email)).pipe(
        mergeMap(payment => {
          notifyForm();

          return concat([buySuccess(payment), setLoader(false)]);
        }),

        ...handleErrors(action),

        catchError(err => {
          notifyForm();

          return concat([createToast('warning', err.message), setLoader(false)]);
        }),

        startWith(setLoader(true))
      );
    })
  );
};

export const fetchPurchase = ($action, $state, { api }) => {
  const $api = api.getModuleByName('payment');

  return $action.pipe(
    ofType(PURCHASE_REQUEST),
    mergeMap(action => {
      const { userSessionId } = action.payload;

      return from($api.getPurchase(userSessionId)).pipe(
        mergeMap(booking =>
          concat([purchaseSuccess(booking), setBooking(booking), setLoader(false)])
        ),

        ...handleErrors(action),

        catchError(err => concat([createToast('warning', err.message), setLoader(false)])),

        startWith(setLoader(true))
      );
    })
  );
};

export const fetchPurchaseStatus = ($action, $state, { api, session }) => {
  const $api = api.getModuleByName('payment');
  const codes = {
    SUCCESS: 'SUCCESS',
    WAIT_VISTA: 'WAIT_VISTA',
    OUT_OF_TIME: 'OUT_OF_TIME',
    ERROR: 'ERROR'
  };

  return $action.pipe(
    ofType(PURCHASE_STATUS_REQUEST),
    mergeMap(action => {
      let counter = 0;
      const { userSessionId } = action.payload;
      const { pingTimeoutSecs = 10, pingTimes = 3 } = session.getPayment() || {};

      return of({}).pipe(
        mergeMap(() => {
          return from($api.getStatusFinal(userSessionId)).pipe(
            mergeMap(({ status }) => {
              counter += 1;
              if (status === codes.SUCCESS) {
                return concat([purchaseRequest(userSessionId), setLoader(false)]);
              }

              if (status === codes.ERROR || status === codes.OUT_OF_TIME) {
                return concat([setErrorApi({ type: status, codes }), setLoader(false)]);
              }

              if (status === codes.WAIT_VISTA && counter < pingTimes) {
                return throwError({ status });
              }

              return concat([setErrorApi({ type: status, codes }), setLoader(false)]);
            }),

            catchError(err => {
              if (err.status) {
                return throwError(err);
              }

              return concat([createToast('warning', err.message), setLoader(false)]);
            })
          );
        }),

        retryWhen(errors => errors.pipe(delay(pingTimeoutSecs * 1000))),
        startWith(setLoader(true))
      );
    })
  );
};

export const fetchPaymentStatus = ($action, $state, { api }) => {
  const $api = api.getModuleByName('payment');
  const codes = {
    SUCCESS: 0,
    ERROR: 1,
    PROCESSED: 2,
    /**
     * This is custom ERROR code, appears when request is fails for some reasons
     */
    FAIL: 3
  };

  return $action.pipe(
    ofType(PAYMENT_STATUS_REQUEST),
    mergeMap(action => {
      let counter = 0;
      const { userSessionId } = action.payload;

      return of({}).pipe(
        mergeMap(() => {
          return from($api.getStatus(userSessionId)).pipe(
            mergeMap(({ state }) =>
              concat([setPaymentStatus({ status: state, codes }), setLoader(false)])
            ),
            catchError(err => {
              counter += 1;

              if (counter <= 2) {
                return throwError(err);
              }

              return concat([setPaymentStatus({ status: codes.FAIL, codes }), setLoader(false)]);
            })
          );
        }),

        retry(2),
        startWith(setLoader(true))
      );
    })
  );
};

export const buyWithBonus = ($action, $state, { api }) => {
  const $api = api.getModuleByName('payment');
  const codes = {
    SUCCESS: 0,
    ERROR: 1,
    PROCESSED: 2,
    /**
     * This is custom ERROR code, appears when request is fails for some reasons
     */
    FAIL: 3
  };

  return $action.pipe(
    ofType(BUY_WITH_BONUS_REQUEST),
    mergeMap(action => {
      const { userSessionId, notifyForm = () => { } } = action.payload;

      return from($api.buyWithBonusCard(userSessionId)).pipe(
        mergeMap(payment => {
          notifyForm();

          return concat([buyWithBonusSuccess(payment), setLoader(false)]);
        }),

        ...handleErrors(action),

        catchError(err => {
          notifyForm();

          return concat([
            createToast('warning', err.message),
            setPaymentStatus({ status: codes.FAIL, codes }),
            setLoader(false)
          ]);
        }),

        startWith(setLoader(true))
      );
    })
  );
};

export const applyGiftCardsToPayment = ($action, $state, { api }) => {
  const $api = api.getModuleByName('payment');

  return $action.pipe(
    ofType(APPLY_GIFTCARD_REQUEST),
    mergeMap(action => {
      const {
        payload: { userSessionId, payload, notifyForm = () => { } }
      } = action;

      return from($api.applyGiftcards(userSessionId, payload)).pipe(
        mergeMap(res => {
          notifyForm();

          return [applyGiftcardSuccess(res), setLoader(false)];
        }),

        ...handleErrors(action),
        catchError(err => {
          notifyForm();

          return concat([createToast('warning', err.message), setLoader(false)]);
        }),

        startWith(setLoader(true))
      );
    })
  );
};

export const buyWithGift = ($action, $state, { api }) => {
  const $api = api.getModuleByName('payment');
  const codes = {
    SUCCESS: 0,
    ERROR: 1,
    PROCESSED: 2,
    FAIL: 3
  };

  return $action.pipe(
    ofType(BUY_WITH_GIFT_REQUEST),
    mergeMap(action => {
      const { userSessionId, payload, email, notifyForm = () => { } } = action.payload;

      return from($api.buyWithGiftCard(userSessionId, payload, email)).pipe(
        mergeMap(payment => {
          notifyForm();

          return concat([buyWithGiftSuccess(payment), setLoader(false)]);
        }),

        ...handleErrors(action),

        catchError(err => {
          notifyForm();

          return concat([
            createToast('warning', err.message),
            setPaymentStatus({ status: codes.FAIL, codes }),
            setLoader(false)
          ]);
        }),

        startWith(setLoader(true))
      );
    })
  );
};


export function fetchStepCheckout($action, $state, { api }) {
  const $api = api.getModuleByName('technologies');
  return $action.pipe(
    ofType(STEP_CHECKOUT_REQUEST),
    mergeMap(action =>
      from($api.getStepCheckout()).pipe(
        concatMap(res => [stepCheckoutSuccess(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => concat([createToast('warning', err.message), setLoader(false)])),

        startWith(setLoader(true))
      )
    )
  );
}
