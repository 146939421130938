import React from 'react';
import styled from 'styled-components';

const Radio = props => {
  const StyledInput = styled.input.attrs({
    type: 'radio'
  })`
    &:checked + .f-default__radio-btn {
      border-color: ${({ theme }) => theme.color.accent};
      &::before {
        transform: scale(0.65);
      }
      &::after {
        background-color: ${({ theme }) => theme.color.accent};
        transform: scale(2.5);
        opacity: 0;
      }
    }

    & + .f-default__radio-btn {
      display: inline-block;
      vertical-align: middle;
      width: 20px;
      height: 20px;
      border: 2px solid ${({ theme }) => theme.color.borderLight};
      border-radius: 50%;
      position: relative;
      transition: border-color 0.2s ease-out;
      &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.color.accent};
        transform: scale(0);
        transition: transform 0.2s;
      }
      &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        transform: scale(0.8);
        transition: transform 0.2s ease-out, opacity 0.2s ease-in;
        pointer-events: none;
      }
    }
  `;

  return (
    <label>
      <StyledInput {...props} />
      <span className="f-default__radio-btn" />
    </label>
  );
};

export default Radio;
