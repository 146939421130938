export const schema = () => ({
  bookingNumber: [{ rule: "required" }],
  email: [{ rule: "required" }, { rule: "email" }],
});

// export const defaults = {
//   sendNewsletter: false,
//   privacyPolicy: false,
//   tac: false,
//   pdProcessing: false
// };


export default schema;