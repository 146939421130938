import styled, { css } from 'styled-components';
import { inputGeneric } from 'app/theme';

export const InputField = styled.input.attrs({
  className: 'f-default__field'
})`
  width: 100%;
  height: 100%;
  padding: 0 ${({ theme }) => theme.indent.m};
  color: ${({ theme }) => theme.color.textPrimary};
  font-size: inherit;

  &:-ms-input-placeholder,
  &::-ms-input-placeholder {
    color: ${({ theme }) => theme.color.labelColor};
  }

  &::placeholder {
    color: ${({ theme }) => theme.color.labelColor};
  }
`;

export const InputRow = styled.div.attrs({
  className: 'f-default__row'
})`
  ${inputGeneric}
  overflow: hidden;
  position: relative;

  ${props =>
    props.error &&
    css`
      border-color: ${({ theme }) => theme.color.rgbaAccent('.8')};
      box-shadow: 0 0 8px 0 ${({ theme }) => theme.color.rgbaAccent('.7')};
    `}

  ${props =>
    !props.error &&
    css`
      &:focus-within {
        box-shadow: 0 0 8px 0 ${({ theme }) => theme.color.rgbaWhite('.24')};
        border-color: ${({ theme }) => theme.color.rgbaWhite('.8')};
      }
    `}

  & > .svg-search {
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    margin-left: ${({ theme }) => theme.indent.m};
    fill: ${({ theme }) => theme.color.rgbaWhite('.8')};

    & + ${InputField} {
      padding-left: ${({ theme }) => theme.indent.s};
    }
  }

  & > .f-default__field-btn-clear {
    flex-shrink: 0;
    width: 20px;
    height: 100%;
    margin-right: 9px;
  }

  & > .f-default-btn-toggle-pass {
    flex-shrink: 0;
    margin-right: 14px;
    width: 25px;
    height: 100%;
  }

  & > [type='text'] + .f-default-btn-toggle-pass > .svg-input-eye {
    fill: ${({ theme }) => theme.color.rgbaWhite('.8')};
  }

  .svg-input-eye,
  .svg-input-clear {
    width: 100%;
    height: 100%;
    fill: ${({ theme }) => theme.color.svgInput};
    transition: fill 0.2s ease-out;

    &:hover {
      fill: ${({ theme }) => theme.color.rgbaWhite('.8')};
    }
  }

  .svg-calendar {
    width: 1.35em;
    height: 100%;
    position: absolute;
    top: 0;
    right: ${({ theme }) => theme.indent.m};
    fill: ${({ theme }) => theme.color.accent};
    pointer-events: none;
  }

  input[value=''] + .svg-calendar {
    fill: ${({ theme }) => theme.color.svgInput};
  }
`;
