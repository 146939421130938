import React from 'react';
import PropTypes from 'prop-types';
import BlockMain from './BlockMain';
import BlockSecondary from './BlockSecondary';
import { FooterTag } from './styles';

const propTypes = {
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  savedLocation: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  saveLocation: PropTypes.func.isRequired,
  footerNewsLetter: PropTypes.func.isRequired,
  newsLetterContent: PropTypes.array.isRequired,
  newsLetterResult: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  barCode: PropTypes.string.isRequired,
};

const Footer = ({ locations, savedLocation, isVisible, saveLocation, setNewsLetter, newsLetter, pathname, footerNewsLetter, newsLetterContent, newsLetterResult, user, barCode }) => (
  <FooterTag isVisible={isVisible}>
    <BlockMain locations={locations} savedLocation={savedLocation} saveLocation={saveLocation} setNewsLetter={setNewsLetter} pathname={pathname} footerNewsLetter={footerNewsLetter} newsLetterContent={newsLetterContent} newsLetterResult={newsLetterResult} user={user} barCode={barCode}/>
    <BlockSecondary />
  </FooterTag>
);

Footer.propTypes = propTypes;
export default Footer;
