import styled from 'styled-components';

export default styled.div.attrs({
  className: 'b-movies-filter'
})`
  ${({ theme, withPadding }) => withPadding && `padding: ${theme.indent.l} 0;`}
  text-align: right;  
  .movie-detail{
    display: flex;
    justify-content: space-between;
    padding: 12px 12px;
    width:100%;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.xs.width}) {
    display: flex;
    justify-content: space-between;
    z-index: 15;
    ${({ theme, withPadding }) => withPadding && `padding: ${theme.indent.m} 0;`}

    & > div {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
`;
