import { mergeMap, startWith, concatMap, map, catchError } from 'rxjs/operators';
import { from, concat } from 'rxjs';
import { ofType } from 'redux-observable';
import { setLoader, handleErrors, createToast, setError } from './deps';
import {
  CINEMAS_REQUEST,
  CINEMAS_WITH_MOVIES_REQUEST,
  TOGGLE_FAVORITE_REQUEST,
  CINEMA_REQUEST,
  DATES_REQUEST,
  MOVIE_SESSIONS_REQUEST,
  CINEMA_EXTRA_INFO_FETCH,
  MOVIE_OPERA_SESSIONS_REQUEST,
  MOVIE_OPERA_SCREENS_REQUEST
  // CINEMAS_WITH_MOVIES_REQUEST_LOCATION
} from './types';
import {
  successCinemas,
  successToggleFavorite,
  setCinemasWithMovies,
  setDates,
  setCinema,
  setMovieSessions,
  cinemaExtraInfoGetSuccess,
  setMovieOperaSessions,
  setMovieOperaScreens
  // setCinemasWithMoviesLocation
} from './actions';

export function fetchCinemas($action, $state, { api }) {
  const $apiCinemas = api.getModuleByName('cinemas');

  return $action.pipe(
    ofType(CINEMAS_REQUEST),
    mergeMap(action =>
      from($apiCinemas.fetchAll()).pipe(
        concatMap(res => [successCinemas(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      )
    )
  );
}

export function fetchCinemasWithMovies($action, $state, { api }) {
  const $api = api.getModuleByName('cinemas');

  return $action.pipe(
    ofType(CINEMAS_WITH_MOVIES_REQUEST),
    mergeMap(action => {
      const { date } = action.payload;

      return from($api.fetchWithMovies({ date })).pipe(
        concatMap(cinemas => [setCinemasWithMovies(cinemas), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [createToast('warning', err.message), setLoader(false)]),

        startWith(setLoader(true))
      );
    })
  );
}

// export function fetchForCinemasWithMovies($action, $state, { api }) {
//   const $api = api.getModuleByName('cinemas');
//   return $action.pipe(
//     ofType(CINEMAS_WITH_MOVIES_REQUEST_LOCATION),
//     mergeMap(action => {
//       const { date } = action.payload;
//       return from($api.fetchWithMovies({ date })).pipe(
//         concatMap(cinemas => [setCinemasWithMoviesLocation(cinemas), setLoader(false)]),

//         ...handleErrors(action),
//         catchError(err => [createToast('warning', err.message), setLoader(false)]),

//         startWith(setLoader(true))
//       );
//     })
//   );
// }

export function fetchDates($action, $state, { api }) {
  const $api = api.getModuleByName('movies');

  return $action.pipe(
    ofType(DATES_REQUEST),
    mergeMap(action => {
      const { cinemaId, location } = action.payload;

      return from($api.fetchDatesList({ cinemaId, location })).pipe(
        concatMap(dates => [setDates(dates), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      );
    })
  );
}

export function toggleCinemaFavorite($action, $state, { api }) {
  const $api = api.getModuleByName('cinemas');

  return $action.pipe(
    ofType(TOGGLE_FAVORITE_REQUEST),
    mergeMap(action => {
      const {
        payload: { id, favorite, loyaltyCode }
      } = action;

      return from($api.toggleFavorite(id, favorite)).pipe(
        map(({ preferredComplexList }) => {
          const value = preferredComplexList.indexOf(loyaltyCode) >= 0;

          return successToggleFavorite(
            {
              id,
              favorite: value,
              loyaltyCode
            },
            preferredComplexList
          );
        }),

        ...handleErrors(action),
        catchError(err => {
          const actions = [
            successToggleFavorite(
              {
                id,
                favorite: true,
                loyaltyCode
              },
              [loyaltyCode]
            )
          ];

          if (err.type === 'GENERAL' && err.code === 0) {
            actions.push(createToast('warning', 'cinemas.favorite_restriction'));
          } else {
            actions.push(createToast('warning', err.message));
          }

          return concat(actions);
        })
      );
    })
  );
}

export function fetchCinema($action, $state, { api }) {
  const $api = api.getModuleByName('cinemas');

  return $action.pipe(
    ofType(CINEMA_REQUEST),
    mergeMap(action => {
      const { id } = action.payload;

      return from($api.fetchById(id)).pipe(
        concatMap(cinema => [setCinema(cinema), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      );
    })
  );
}
export function fetchOperaCinema($action, $state, { api }) {
  const $api = api.getModuleByName('cinemas');

  return $action.pipe(
    ofType(MOVIE_OPERA_SESSIONS_REQUEST),
    mergeMap(action => {
      const { cinemaId, AboIds } = action.payload;
      return from($api.fetchByIdSeasonSessions(cinemaId, AboIds)).pipe(
        concatMap(cinema => [setMovieOperaSessions(cinema), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      );
    })
  );
}

export function fetchOperaScreens($action, $state, { api }) {
  const $api = api.getModuleByName('cinemas');

  return $action.pipe(
    ofType(MOVIE_OPERA_SCREENS_REQUEST),
    mergeMap(action => {
      const { cinemaId } = action.payload;
      return from($api.fetchByIdSeasonScreens(cinemaId)).pipe(
        concatMap(cinema => [setMovieOperaScreens(cinema), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      );
    })
  );
}

export function fetchMovieSessions($action, $state, { api }) {
  const $api = api.getModuleByName('cinemas');

  return $action.pipe(
    ofType(MOVIE_SESSIONS_REQUEST),
    mergeMap(action => {
      const { id, date } = action.payload;

      return from($api.fetchMoviesToCinema(id, { date })).pipe(
        concatMap(sessions => [setMovieSessions(sessions), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      );
    })
  );
}

export function fetchCinemaExtraInfo($action, $state, { api }) {
  const $api = api.getModuleByName('technologies');

  return $action.pipe(
    ofType(CINEMA_EXTRA_INFO_FETCH),
    mergeMap(action => {
      return from($api.fetchCinemaExtraInfo()).pipe(
        concatMap(res => [cinemaExtraInfoGetSuccess(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      );
    })
  );
}
