import { CINEMA_RENT_INQUIRY_REQUEST_SUCCESS, CINEMA_RENT_SUCCESS } from './types';

const initialState = {
  cinemaRentInquiry: [],
  cinemaRent: []
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case CINEMA_RENT_INQUIRY_REQUEST_SUCCESS: {

      return {
        ...state,
        cinemaRentInquiry: Object.values(payload.cinemaRentInquiry)
      };
    }

    case CINEMA_RENT_SUCCESS: {
      return {
        ...state,
        cinemaRent: payload.cinemaRent?.data
      };
    }

    default: {
      return state;
    }
  }
}
