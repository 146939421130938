import {
  SESSEIONS_TICKETS_REQUEST,
  SESSIONS_TICKET_SUCCESS,
  SESSEIONS_BOOKING_COUNT_REQUEST,
  SESSIONS_BOOKING_COUNT_SUCCESS,
  SESSEIONS_SEAT_PLAN_REQUEST,
  SESSIONS_SEAT_PLAN_SUCCESS,
  SET_SESSEIONS_TICKETS,
  CLEAR_SESSEION_SEAT_PLAN,
  ORDER_SESSION_REQUEST,
  ORDER_SESSION_REQUEST_SUCCESS
} from "./types";


export const requestSesseionsTickets = ({ permotionId, cinemaId }) => ({
  type: SESSEIONS_TICKETS_REQUEST,
  payload: { permotionId, cinemaId }
});

export const setSesseionsTickets = (payload) => ({
  type: SESSIONS_TICKET_SUCCESS,
  payload: payload
});


export const requestSesseionsBookingCount = ({ cinemaId, email, promotionId }) => ({
  type: SESSEIONS_BOOKING_COUNT_REQUEST,
  payload: { cinemaId, email, promotionId }
});

export const setSesseionsBookingCount = (payload) => ({
  type: SESSIONS_BOOKING_COUNT_SUCCESS,
  payload: payload
});
export const requestSesseionsSeatPlan = ({ cinemaId, email, promotionId }) => ({
  type: SESSEIONS_SEAT_PLAN_REQUEST,
  payload: { cinemaId, email, promotionId }
});

export const setSesseionsSeatPlan = plans => ({
  type: SESSIONS_SEAT_PLAN_SUCCESS,
  payload: { plans }
});

export const clearSesseionSeatPlan = () => ({
  type: CLEAR_SESSEION_SEAT_PLAN
});

export const orderSessionRequest = (cinemaId, sessionId, tickets) => ({
  type: ORDER_SESSION_REQUEST,
  payload: {
    cinemaId,
    sessionId,
    tickets
  }
});
export const orderSessionRequestSuccess = order => ({
  type: ORDER_SESSION_REQUEST_SUCCESS,
  payload: {
    order
  }
});
// export const setUpcomingMovies = ({ films, upcomingDates }) => ({
//   type: SESSIONS_TICKET_SUCCESS,
//   payload: { films, upcomingDates }
// });