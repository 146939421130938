import { SESSION_REQUEST, SET_SESSION, CLEAR_SESSION, WIZARD_CLEAR, WIZARD_SET } from './types';

export const sessionRequest = id => ({
  type: SESSION_REQUEST,
  payload: {
    id
  }
});
export const setSession = sessionData => ({
  type: SET_SESSION,
  payload: {
    sessionData
  }
});
export const clearSession = () => ({
  type: CLEAR_SESSION
});

export const wizardSet = wizard => ({
  type: WIZARD_SET,
  payload: {
    wizard
  }
});
export const wizardClear = () => ({
  type: WIZARD_CLEAR
});
