import {
  CINEMA_ADVERTISING_SUCCESS,
  ONLINE_ADVERTISING_SUCCESS,
  PRESS_INFO_SUCCESS
} from './types';

const initialState = {
  advertising: {
    cinema: [],
    online: [],
    press: []
  }
};

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case CINEMA_ADVERTISING_SUCCESS: {
      return {
        ...state,
        advertising: {
          ...state.advertising,
          cinema: payload.cinemaAdvertising?.data
        }
      };
    }

    case ONLINE_ADVERTISING_SUCCESS: {
      return {
        ...state,
        advertising: {
          ...state.advertising,
          online: payload.onlineAdvertising?.data
        }
      };
    }

    case PRESS_INFO_SUCCESS: {
      return {
        ...state,
        advertising: {
          ...state.advertising,
          press: payload.pressInfo?.data
        }
      };
    }

    default:
      return state;
  }
}
