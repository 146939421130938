import React from 'react';
import styled, { ThemeConsumer, css } from 'styled-components';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import Svg from 'app/components/Svg';

const propTypes = {
  children: PropTypes.element.isRequired,
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  isSmall: PropTypes.bool,
  isMiddleWidth: PropTypes.bool,
  closeButton:PropTypes.bool,
};

const defaultProps = {
  title: '',
  isSmall: false,
  isMiddleWidth: false,
  isOpen: false,
  closeButton: true
};

const Title = styled.div`
  position: relative;
  padding: 7px 46px 7px ${({ theme }) => theme.indent.l};
  border-bottom: ${({ theme }) => theme.color.borderDark} solid 1px;
  color: ${({ theme }) => theme.color.accent};
  font-size: 2rem;
  font-weight: 700;
  text-transform: capitalize;
`;

const CloseBtn = styled.button.attrs({
  type: 'button'
})`
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;

  & > .svg-close {
    display: block;
    fill: ${({ theme }) => theme.color.textPrimary};
    width: 24px;
    height: 24px;
    transition: opacity 0.2s ease-out;
    opacity: 0.8;
    backface-visibility: hidden;
  }

  &:hover > .svg-close {
    opacity: 1;
  }
`;

const Content = styled.div`

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 40rem;
z-index: 10;

  label {
    text-align: left;
  }

  ${props =>
    props.small
      ? css`
          padding: 24px 16px;
        `
      : css`
          padding: 24px 16px 48px;
        `}

  ${props =>
    props.middleWidth &&
    css`
      text-align: left;
    `}

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
    padding: 16px 16px 24px;
  }
`;

const BonusModal = ({ children, title, close, isOpen, isSmall, isMiddleWidth, closeButton }) => (
  <ThemeConsumer>
    {theme => {
      const styles = {
        overlay: {
          minWidth: '320px',
          padding: '0 10px',
          zIndex: 12,
          overflowY: 'auto',
          backgroundColor: theme.color.rgbaBlack(0.5),
          textAlign: 'center'
        },
        content: {
          display: 'inline-block',
          verticalAlign: 'middle',
          width: '100%',
          height:"480px",
          maxWidth: isMiddleWidth ? 640 : 440,
          margin: '30px 0',
          padding: 0,
          position: 'relative',
          top: 'auto',
          left: 'auto',
          bottom: 'auto',
          right: 'auto',
          borderRadius: theme.indent.s,
          border: 0,
          boxShadow: `0 3px 16px 0 ${theme.color.rgbaBlack(0.5)}`,
          backgroundColor: theme.color.bgPopup
        }
      };

      return (
        <ReactModal isOpen={isOpen} style={styles} onRequestClose={close}>
          <Title>
            {title}

            {closeButton &&  <CloseBtn onClick={close}>
              <Svg id="close" />
            </CloseBtn>}
          </Title>

          <Content small={isSmall} middleWidth={isMiddleWidth}>
            {children}
          </Content>
        </ReactModal>
      );
    }}
  </ThemeConsumer>
);

BonusModal.propTypes = propTypes;
BonusModal.defaultProps = defaultProps;
export default React.memo(BonusModal);
