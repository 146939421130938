import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  id: PropTypes.string.isRequired
};

const Svg = ({ id }) => (
  <svg className={`svg-${id}`}>
    <use xlinkHref={`#svg-${id}`} />
  </svg>
);

Svg.propTypes = propTypes;
export default Svg;
