import { catchError, mergeMap, startWith, concatMap } from 'rxjs/operators';
import { concat, from, of } from 'rxjs';
import { ofType } from 'redux-observable';

import { setLoader, handleUnknownError, createToast, setModal, logoutRequest } from './deps';
import { setUser, setTransactions, updateUserSuccess, deleteUserSuccess, setReservedPayment, setReservedGiftcardPayment } from './actions';
import {
  CHANGE_PASSWORD_REQUEST,
  DELETE_REQUEST,
  TRANSACTIONS_REQUEST,
  UPDATE_REQUEST,
  RESERVED_PAYMENT_REQUEST,
  RESERVED_GIFTCARD_PAYMENT_REQUEST
} from './types';
import { handleErrors } from '../utils';

export function update($action, $state, { api }) {
  const $api = api.getModuleByName('auth');

  return $action.pipe(
    ofType(UPDATE_REQUEST),
    mergeMap(({ payload: { data, notifyForm = () => { } } }) => {
      return from($api.updateUser(data)).pipe(
        concatMap(user => {
          notifyForm();

          return [updateUserSuccess(data, user), setUser(user), setLoader(false)];
        }),

        catchError(handleUnknownError),
        catchError(err => {
          const actions = [setLoader(false)];
          if (err.errors) {
            notifyForm(err.errors);
          } else {
            // actions.push(createToast('warning', err.message.replace(':', '')));
            // notifyForm();
          }

          return concat(actions);
        }),

        startWith(setLoader(true))
      );
    })
  );
}

export function changePassword($action, $state, { api }) {
  const $api = api.getModuleByName('auth');

  return $action.pipe(
    ofType(CHANGE_PASSWORD_REQUEST),
    mergeMap(({ payload: { data, notifyForm } }) =>
      from($api.changePassword(data)).pipe(
        concatMap(user => {
          notifyForm();

          return [
            setUser(user),
            createToast('info', 'account.change_password.toaster'),
            setLoader(false)
          ];
        }),

        catchError(handleUnknownError),
        catchError(err => {
          const actions = [setLoader(false)];

          if (err.errors) {
            notifyForm(err.errors);
          } else {
            actions.push(createToast('warning', err.message.replace(':', '')));
            notifyForm();
          }

          return concat(actions);
        }),

        startWith(setLoader(true))
      )
    )
  );
}

export function deleteUser($action, $state, { api }) {
  const $api = api.getModuleByName('user');

  return $action.pipe(
    ofType(DELETE_REQUEST),
    mergeMap(action => {
      const {
        user: {
          user: { email }
        }
      } = $state.value;

      return from($api.removeAccount()).pipe(
        concatMap(() => [
          setModal('deleteAccount', false),
          setLoader(false),
          createToast('info', ['toasters.user_account_deleted', { email }]),
          logoutRequest(),
          deleteUserSuccess()
        ]),

        ...handleErrors(action),

        catchError(err => of(createToast('warning', err.message), setLoader(false))),

        startWith(setLoader(true))
      );
    })
  );
}

export function fetchTransactions($action, $state, { api }) {
  const $api = api.getModuleByName('user');

  return $action.pipe(
    ofType(TRANSACTIONS_REQUEST),
    mergeMap(action =>
      from($api.getTransactions()).pipe(
        concatMap(transactions => [setTransactions(transactions), setLoader(false)]),

        ...handleErrors(action),

        catchError(err => of(createToast('warning', err.message), setLoader(false))),

        startWith(setLoader(true))
      )
    )
  );
}

export const fetchReservedPayment = ($action, $state, { api }) => {
  const $api = api.getModuleByName('user');

  return $action.pipe(
    ofType(RESERVED_PAYMENT_REQUEST),
    mergeMap(action => {
      const { params } = action.payload;

      return from($api.fetchBooking(params)).pipe(
        mergeMap(res =>
          concat([setReservedPayment(res), setLoader(false)])
        ),

        ...handleErrors(action),

        catchError(err => concat([createToast('warning', err.message), setLoader(false)])),

        startWith(setLoader(true))
      );
    })
  );
};

export const fetchReservedGiftcardPayment = ($action, $state, { api }) => {
  const $api = api.getModuleByName('user');

  return $action.pipe(
    ofType(RESERVED_GIFTCARD_PAYMENT_REQUEST),
    mergeMap(action => {
      const { params } = action.payload;
      const bookingNumber = params.bookingNumber
      const payload = params.payload

      return from($api.fetchGiftcardBooking(bookingNumber, payload)).pipe(
        mergeMap(res =>
          concat([setReservedGiftcardPayment(res), setLoader(false)])
        ),

        ...handleErrors(action),

        catchError(err => concat([createToast('warning', err.message), setLoader(false)])),

        startWith(setLoader(true))
      );
    })
  );
};
