export const CINEMAS_REQUEST = 'cinemas/CINEMAS_REQUEST';
export const CINEMAS_SUCCESS = 'cinemas/CINEMAS_SUCCESS';

export const CINEMAS_WITH_MOVIES_REQUEST = 'cinemas/CINEMAS_WITH_MOVIES_REQUEST';
export const SET_CINEMAS_WITH_MOVIES = 'cinemas/SET_CINEMAS_WITH_MOVIES';

export const DATES_REQUEST = 'cinemas/DATES_REQUEST';
export const SET_DATES = 'cinemas/SET_DATES';

export const CINEMA_REQUEST = 'cinemas/CINEMA_REQUEST';
export const SET_CINEMA = 'cinemas/SET_CINEMA';

export const MOVIE_SESSIONS_REQUEST = 'cinemas/MOVIE_SESSIONS_REQUEST';
export const SET_MOVIE_SESSIONS = 'cinemas/SET_MOVIE_SESSIONS';

export const MOVIE_OPERA_SESSIONS_REQUEST = 'cinemas/MOVIE_OPERA_SESSIONS_REQUEST';
export const SET_MOVIE_OPERA_SESSIONS = 'cinemas/SET_MOVIE_OPERA_SESSIONS';

export const MOVIE_OPERA_SCREENS_REQUEST = 'cinemas/MOVIE_OPERA_SCREENS_REQUEST';
export const SET_MOVIE_OPERA_SCREENS = 'cinemas/SET_MOVIE_OPERA_SCREENS';

export const TOGGLE_FAVORITE_REQUEST = 'cinemas/TOGGLE_FAVORITE_REQUEST';
export const TOGGLE_FAVORITE_SUCCESS = 'cinemas/TOGGLE_FAVORITE_SUCCESS';

// export const CINEMAS_WITH_MOVIES_REQUEST_LOCATION = 'cinemas/CINEMAS_WITH_MOVIES_REQUEST_LOCATION';
// export const SET_CINEMAS_WITH_MOVIES_GET_LOCATION = 'cinemas/SET_CINEMAS_WITH_MOVIES_GET_LOCATION';

export const CINEMA_EXTRA_INFO_FETCH = 'cinemas/CINEMA_EXTRA_INFO_FETCH';
export const CINEMA_EXTRA_INFO_SUCCESS = 'cinemas/CINEMA_EXTRA_INFO_SUCCESS';
