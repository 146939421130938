import {
  BALANCE_REQUEST,
  BALANCE_SUCCESS,
  BONUS_CARD_INFO_REQUEST,
  BONUS_CARD_INFO_SUCCESS,
  CONCESSIONS_REQUEST,
  CONCESSIONS_SUCCESS,
  ORDER_CONCESSION_REQUEST,
  ORDER_CONCESSION_SUCCESS,
  REWARDS_REQUEST,
  REWARDS_SUCCESS
} from './types';

export const rewardsRequest = () => ({
  type: REWARDS_REQUEST
});

export const rewardsSuccess = rewards => ({
  type: REWARDS_SUCCESS,
  payload: { rewards }
});

export const balanceRequest = () => ({
  type: BALANCE_REQUEST
});

export const balanceSuccess = balance => ({
  type: BALANCE_SUCCESS,
  payload: { balance }
});

export const concessionsRequest = () => ({
  type: CONCESSIONS_REQUEST
});

export const concessionsSuccess = concessions => ({
  type: CONCESSIONS_SUCCESS,
  payload: { concessions }
});

export const orderConcessionRequest = (concessionId, notifyForm) => ({
  type: ORDER_CONCESSION_REQUEST,
  payload: {
    concessionId,
    notifyForm
  }
});

export const orderConcessionSuccess = orderedConcession => ({
  type: ORDER_CONCESSION_SUCCESS,
  payload: {
    orderedConcession
  }
});

export const bonusCardInfoRequest = () => ({
  type: BONUS_CARD_INFO_REQUEST
});

export const bonusCardInfoSuccess = bonusCardInfo => ({
  type: BONUS_CARD_INFO_SUCCESS,
  payload: { bonusCardInfo }
});
