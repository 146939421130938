import React from 'react';
import PropTypes from 'prop-types';
import useConfig from 'app/hooks/useConfig';

const propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string
};

const defaultProps = {
  alt: ''
};

const Image = ({ src, alt, ...props }) => {
  const {
    media: { imgPrefix }
  } = useConfig();
  const url = imgPrefix(src);

  return <img src={url} alt={alt} {...props} />;
};

Image.propTypes = propTypes;
Image.defaultProps = defaultProps;
export default Image;
