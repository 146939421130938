import styled from 'styled-components';

export default styled.div`
  // max-height: 1000px;
  max-height: 1036px;
  overflow: auto;
  transition: max-height 0.2s ease-in, border-color 0.2s ease-out;

  ${({ isExpanded }) =>
    !isExpanded &&
    `
    max-height: 0;
    transition-timing-function: ease-out;
  `}
`;
