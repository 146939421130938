/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable array-callback-return  */
/* eslint-disable jsx-a11y/anchor-is-valid  */
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Container, Button } from 'app/components';
import { useConfig } from 'app/hooks';
import { CookiesAgreementComponent } from './styles';

const propTypes = {
  info: PropTypes.shape({
    doc: PropTypes.string,
    docType: PropTypes.string,
    label: PropTypes.string
  }),
  acceptCookiesAgreement: PropTypes.func.isRequired,
  declineCookiesAgreement: PropTypes.func.isRequired
};

const defaultProps = {
  info: {}
};

const CookiesAgreement = ({ info, acceptCookiesAgreement, declineCookiesAgreement }) => {
  const [t] = useTranslation();
  const [isAgreementAccepted, setAcceptStatus] = useState(false);
  const {
    media: { docsPrefix }
  } = useConfig();

  const onAccept = useCallback(() => {
    acceptCookiesAgreement();
    setAcceptStatus(true);
  }, []);

  const onDecline = useCallback(() => {
    declineCookiesAgreement();
    setAcceptStatus(true);
  }, []);

  if (isAgreementAccepted) {
    return null;
  }

  return (
    <CookiesAgreementComponent>
      <Container>
        <p className="b-cookies-agreement__text">
          {t('cookies.info')}
          <a
            href={docsPrefix(info.doc)}
            target="_blank"
            rel="noopener noreferrer"
            className="b-cookies-agreement__link"
          >
            {t('cookies.link')}
          </a>
        </p>
        <div className="b-cookies-agreement__buttons">
          <Button isDefault onClick={onDecline}>
            {t('cookies.decline')}
          </Button>
          <Button isPrimary onClick={onAccept}>
            {t('cookies.accept')}
          </Button>
        </div>
      </Container>
    </CookiesAgreementComponent>
  );
};

CookiesAgreement.propTypes = propTypes;
CookiesAgreement.defaultProps = defaultProps;
export default CookiesAgreement;
