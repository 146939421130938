import React from 'react';
import styled from 'styled-components';

const Switch = props => {
  const StyledInput = styled.input.attrs({
    type: 'checkbox'
  })`
    & + .f-default__switch-slider {
      display: inline-block;
      vertical-align: middle;
      width: 51px;
      height: 31px;
      position: relative;
      border-radius: 16px;
      border: 2px solid #e5e5ea;
      transition: background-color 0.2s, border-color 0.25s ease-out;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 27px;
        height: 27px;
        box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.1),
          0 1px 1px 0 rgba(0, 0, 0, 0.16), 0 3px 8px 0 rgba(0, 0, 0, 0.15);
        background-color: ${({ theme }) => theme.color.textPrimary};
        border-radius: 50%;
        transition: transform 0.25s ease-out;
      }
    }

    &:checked {
      & + .f-default__switch-slider {
        background-color: ${({ theme }) => theme.color.accent};
        border-color: ${({ theme }) => theme.color.accent};
      }
      & + .f-default__switch-slider::before {
        transform: translateX(20px);
      }
    }
  `;

  return (
    <label>
      <StyledInput {...props} />
      <span className="f-default__switch-slider" />
    </label>
  );
};

export default Switch;
