import styled from 'styled-components';

export default styled.div.attrs({
  className: 'b-session-state'
})`
  /* padding: 4px 10px; */
  padding: 4px 5px;
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.color.borderDark};
  margin-top: 20px;

  .b-session-state__action {
    display: none;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 2px 0;
    font-size: 1.6rem;
    color: #fff;

    .svg-tickets-desk,
    .svg-tickets-locked {
      margin-left: 10px;
    }
  }

  .b-session-state__inner {
    overflow: hidden;
    transition: max-height 0.2s ease-out, margin 0.2s ease-out;
  }

  .b-session-state__description {
    display: inline-block;
    padding: 7px 0;
    line-height: 0;

    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.indent.s};
    }
  }

  .b-session-state__description-text {
    display: inline-block;
    vertical-align: middle;
    max-width: calc(100% - 24px);
    padding-left: ${({ theme }) => theme.indent.xs};
    line-height: 1.4;
    font-size: 14px;
  }

  .svg-tickets-noreserve,
  .svg-tickets-desk,
  .svg-tickets-locked {
    vertical-align: middle;
    width: 24px;
    height: 24px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    padding: 4px 6px 4px 10px;

    .b-session-state__action {
      display: flex;
    }

    .b-session-state__inner {
      max-height: 0;

      &._visible {
        max-height: 180px;
        margin-top: 5px;
      }
    }

    .b-session-state__description {
      width: 100%;

      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
    font-size: 1.4rem;
  }
`;
