import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { textTransform } from 'utils';

export const SignupWrapper = styled.div.attrs({
  className: 'header__loggedout'
})`
  display: flex;
  // margin-left: 10px;
  margin-left: 4px;
  align-items: center;
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    display: none;
  }
`;

export const RewardsLinkText = styled.div`
  & > span {
    display: block;
    font-size: 1.6rem;
    font-weight: 700;
    margin-top: 0px;
    text-transform: ${textTransform};
    color: ${({ theme }) => theme.color.bgColor};
  }
`;

export const RewardsLink = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 7px 15px;
  color: red;
  border-radius: 24px;
  transition: background-color 0.2s ease;
  backface-visibility: hidden;

  & > .svg-rewards {
    width: 24px;
    height: 24px;
    margin-top: 2px;
    fill: ${({ theme }) => theme.color.accent};
    & + div {
      margin-left: 7px;
    }
  }
`;

export const RewardsLinkWrapper = styled.div`
  &._active {
    pointer-events: none;

    ${RewardsLink} {
      background-color: ${({ theme }) => theme.color.textPrimary};
      & + div {
        display: none;
      }
    }

    ${RewardsLinkText} >  span:last-child {
      color: ${({ theme }) => theme.color.bgSecondary};
    }
  }

  &:hover ${RewardsLink} {
    background-color: ${({ theme }) => theme.color.textPrimary};

    ${RewardsLinkText} > span:last-child {
      color: ${({ theme }) => theme.color.bgSecondary};
    }

    & + div {
      opacity: 1;
      pointer-events: auto;

      & > h3 {
        transform: translateY(0);
        opacity: 1;
      }

      .l-benefits__item {
        & > .l-benefits__item-inner {
          animation: scaleAndBounce 0.45s ease-out both;
        }

        & > .l-benefits__item-text {
          opacity: 1;
        }
      }

      .l-benefits__item:nth-child(2) {
        & > .l-benefits__item-inner {
          animation-delay: 0.05s;
        }

        & > .l-benefits__item-text {
          transition-delay: 0.05s;
        }
      }

      .l-benefits__item:nth-child(3) {
        & > .l-benefits__item-inner {
          animation-delay: 0.1s;
        }

        & > .l-benefits__item-text {
          transition-delay: 0.1s;
        }
      }

      .l-benefits__item:nth-child(4) {
        & > .l-benefits__item-inner {
          animation-delay: 0.15s;
        }

        & > .l-benefits__item-text {
          transition-delay: 0.15s;
        }
      }
    }
  }
`;

export const RewardsPopupWrapper = styled.div`
  width: calc(100% - 32px);
  padding: 24px 40px 77px;
  position: absolute;
  left: ${({ theme }) => theme.indent.m};
  top: calc(100% + 2px);
  font-size: 1.6rem;
  box-shadow: 0 0px 7px 0px rgba(25, 25, 25, 0.6);
  background-color: ${({ theme }) => theme.color.bgPopup};
  border: 1px solid ${({ theme }) => theme.color.borderPopup};
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-out;

  &::before {
    content: '';
    width: 100%;
    height: 20px;
    position: absolute;
    top: -15px;
    left: 0;
  }

  &:hover {
    opacity: 1;
    pointer-events: auto;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    display: none;
  }
`;

export const RewardsPopupTitle = styled.h3`
  margin-bottom: ${({ theme }) => theme.indent.l};
  font-size: 2rem;
  letter-spacing: 1.9px;
  text-align: center;
  color: ${({ theme }) => theme.color.accent};
  text-transform: ${textTransform};
  text-align: center;
  transition: transform 0.35s ease-out, opacity 0.4s ease-in;
  transform: translateY(-10px);
  opacity: 0;
`;

export const RewardsPopupInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RewardsPopupAdvantages = styled.ul.attrs({
  className: 'l-benefits'
})`
  margin: 0 -${({ theme }) => theme.indent.m};

  & > .l-benefits__item {
    vertical-align: top;
    max-width: calc(192px + 32px);
    padding: 0 ${({ theme }) => theme.indent.m};
    text-align: center;
  }

  .l-benefits__item-inner {
    display: inline-flex;
    width: 72px;
    height: 72px;
    background-color: #de1619;
    border-radius: 50%;
    opacity: 0;

    & > svg {
      margin: auto;
      width: 40px;
      height: 40px
      fill: #fff;
    }
  }

  .l-benefits__item-text {
    margin-top: ${({ theme }) => theme.indent.m};
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: ${textTransform};
    letter-spacing: .1px;
    transition: opacity .5s ease-in-out;
    opacity: 0;
  }
`;

export const RewardsPopupLink = styled.div`
  width: 200px;

  & > .svg-logo {
    width: 100%;
    height: 87px;

  & > a {
    width: 100%;
    text-align: center;
    margin-top: ${({ theme }) => theme.indent.l};
    padding: 1.19em 1.15em;
  }
`;
