import layoutModule from 'app/store/layout';

let counter = 0;
const { LOADING } = layoutModule.types;

// eslint-disable-next-line
const loader = store => next => action => {
  const { type, payload } = action;

  if (type === LOADING) {
    counter += payload.val ? 1 : -1;
    payload.val = !!counter;
  }

  return next(action);
};

export default loader;
