import { catchError, mergeMap, concatMap, startWith } from 'rxjs/operators';
import { from } from 'rxjs';
import { ofType } from 'redux-observable';

import { setLoader, handleErrors, setError } from './deps';
import { NEWSLETTER_REGISTRATION_REQUEST, LIGHT_MEMBER_REGISTRATION_REQUEST } from './types';
import { sendNewsletterRegistrationSuccess, lightMemberRegistrationSuccess } from './actions';

export const saveNewsletterRegistration = ($action, $state, { api }) => {
  const $apiNewsletter = api.getModuleByName('newsletter');

  return $action.pipe(
    ofType(NEWSLETTER_REGISTRATION_REQUEST),
    mergeMap(action => {
      const {
        payload: { body }
      } = action;

      return from($apiNewsletter.registration(body)).pipe(
        concatMap(res => [sendNewsletterRegistrationSuccess(res), setLoader(false)]),

        ...handleErrors(action),

        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      );
    })
  );
};

export const lightMemberRegistration = ($action, $state, { api }) => {
  const $apiNewsletter = api.getModuleByName('bonuscard');

  return $action.pipe(
    ofType(LIGHT_MEMBER_REGISTRATION_REQUEST),
    mergeMap(action => {
      const {
        payload: { body }
      } = action;

      return from($apiNewsletter.lightMemberRegister(body)).pipe(
        concatMap(res => [lightMemberRegistrationSuccess(res), setLoader(false)]),

        ...handleErrors(action),

        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      );
    })
  );
};

