export const BUY_REQUEST = 'payment/BUY_REQUEST';
export const BUY_SUCCESS = 'payment/BUY_SUCCESS';

export const RESERVE_REQUEST = 'payment/RESERVE_REQUEST';

export const PURCHASE_REQUEST = 'payment/PURCHASE_REQUEST';
export const PURCHASE_SUCCESS = 'payment/PURCHASE_SUCCESS';

export const PURCHASE_STATUS_REQUEST = 'payment/PURCHASE_STATUS_REQUEST';

export const PAYMENT_STATUS_REQUEST = 'payment/PAYMENT_STATUS_REQUEST';
export const SET_PAYMENT_STATUS = 'payment/SET_PAYMENT_STATUS';

export const BUY_WITH_BONUS_REQUEST = 'payment/BUY_WITH_BONUS_REQUEST';
export const BUY_WITH_BONUS_SUCCESS = 'payment/BUY_WITH_BONUS_SUCCESS';

export const BUY_WITH_GIFT_REQUEST = 'payment/BUY_WITH_GIFT_REQUEST';
export const BUY_WITH_GIFT_SUCCESS = 'payment/BUY_WITH_GIFT_SUCCESS';

export const APPLY_GIFTCARD_REQUEST = 'payment/APPLY_GIFTCARD_REQUEST';
export const APPLY_GIFTCARD_SUCCESS = 'payment/APPLY_GIFTCARD_SUCCESS';

export const TICKET_SESSION_CLEAR = 'payment/TICKET_SESSION_CLEAR';

export const PAYMENT_CLEAR = 'payment/PAYMENT_CLEAR';

export const STEP_CHECKOUT_REQUEST = 'payment/STEP_CHECKOUT_REQUEST';
export const STEP_CHECKOUT_SUCCESS = 'payment/STEP_CHECKOUT_SUCCESS';
