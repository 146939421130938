import {
  NEWSLETTER_REGISTRATION_REQUEST,
  NEWSLETTER_REGISTRATION_SUCCESS,
  LIGHT_MEMBER_REGISTRATION_REQUEST,
  LIGHT_MEMBER_REGISTRATION_SUCCESS
} from './types';

export const sendNewsletterRegistrationRequest = body => ({
  type: NEWSLETTER_REGISTRATION_REQUEST,
  payload: { body }
});

export const sendNewsletterRegistrationSuccess = newsletterRegistration => ({
  type: NEWSLETTER_REGISTRATION_SUCCESS,
  payload: { newsletterRegistration }
});

export const fetchLightMemberSend = body => (
  {
  type: LIGHT_MEMBER_REGISTRATION_REQUEST,
  payload: { body }
});

export const lightMemberRegistrationSuccess = lightMember => ({
  type: LIGHT_MEMBER_REGISTRATION_SUCCESS,
  payload: { lightMember }
});
