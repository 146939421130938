import reducer from './reducer';
import * as actions from './actions';
import * as types from './types';
import * as epics from './epics';

const NAME = 'order';

export default {
  NAME,
  reducer,
  actions,
  types,
  epics
};
