import * as deepmerge from 'deepmerge';
import common from './common';

const contryCurrent = {
  'bih': 'KM',
  'rou': 'LEI',
  'aut': '€',
  'srb': 'RSD',
  'alb': 'ALL',
  'bil': 'KM',
  'grc': '€',
  'hrv': '€',
  'mkd': 'ДЕН',
  'mne': '€',
  'rks': '€',
  'svn': '€',
}

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const apiIalEndpoint = process.env.REACT_APP_TOKEN_IAL;
// const giftCardShop = process.env.REACT_APP_GIFTCARDSHOP;
const giftCardShopEN = process.env.REACT_APP_GIFTCARDSHOP_EN;
const giftCardShopDE = process.env.REACT_APP_GIFTCARDSHOP_DE;
const memberLevelId = process.env.REACT_APP_MEMBER_LEVEL_ID;
const clubID = process.env.REACT_APP_CLUBID
const cmsApiEndpoint = process.env.REACT_APP_CMS_API_ENDPOINT;
const country = process.env.REACT_APP_COUNTRY;
const googleAnalyticKey = process.env.REACT_APP_GOOGLE_ANALYTIC_KEY;
const googleMapKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
const env = process.env.REACT_APP_ENV;
const gtmId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;
const streamingUrl = process.env.REACT_APP_STREAMING_URL;
const vodNotLoggedInUrlEN = process.env.REACT_APP_VOD_NOTLOGGEDINURL_EN;
const vodNotLoggedInUrlDE = process.env.REACT_APP_VOD_NOTLOGGEDINURL_DE;
const domainGroupId = process.env.REACT_APP_DOMAIN_GROUP_ID;
const cineplexUrl = process.env.REACT_APP_CINEPLEX_URL;
const sitekey = process.env.REACT_APP_SITE_KEY;
const secretKey = process.env.REACT_APP_SECRET_KEY;
const surveyURL = process.env.REACT_APP_SURVEY_URL;
const currencyForCountry = contryCurrent[country]
// eslint-disable-next-line
const required = require(`./countries/${country}`).default;
const commonConfig = common(apiEndpoint, apiIalEndpoint, cmsApiEndpoint, googleAnalyticKey, googleMapKey, gtmId,
  // giftCardShop, 
  currencyForCountry,
  giftCardShopEN, giftCardShopDE, streamingUrl, vodNotLoggedInUrlEN, vodNotLoggedInUrlDE, memberLevelId, clubID, domainGroupId, cineplexUrl, sitekey, secretKey, surveyURL);
const requiredConfig = required();
const arrayMerge = (destinationArray, sourceArray) => sourceArray;

const config = deepmerge(commonConfig, requiredConfig, { arrayMerge });

if (env === 'development') {
  config.intl = commonConfig.intl;
}

export default config;
