export const INFORMATION_LEGAL_REQUEST = 'information/LEGAL_REQUEST';
export const INFORMATION_LEGAL_SET = 'information/LEGAL_SET';

export const INFORMATION_IMPRESSUM_REQUEST = 'information/IMPRESSUM_REQUEST';
export const INFORMATION_IMPRESSUM_SET = 'information/IMPRESSUM_SET';

export const INFORMATION_B2B_REQUEST = 'information/B2B_REQUEST';
export const INFORMATION_B2B_SET = 'information/B2B_SET';

export const INFORMATION_PRICELIST_REQUEST = 'information/PRICELIST_REQUEST';
export const INFORMATION_PRICELIST_SET = 'information/INFORMATION_PRICELIST_SET';

export const INFORMATION_BONUSCARD_REQUEST = 'information/INFORMATION_BONUSCARD_REQUEST';
export const INFORMATION_BONUSCARD_SET = 'information/INFORMATION_BONUSCARD_SET';

export const INFORMATION_FAMILYFILMCLUB_REQUEST = 'information/INFORMATION_FAMILYFILMCLUB_REQUEST';
export const INFORMATION_FAMILYFILMCLUB_SET = 'information/INFORMATION_FAMILYFILMCLUB_SET';

export const INFORMATION_BIRTHDAYROOM_REQUEST = 'information/INFORMATION_BIRTHDAYROOM_REQUEST';
export const INFORMATION_BIRTHDAYROOM_SET = 'information/INFORMATION_BIRTHDAYROOM_SET';

export const INFORMATION_COMPETITIONS_REQUEST = 'information/INFORMATION_COMPETITIONS_REQUEST';
export const INFORMATION_COMPETITIONS_SET = 'information/INFORMATION_COMPETITIONS_SET';

export const SLIDER_CONTENT_REQUEST = 'information/SLIDER_CONTENT_REQUEST';
export const SLIDER_CONTENT_SUCCESS = 'information/SLIDER_CONTENT_SUCCESS';

export const SEND_QUESTION_FORM_DATA = 'information/SEND_QUESTION_FORM_DATA';
export const SUCCESS_QUESTION_FORM_DATA = 'information/SUCCESS_QUESTION_FORM_DATA';

export const REQUEST_COMPETITION_DETAIL = 'information/REQUEST_COMPETITION_DETAIL';
export const REQUEST_COMPETITION_DETAIL_SUCCESS = 'information/REQUEST_COMPETITION_DETAIL_SUCCESS';

export const COMPETITION_EVENTS_REQUEST = 'information/COMPETITION_EVENTS_REQUEST';
export const COMPETITION_EVENTS_SUCCESS = 'information/COMPETITION_EVENTS_SUCCESS';

export const INFORMATION_FAQ_REQUEST = 'information/INFORMATION_FAQ_REQUEST';
export const INFORMATION_FAQ_SUCCESS = 'information/INFORMATION_FAQ_SUCCESS';

export const LANDING_INFO_REQUEST = 'information/LANDING_INFO_REQUEST';
export const LANDING_INFO_SUCCESS = 'information/LANDING_INFO_SUCCESS';

export const HALLTICKET_BOOK_REQUEST = 'information/HALLTICKET_BOOK_REQUEST';
export const HALLTICKET_BOOK_SUCCESS = 'information/HALLTICKET_BOOK_SUCCESS';

export const INFORMATION_PRICELIST_GASTRONOMY_REQUEST = 'information/INFORMATION_PRICELIST_GASTRONOMY_REQUEST';
export const INFORMATION_PRICELIST_GASTRONOMY_SET = 'information/INFORMATION_PRICELIST_GASTRONOMY_SET';

export const FAQS_REGISTRATION_REQUEST = 'information/FAQS_REGISTRATION_REQUEST';
export const FAQS_REGISTRATION_SUCCESS = 'information/FAQS_REGISTRATION_SUCCESS';

export const FOOTER_NEWSLETTER_REGISTRATION_REQUEST = 'information/FOOTER_NEWSLETTER_REGISTRATION_REQUEST';
export const FOOTER_NEWSLETTER_REGISTRATION_SUCCESS = 'information/FOOTER_NEWSLETTER_REGISTRATION_SUCCESS';

export const GET_FFCSIGNUP_NOTE_REQUEST = 'information/GET_FFCSIGNUP_NOTE_REQUEST';
export const GET_FFCSIGNUP_NOTE_SUCCESS = 'information/GET_FFCSIGNUP_NOTE_SUCCESS';

export const GET_MAIN_URL_REQUEST = 'information/GET_MAIN_URL_REQUEST';
export const GET_MAIN_URL_SUCCESS = 'information/GET_MAIN_URL_SUCCESS';