import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Svg } from '../..';

const Checkbox = props => {
  const StyledInput = styled.input.attrs({
    type: 'checkbox'
  })`
    & + .f-default__checkbox-btn {
      display: inline-flex;
      vertical-align: middle;
      width: 20px;
      height: 20px;
      border: solid 2px ${({ theme }) => theme.color.borderLight};
      border-radius: 50%;
      position: relative;
      transition: border-color 0.2s ease-out, background-color 0.2s ease-out;
      margin-right: 10px;
      top: -2px;
      &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        transform: scale(0.8);
        transition: transform 0.2s ease-out, opacity 0.2s ease-in;
        pointer-events: none;
      }
      & > svg {
        width: 12px;
        height: 12px;
        margin: auto;
        opacity: 0;
        fill: ${({ theme }) => theme.color.textPrimary};
        transition: opacity 0.2s ease-out;
      }
    }

    &[required] + .f-default__checkbox-btn {
      border-color: ${({ theme }) => theme.color.accent};
    }

    &:checked + .f-default__checkbox-btn {
      border-color: ${({ theme }) => theme.color.accent};
      background-color: ${({ theme }) => theme.color.accent};
      & > svg {
        opacity: 1;
      }
      &::before {
        transform: scale(0.65);
      }
      &::after {
        background-color: ${({ theme }) => theme.color.accent};
        transform: scale(2.5);
        opacity: 0;
      }
    }
  `;

  return (
    <Fragment>
      <StyledInput {...props} />
      <span className="f-default__checkbox-btn">
        <Svg id="checkmark" />
      </span>
    </Fragment>
  );
};

export default Checkbox;
