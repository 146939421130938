import { SET_PLAN, CLEAR_PLAN, SET_LEGEND, CLEAR_LEGEND } from './types';

const initialState = {
  plan: null,
  legend: null
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case SET_PLAN: {
      const { plan } = payload;
      const { rows, icons } = plan;
      const newStatePlan = icons?.length
        ? plan
        : {
          ...state.plan,
          rows
        };

      return {
        ...state,
        plan: newStatePlan
      };
    }

    case CLEAR_PLAN: {
      return {
        ...state,
        plan: null
      };
    }

    case SET_LEGEND: {
      return {
        ...state,
        legend: payload.legend
      };
    }

    case CLEAR_LEGEND: {
      return {
        ...state,
        legend: null
      };
    }

    default: {
      return state;
    }
  }
}
