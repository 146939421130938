import BaseAPI from '../BaseAPI';

/**
 * Api class for handling watchlist
 */
class Watchlist extends BaseAPI {
  /**
   * Creates User instance
   *
   * @param props {Object}
   * @return {User}
   */
  constructor(props) {
    super(props);

    this.baseUrl = '/users/watchlist';
  }

  /**
   * Fetch the watch list
   * @returns {Promise<*|Error>}
   */
  fetchWatchlist() {
    const url = `${this.baseUrl}`;

    return this.get({ url });
  }

  /**
   * Add a movie to watchlist
   * @param movieId {string}
   * @returns {Promise<*|Error>}
   */
  addMovieToWatchlist(movieId) {
    const url = `${this.baseUrl}/movie/${movieId}`;

    return this.post({ url });
  }

  /**
   * Remove a movie from watchlist
   * @param movieId {string}
   * @returns {Promise<*|Error>}
   */
  removeMovieFromWatchlist(movieId) {
    const url = `${this.baseUrl}/movie`;
    const body = [movieId];

    return this.remove({ url, body });
  }

  /**
   * Add an event to watchlist
   * @param eventId {string}
   * @returns {Promise<*|Error>}
   */
  addEventToWatchlist(eventId) {
    const url = `${this.baseUrl}/event/${eventId}`;

    return this.post({ url });
  }

  /**
   * Remove an event from watchlist
   * @param eventId {string}
   * @returns {Promise<*|Error>}
   */
  removeEventFromWatchlist(eventId) {
    const url = `${this.baseUrl}/event`;
    const body = [eventId];

    return this.remove({ url, body });
  }
}

export default Watchlist;
