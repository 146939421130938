import styled from 'styled-components';
import { TitleAccent, ShadowBox } from 'app/components';

export const Page = styled.div`
    max-width: 648px;
    margin-top: ${({ theme }) => theme.indent.l};
    margin-bottom: ${({ theme }) => theme.indent.xxl};
    margin-left: auto;
    margin-right: auto;

    .error {
        margin: 0 0 ${({ theme }) => theme.indent.m} 0;
    }
    
    ${TitleAccent} {
        margin-bottom: ${({ theme }) => theme.indent.m};
    }
`;

export const RefundTicketsForm = styled(ShadowBox).attrs({
    className: 'f-refund-tickets'
})`
    padding: 24px;
    height: 338px;
    background-color: rgba(25, 25, 25, 0.9);

    .f-refund-tickets__form-wrap {
        display: flex;
        justify-content: center;

        .f-refund-tickets__content{
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .email {
                margin-top: 16px;
            }
        }

        [type="submit"] {
            margin-top: 44px;
        }
    }

  
    @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
      padding: ${({ theme }) => `${theme.indent.m} ${theme.indent.s}`};
    }
`;

export default Page;