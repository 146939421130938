import {
  REQUEST_ALL_EVENTS,
  REQUEST_EVENT_DETAILS,
  SET_ALL_EVENTS,
  SET_EVENT_DETAILS,
  SET_EVENTS_DATE,
  RESET_ALL_EVENTS,
  RESET_EVENT_DETAILS
} from './types';

export const requestAllEvents = params => ({
  type: REQUEST_ALL_EVENTS,
  payload: { params }
});
export const requestEventDetails = eventId => ({
  type: REQUEST_EVENT_DETAILS,
  payload: { eventId }
});

export const setAllEvents = ({ allEvents, monthsList }) => ({
  type: SET_ALL_EVENTS,
  payload: {
    allEvents,
    monthsList
  }
});
export const setEventDetails = ({ eventDetails, movies }) => ({
  type: SET_EVENT_DETAILS,
  payload: {
    eventDetails,
    movies
  }
});
export const setDate = date => ({
  type: SET_EVENTS_DATE,
  payload: {
    date
  }
});

export const resetAllEvents = () => ({
  type: RESET_ALL_EVENTS
});
export const resetEventDetails = () => ({
  type: RESET_EVENT_DETAILS
});
