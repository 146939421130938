import styled from 'styled-components';

export const Wrapper = styled.div.attrs({
  className: 'b-carousel__wrapper'
})`
  position: relative;
  // top:15px;
  

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.xs.width}) {
    width: 100%;
  }
  // @media screen and (max-width: 992px){
  //   padding-top: 15px;
  //   top:0px;
  // }
  @media screen and (max-width:${({ theme }) => theme.breakPoints.sm.width}){
    padding-top:0px
  }
`;

export const ButtonsRow = styled.div`
  position: absolute;

  left: ${({ theme }) => theme.indent.s};
  right: ${({ theme }) => theme.indent.s};
  ${({ withCaption }) => withCaption && 'top: 46%'}
  @media screen and (max-width: 568px){
    left:2px;
    right:2px;
    top:34%;
  }
`;

export const Arrow = styled.button`
  width: ${({ theme }) => theme.indent.xxl};
  height: ${({ theme }) => theme.indent.xxl};
  position: absolute;
  top: 50%;
  z-index: 2;
  border-radius: 50%;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
  background-color: rgba(25, 25, 25, 0.9);
  transition: background-color 0.2s ease-out;
  transform: translateY(-50%);

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${({ theme }) => theme.indent.l};
    height: ${({ theme }) => theme.indent.l};
     margin: -12px 0 0 -12px;
  }

  &:first-child {
    left: 0;

    &::after {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4gICAgPHBhdGggZmlsbD0iI0ZGRiIgZmlsbC1vcGFjaXR5PSIuOCIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJNMTUuNDEgNy40MUwxMC44MyAxMmw0LjU4IDQuNTlMMTQgMThsLTYtNiA2LTZ6Ii8+PC9zdmc+);
    }
  }

  &:last-child {
    right: 0;

    &::after {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4gICAgPHBhdGggZmlsbD0iI0ZGRiIgZmlsbC1vcGFjaXR5PSIuOCIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJNOCA3LjQxTDkuNDEgNmw2IDYtNiA2TDggMTYuNTkgMTIuNTggMTJ6Ii8+PC9zdmc+);
    }
  }

  ${({ disabled }) =>
    disabled &&
    `
    opacity: .5;
  `}
  
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.xs.width}) {
    width: 32px;
    height: 32px;
  }
`;
