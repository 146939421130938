import { mergeMap, startWith, concatMap, catchError } from 'rxjs/operators';
import { from } from 'rxjs';
import { ofType } from 'redux-observable';
import { setLoader, handleErrors, setError } from './deps';

import {
  CINEMA_ADVERTISING_REQUEST,
  ONLINE_ADVERTISING_REQUEST,
  PRESS_INFO_REQUEST
} from './types';

import { cinemaAdvertisingSuccess, onlineAdvertisingSuccess, pressInfoSuccess } from './actions';

export const fetchCinemaAdvertising = ($action, $state, { api }) => {
  const $apiAdvertising = api.getModuleByName('advertising');

  return $action.pipe(
    ofType(CINEMA_ADVERTISING_REQUEST),
    mergeMap(action =>
      from($apiAdvertising.fetchCinemaAll()).pipe(
        concatMap(res => [cinemaAdvertisingSuccess(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      )
    )
  );
};

export const fetchOnlineAdvertising = ($action, $state, { api }) => {
  const $apiAdvertising = api.getModuleByName('advertising');

  return $action.pipe(
    ofType(ONLINE_ADVERTISING_REQUEST),
    mergeMap(action =>
      from($apiAdvertising.fetchOnlineAll()).pipe(
        concatMap(res => [onlineAdvertisingSuccess(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      )
    )
  );
};

export const fetchPressInfo = ($action, $state, { api }) => {
  const $apiAdvertising = api.getModuleByName('advertising');

  return $action.pipe(
    ofType(PRESS_INFO_REQUEST),
    mergeMap(action =>
      from($apiAdvertising.fetchPressInfoAll()).pipe(
        concatMap(res => [pressInfoSuccess(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      )
    )
  );
};
