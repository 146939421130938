import {
  REWARDS_SUCCESS,
  BALANCE_SUCCESS,
  CONCESSIONS_SUCCESS,
  ORDER_CONCESSION_SUCCESS,
  BONUS_CARD_INFO_SUCCESS
} from './types';

const initialState = {
  rewards: [],
  balance: {},
  concessions: [],
  orderedConcession: {},
  bonusCardInfo: []
};

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case REWARDS_SUCCESS: {
      return {
        ...state,
        rewards: payload.rewards
      };
    }

    case BALANCE_SUCCESS: {
      return {
        ...state,
        balance: payload.balance
      };
    }

    case CONCESSIONS_SUCCESS: {
      return {
        ...state,
        concessions: payload.concessions
      };
    }

    case ORDER_CONCESSION_SUCCESS: {
      return {
        ...state,
        orderedConcession: payload.orderedConcession
      };
    }

    case BONUS_CARD_INFO_SUCCESS: {
      return {
        ...state,
        bonusCardInfo: payload.bonusCardInfo?.data
      };
    }

    default:
      return state;
  }
}
