import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const PreviousRouteContext = createContext();

export const usePreviousRoute = () => useContext(PreviousRouteContext);

export const PreviousRouteProvider = ({ children }) => {
  const history = useHistory();
  const [previousRoute, setPreviousRoute] = useState(null);

  useEffect(() => {
    return history.listen((location) => {
      setPreviousRoute(location.pathname);
    });
  }, [history]);

  return (
    <PreviousRouteContext.Provider value={previousRoute}>
      {children}
    </PreviousRouteContext.Provider>
  );
};
