import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ThemeProvider } from '@mui/material/styles';
// import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
// import DateFnsUtils from '@date-io/date-fns';
// import { MuiThemeProvider } from '@material-ui/core';
import useUtils from 'app/hooks/useUtils';
import enLocale from "date-fns/locale/en-US";
import deLocale from "date-fns/locale/de"
import ruLocale from "date-fns/locale/ru";
import hrLocale from "date-fns/locale/hr";
// import { IconButton} from "@material-ui/core"
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import theme from './theme';

const propTypes = {
  value: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
};

const defaultProps = {
  maxDate: null,
  minDate: null,
};

const localeMap = {
  en: enLocale,
  de: deLocale,
  ru: ruLocale,
  hr: hrLocale,
};

const Calendar = ({ value, onChange, onClose, isOpen, maxDate, minDate }) => {
  const [t, i18n] = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [locale, setLocale] = useState(i18n.language);
  const [anchorEl, setAnchorEl] = useState(null);
  const { getDateWithOffset } = useUtils;


  const handleMenuOpen = useCallback(e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }, []);

  const customButtonLocaleText = {
    okButtonLabel: "Ok",
    cancelButtonLabel: t('common.cancel')
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[locale]} localeText={customButtonLocaleText}>
      <ThemeProvider theme={theme}>
        <MobileDatePicker
          views={['year', 'month', 'day']}
          openTo="year"
          value={value}
          onChange={onChange}
          onClose={onClose}
          // ref={picker => picker && isOpen && picker.open()}
          open={isOpen}
          minDate={minDate}
          maxDate={maxDate || getDateWithOffset()}
          // cancelLabel={t('common.cancel')}
          InputProps={{
            endAdornment: (
              <IconButton
                aria-label="Select locale"
                onClick={handleMenuOpen}
                aria-owns={anchorEl ? "locale-menu" : undefined}
              >
              </IconButton>
            ),
          }}
        />
      </ThemeProvider>
    </LocalizationProvider>
  );
};

Calendar.defaultProps = defaultProps;
Calendar.propTypes = propTypes;
export default Calendar;
