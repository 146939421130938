import * as actions from './actions';
import * as types from './types';
import * as epics from './epics';

const NAME = 'auth';

export default {
  NAME,
  actions,
  types,
  epics
};
