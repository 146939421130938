import BaseAPI from '../BaseAPI';

class NewsLetter extends BaseAPI {
  constructor(props) {
    super(props);

    this.baseUrl = '/b2bnewsletter/request';
  }

  /**
   * sends the cinema rent inquiry
   * @body payload data
   * @returns {Promise<*|Error>}
   */
  registration(body) {
    const url = this.baseUrl;

    return this.post({ url, body });
  }
}

export default NewsLetter;
