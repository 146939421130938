import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { textTransform } from 'utils';

export const SignInLink = styled(NavLink)`
  font-size: 1.6rem;
  font-weight: 700;
  margin-top: 0px;
  color: ${({ theme }) => theme.color.textSecondary};
  text-transform: ${textTransform};
  transition: color 0.2s ease-out;
  /* margin-right: 12px; */

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
   .login-name{
    display: none;
   }
  }

  & > .svg-user {
    vertical-align: middle;
    width: 24px;
    margin-top: 2px;
    height: 24px;
    /* margin-right: ${({ theme }) => theme.indent.s}; */
    margin-right: 3px;
    fill: ${({ theme }) => theme.color.rgbaWhite('.8')};
    transition: fill 0.2s ease-out;
  }
  & > span {
    vertical-align: middle;
  }

  &:hover,
  &._active {
    color: ${({ theme }) => theme.color.textPrimary};
    & > .svg-user {
      fill: ${({ theme }) => theme.color.rgbaWhite('1')};
    }
  }

  &._active {
    pointer-events: none;
  }
`;

export default SignInLink;
