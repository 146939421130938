import styled from 'styled-components';

const Label = styled.label`
  display: block;
  margin-bottom: 4px;
  padding-left: 16px;
  font-weight: 500;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.color.labelColor};
`;

export default Label;
