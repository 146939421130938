export default {
  required: () => ['form.errors.required'],
  minLength: ({ min }) => ['form.errors.minLength', { min }],
  maxLength: ({ max }) => ['form.errors.maxLength', { max }],
  email: () => ['form.errors.email'],
  equal: ({ field: { message } }) => ['form.errors.equal', { field: message }],
  requiredArray: () => ['form.errors.requiredArray'],
  requiredDate: () => ['form.errors.requiredDate'],
  password: () => ['form.errors.password', { backslash: '\\' }],
  numeric: () => ['form.errors.numeric'],
  zip: ({ amount }) => ['form.errors.zip', { amount }]
};
