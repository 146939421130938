import BaseAPI from '../BaseAPI';

class Competitions extends BaseAPI {
  constructor(props) {
    super(props);
    this.baseUrl = `/list?country=${process.env.REACT_APP_COUNTRY?.toUpperCase()}`;
  }

  /**
   * Gets a list of competitions
   *
   * @returns {Promise<*|Error>}
   */
  fetchAll() {
    const url = `/competitions${this.baseUrl}`;

    return this.get({ url });
  }

  /**
   * Gets a list of main events
   *
   * @returns {Promise<*|Error>}
   */
  fetchMainAll() {
    const url = `/competition-main-site${this.baseUrl}`;

    return this.get({ url });
  }

  fetchGiftCardContent() {
    const url = `/gift-card-info${this.baseUrl}`;

    return this.get({ url });
  }
}

export default Competitions;
