import UrlPattern from 'url-pattern';

const getFilledFields = data => {
  const optional = ['phone', 'city', 'address', 'zip'];
  const filled = [];

  for (let i = 0; i < optional.length; i += 1) {
    if (data[optional[i]]) {
      filled.push(optional[i]);
    }
  }

  return filled;
};

class AnalyticsReporter {
  constructor(routes, ga) {
    this.routes = Object.values(routes);
    this.ga = ga;

    this.sendPage = this.sendPage.bind(this);
  }

  findRoute(path) {
    return this.routes.find(route => new UrlPattern(route.pattern).match(path));
  }

  sendEvent(name, params) {
    this.ga.sendEvent(name, params);
  }

  sendPage(location) {
    const { pathname } = location;
    const route = this.findRoute(pathname);

    if (route && route.tracked) {
      const { name } = route;

      window.addEventListener('CookiebotOnAccept', function () {
        if(window?.Cookiebot?.consent?.statistics){
          console.log('analytics is sending!!!', location);
          localStorage.setItem('googleAnalytics', JSON.stringify(location));
        }
      }, false)
      window.addEventListener('CookiebotOnDecline', function () {
        if(window?.Cookiebot?.consent?.statistics){
          console.log('analytics is sending!!!', location);
          localStorage.setItem('googleAnalytics', JSON.stringify(location));
        }
      }, false)
      this.ga.sendPage(name, pathname);
    }
  }

  sendSearch(value) {
    this.sendEvent('search', { search_term: value });
  }

  sendRegistration(data) {
    const filled = getFilledFields(data);

    this.sendEvent('registration', {
      event_category: 'user',
      event_label: filled.join(', '),
      value: filled.length
    });
  }

  sendUserUpdate(data) {
    const filled = getFilledFields(data);

    this.sendEvent('update', {
      event_category: 'user',
      event_label: filled.join(', '),
      value: filled.length
    });
  }

  sendCinemaFavorite(value) {
    const action = value ? 'add_cinema' : 'remove_cinema';

    this.sendEvent(action, {
      event_category: 'favorite'
    });
  }

  sendMovieFavorite(value) {
    const action = value ? 'add_movie' : 'remove_movie';

    this.sendEvent(action, {
      event_category: 'favorite'
    });
  }

  sendTicketPurchase(amount, paymentType) {
    this.sendEvent('tickets', {
      event_category: 'purchase',
      event_label: paymentType,
      value: amount
    });
  }

  sendReservation(amount, paymentType) {
    this.sendEvent('reservation', {
      event_category: 'purchase',
      event_label: paymentType,
      value: amount
    });
  }

  sendRedemption(amount, paymentType) {
    this.sendEvent('redemption', {
      event_category: 'purchase',
      event_label: paymentType,
      value: amount
    });
  }

  sendCancelReservation(amount, paymentType) {
    this.sendEvent('reservation_cancel', {
      event_category: 'purchase',
      event_label: paymentType,
      value: amount
    });
  }

  sendPDF(type) {
    this.sendEvent(type, {
      event_category: 'pdf_generated'
    });
  }

  sendError(error, type) {
    this.sendEvent(type, {
      event_category: 'error',
      event_label: JSON.stringify({
        ...error,
        message: error.message,
        stack: error.stack
      })
    });
  }
}

export default AnalyticsReporter;
