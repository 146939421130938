import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CarouselVendor from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Arrow, Wrapper, ButtonsRow } from './styles';

const Arr = ({ disabled, onClick }) => (
  <Arrow disabled={disabled} onClick={onClick}>
    <span className="_with-chevron" />
  </Arrow>
);

Arr.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

const ButtonGroup = ({ next, previous, withCaption, ...rest }) => {
  const {
    carouselState: { currentSlide, totalItems, slidesToShow }
  } = rest;

  return totalItems > slidesToShow ? (
    <ButtonsRow className="buttons-row" withCaption={withCaption}>
      <Arr disabled={currentSlide === 0 || currentSlide === null} onClick={previous} />
      <Arr disabled={currentSlide === totalItems - slidesToShow} onClick={next} />
    </ButtonsRow>
  ) : null;
};

ButtonGroup.propTypes = {
  next: PropTypes.func,
  previous: PropTypes.func,

  carouselState: PropTypes.shape({
    currentSlide: PropTypes.number,
    totalItems: PropTypes.number,
    slidesToShow: PropTypes.number,
    deviceType: PropTypes.oneOf(['desktop', 'tablet', 'mobile'])
  }),
  withCaption: PropTypes.bool
};

ButtonGroup.defaultProps = {
  next: null,
  previous: null,

  carouselState: {},
  withCaption: false
};

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,

  slidesToSlide: PropTypes.number.isRequired,
  responsive: PropTypes.shape({
    desktop: PropTypes.object,
    tablet: PropTypes.object,
    mobile: PropTypes.object
  }).isRequired,

  initialSlideNumber: PropTypes.number,

  refEl: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(Element)
    }),
    PropTypes.object
  ]),
  afterChange: PropTypes.func,
  autoPlay: PropTypes.bool,
  autoPlaySpeed: PropTypes.number,
  infinite: PropTypes.bool,
  className: PropTypes.string,
  showDots: PropTypes.bool,
  ssr: PropTypes.bool,
  partialVisible: PropTypes.bool,
  customDot: PropTypes.node,
  customSlide: PropTypes.node,
  withCaption: PropTypes.bool
};

const defaultProps = {
  refEl: null,
  afterChange: null,
  initialSlideNumber: null,
  autoPlay: false,
  autoPlaySpeed: 1000,
  infinite: false,
  className: null,
  showDots: false,
  ssr: false,
  partialVisible: false,
  customDot: null,
  customSlide: null,
  withCaption: false
};

/**
 * Hack is used for setting correct width of the carousel items
 */
const fireResize = () => {
  const resizeEvent = window.document.createEvent('UIEvents');

  resizeEvent.initUIEvent('resize', true, false, window, 0);
  setTimeout(() => window.dispatchEvent(resizeEvent), 0);
};

const Carousel = ({
  children,
  slidesToSlide,
  responsive,
  afterChange,
  refEl,
  initialSlideNumber,
  autoPlay,
  autoPlaySpeed,
  infinite,
  className,
  showDots,
  ssr,
  partialVisible,
  customDot,
  customSlide,
  withCaption
}) => {
  useEffect(fireResize, []);

  return (
    <Wrapper>
      <CarouselVendor
        ref={refEl}
        containerClass={classNames('carousel', { [className]: !!className })}
        arrows={false}
        additionalTransfrom={0}
        centerMode={false}
        draggable
        focusOnSelect={false}
        infinite={infinite}
        keyBoardControl
        minimumTouchDrag={80}
        responsive={responsive}
        showDots={showDots}
        slidesToSlide={slidesToSlide}
        swipeable
        customButtonGroup={<ButtonGroup withCaption={withCaption} />}
        afterChange={afterChange}
        initialSlideNumber={initialSlideNumber}
        autoPlay={autoPlay}
        autoPlaySpeed={autoPlaySpeed}
        ssr={ssr}
        partialVisible={partialVisible}
        customDot={customDot}
        customSlide={customSlide}
      >
        {children}
      </CarouselVendor>
    </Wrapper>
  );
};

Carousel.fireResize = fireResize;
Carousel.defaultProps = defaultProps;
Carousel.propTypes = propTypes;
export default Carousel;
