import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { Svg, Error } from 'app/components';
import { InputField, InputRow } from './styles';

const propTypes = {
  type: PropTypes.string,
  isClearable: PropTypes.bool,
  isCalendar: PropTypes.bool,
  clearAction: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

const defaultProps = {
  type: 'text',
  isClearable: false,
  isCalendar: false,
  clearAction: null,
  error: false
};

const Input = props => {
  const { error, type, isClearable, isCalendar, clearAction } = props;
  const inputRef = useRef(null);

  const onTogglePass = () => {
    inputRef.current.type = inputRef.current.type === 'password' ? 'text' : 'password';
  };

  const onClearInput = () => {
    inputRef.current.value = '';
    clearAction();
  };

  return (
    <Fragment>
      <InputRow error={error}>
        {type === 'search' && <Svg id="search" />}

        <InputField {...props} ref={inputRef} />

        {type === 'password' && (
          <button type="button" className="f-default-btn-toggle-pass" onClick={onTogglePass}>
            <Svg id="input-eye" />
          </button>
        )}

        {isClearable && inputRef.current && inputRef.current.value && (
          <button type="button" className="f-default__field-btn-clear" onClick={onClearInput}>
            <Svg id="input-clear" />
          </button>
        )}

        {isCalendar && <Svg id="calendar" />}
      </InputRow>

      {error && <Error>{error}</Error>}
    </Fragment>
  );
};

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;
export default Input;
