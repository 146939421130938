/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable array-callback-return  */
/* eslint-disable jsx-a11y/anchor-is-valid  */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import layout from 'app/store/layout';

const { setElementsVisibility } = layout.actions;

export default function({ header, footer }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setElementsVisibility({
        header,
        footer
      })
    );

    return () =>
      dispatch(
        setElementsVisibility({
          header: !header,
          footer: !footer
        })
      );
  }, []);
}
