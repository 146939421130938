import {
  BUY_REQUEST,
  BUY_SUCCESS,
  PURCHASE_REQUEST,
  PURCHASE_SUCCESS,
  RESERVE_REQUEST,
  PURCHASE_STATUS_REQUEST,
  PAYMENT_STATUS_REQUEST,
  SET_PAYMENT_STATUS,
  BUY_WITH_BONUS_REQUEST,
  BUY_WITH_BONUS_SUCCESS,
  APPLY_GIFTCARD_REQUEST,
  APPLY_GIFTCARD_SUCCESS,
  BUY_WITH_GIFT_REQUEST,
  BUY_WITH_GIFT_SUCCESS,
  TICKET_SESSION_CLEAR,
  PAYMENT_CLEAR,
  STEP_CHECKOUT_REQUEST,
  STEP_CHECKOUT_SUCCESS
} from './types';

export const buyRequest = (userSessionId, email, notifyForm) => ({
  type: BUY_REQUEST,
  payload: {
    userSessionId,
    email,
    notifyForm
  }
});
export const buySuccess = payment => ({
  type: BUY_SUCCESS,
  payload: { payment }
});

export const reserveRequest = userSessionId => ({
  type: RESERVE_REQUEST,
  payload: { userSessionId }
});

export const purchaseRequest = userSessionId => (
  {
    type: PURCHASE_REQUEST,
    payload: {
      userSessionId
    }
  });
export const purchaseSuccess = booking => (
  {
    type: PURCHASE_SUCCESS,
    payload: {
      booking
    }
  });

export const purchaseStatusRequest = userSessionId => (
  {
    type: PURCHASE_STATUS_REQUEST,
    payload: {
      userSessionId
    }
  });

/**
 *  Payment Status Actions
 */
export const paymentStatusRequest = userSessionId => (
  {
    type: PAYMENT_STATUS_REQUEST,
    payload: {
      userSessionId
    }
  });
export const setPaymentStatus = status => ({
  type: SET_PAYMENT_STATUS,
  payload: {
    status
  }
});

export const buyWithBonusRequest = (userSessionId, notifyForm) => ({
  type: BUY_WITH_BONUS_REQUEST,
  payload: { userSessionId, notifyForm }
});

export const buyWithBonusSuccess = payment => ({
  type: BUY_WITH_BONUS_SUCCESS,
  payload: { payment }
});

export const buyWithGiftRequest = (userSessionId, payload, email, notifyForm) => ({
  type: BUY_WITH_GIFT_REQUEST,
  payload: {
    userSessionId,
    payload,
    email,
    notifyForm
  }
});
export const buyWithGiftSuccess = payment => ({
  type: BUY_WITH_GIFT_SUCCESS,
  payload: { payment }
});

export const applyGiftcardRequest = (userSessionId, payload, notifyForm) => ({
  type: APPLY_GIFTCARD_REQUEST,
  payload: { userSessionId, payload, notifyForm }
});

export const applyGiftcardSuccess = appliedGiftcard => ({
  type: APPLY_GIFTCARD_SUCCESS,
  payload: { appliedGiftcard }
});

export const clearTicketSessionSend = () => ({
  type: TICKET_SESSION_CLEAR
});

export const clearPaymentStatus = () => ({
  type: PAYMENT_CLEAR
});

export const stepCheckoutRequest = () => ({
  type: STEP_CHECKOUT_REQUEST
});

export const stepCheckoutSuccess = params => ({
  type: STEP_CHECKOUT_SUCCESS,
  payload: { params }
});
