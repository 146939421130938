import { compose } from 'redux';
import { connect } from 'react-redux';
import sessionsTicketsModule from 'app/store/sessionsTickets';
import Sessions from './SessionsPass.component';


const { requestSesseionsTickets } = sessionsTicketsModule.actions


const mapStateToProps = ({ sessions }) => ({
  sessionsData: sessions ? sessions : null
});

const mapDispatchToProps = {
  requestSesseionsTickets: requestSesseionsTickets,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
  (Sessions);
