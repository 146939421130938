import BaseAPI from '../BaseAPI';

class PDF extends BaseAPI {
  /**
   * Fetches PDF by orderId
   * @param orderId {string}
   * @return {Promise<*|Error>}
   */
  fetchByOrderId(orderId) {
    const url = `/orders/${orderId}/payment/tickets/pdf`;

    return this.get({ url });
  }

  /**
   * Fetches PDF by bookingId
   * @param cinemaId {string}
   * @param bookingId {string}
   * @return {Promise<*|Error>}
   */
  fetchByBookingId(cinemaId, bookingId) {
    const url = `/users/bookings/pdf/${cinemaId}/${bookingId}`;

    return this.get({ url });
  }
}

export default PDF;
