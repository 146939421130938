
export const SESSEIONS_TICKETS_REQUEST = 'sessionsTickets/SESSEIONS_TICKETS_REQUEST';
export const SESSIONS_TICKET_SUCCESS = 'sessionsTickets/SESSIONS_TICKET_SUCCESS';
export const SESSEIONS_BOOKING_COUNT_REQUEST = 'sessionsTickets/SESSEIONS_BOOKING_COUNT_REQUEST';
export const SESSIONS_BOOKING_COUNT_SUCCESS = 'sessionsTickets/SESSIONS_BOOKING_COUNT_SUCCESS';
export const SESSEIONS_SEAT_PLAN_REQUEST = 'sessionsTickets/SESSEIONS_SEAT_PLAN_REQUEST';
export const ORDER_SESSION_REQUEST = 'sessionsTickets/ORDER_SESSION_REQUEST';
export const ORDER_SESSION_REQUEST_SUCCESS = 'sessionsTickets/ORDER_SESSION_REQUEST_SUCCESS';
export const SESSIONS_SEAT_PLAN_SUCCESS = 'sessionsTickets/SESSIONS_SEAT_PLAN_SUCCESS';
export const CLEAR_SESSEION_SEAT_PLAN = 'sessionsTickets/CLEAR_SESSEION_SEAT_PLAN';
export const SET_SESSEIONS_TICKETS = 'sessionsTickets/SET_SESSEIONS_TICKETS';
