import {
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SEARCH_FILTERS_SET,
  SEARCH_DATES_SET,
  SEARCH_RESET,
  FILTER_PAYLOAD,
  CLEAR_FILTER_PAYLOAD
} from './types';

const initialState = {
  isPending: false,
  movies: [],
  filters: {
    technologies: [],
    versions: []
  },
  dates: [],
  filterPayload: {}
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case SEARCH_REQUEST: {
      return {
        ...state,
        isPending: true
      };
    }

    case SEARCH_SUCCESS: {
      return {
        ...state,
        isPending: false,
        movies: payload.movies
      };
    }

    case SEARCH_FILTERS_SET: {
      return {
        ...state,
        filters: payload.filters
      };
    }

    case SEARCH_DATES_SET: {
      return {
        ...state,
        dates: payload.dates
      };
    }

    case SEARCH_RESET: {
      return {
        ...state,
        isPending: false,
        movies: [],
      };
    }

    case FILTER_PAYLOAD: {
      return {
        ...state,
        filterPayload: payload
      };
    }
    case CLEAR_FILTER_PAYLOAD: {
      return {
        ...state,
        isPending: false,
        filterPayload: {}
      };
    }

    default: {
      return state;
    }
  }
}
