import { NEWSLETTER_REGISTRATION_SUCCESS, LIGHT_MEMBER_REGISTRATION_SUCCESS } from './types';

const initialState = {
  newsletterRegistration: [],
  lightMember: []
};

export default function(state = initialState, { type, payload }) {
  switch (type) {

    case NEWSLETTER_REGISTRATION_SUCCESS: {
      return {
        ...state,
        newsletterRegistration: payload.newsletterRegistration
      };
    }

    case LIGHT_MEMBER_REGISTRATION_SUCCESS: {
      return {
        ...state,
        lightMember: payload.lightMember
      };
    }

    default: {
      return state;
    }
  }
}
