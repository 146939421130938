import { catchError, mergeMap, startWith, map, concatMap } from 'rxjs/operators';
import { from, concat, of } from 'rxjs';
import { ofType } from 'redux-observable';

import { setLoader, setModal, handleUnknownError, setUser, clearUser, createToast } from './deps';
import {
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  REFRESH_TOKEN_REQUEST,
  REGISTER_REQUEST,
  RESET_PASSWORD_REQUEST,
  ACTIVATE_REQUEST,
  FORGOT_PASSWORD_REQUEST
} from './types';

import { registerSuccess, logoutRequest } from './actions';

export function login($action, $state, { api }) {
  const $api = api.getModuleByName('auth');

  return $action.pipe(
    ofType(LOGIN_REQUEST),
    mergeMap(({ payload: { data, notifyForm } }) =>
      from($api.login(data)).pipe(
        concatMap(user => {
          notifyForm();

          return [setUser(user), setLoader(false)];
        }),

        catchError(handleUnknownError),
        catchError(err => {
          notifyForm({ message: err.message });

          return of(setLoader(false));
        }),

        startWith(setLoader(true))
      )
    )
  );
}

export function logout($action, $state, { api }) {
  const $api = api.getModuleByName('auth');

  return $action.pipe(
    ofType(LOGOUT_REQUEST),
    mergeMap(() => from($api.logout()).pipe(map(() => clearUser())))
  );
}

export function register($action, $state, { api }) {
  const $api = api.getModuleByName('auth');

  return $action.pipe(
    ofType(REGISTER_REQUEST),
    mergeMap(({ payload: { data, notifyForm } }) =>
      from($api.register(data)).pipe(
        concatMap(() => {
          notifyForm();

          return [registerSuccess(data), setLoader(false), setModal('accountCreated', true)];
        }),

        catchError(handleUnknownError),
        catchError(err => {
          const actions = [setLoader(false)];

          if (err.errors) {
            notifyForm(err.errors);
          } else {
            actions.push(createToast('warning', err.message.replace(':', '')));
            notifyForm({ message: err.message });
          }

          return concat(actions);
        }),

        startWith(setLoader(true))
      )
    )
  );
}

export function refreshToken($action, $state, { api }) {
  const $api = api.getModuleByName('auth');

  return $action.pipe(
    ofType(REFRESH_TOKEN_REQUEST),
    mergeMap(({ payload }) =>
      from($api.updateToken()).pipe(
        mergeMap(user => {
          const actions = [setUser(user), setLoader(false)];

          if (payload.type) {
            actions.push(payload);
          }

          return concat(actions);
        }),

        catchError(handleUnknownError),
        catchError(() => concat([logoutRequest(), setLoader(false)])),

        startWith(setLoader(true))
      )
    )
  );
}

export function activate($action, $state, { api }) {
  const $api = api.getModuleByName('auth');

  return $action.pipe(
    ofType(ACTIVATE_REQUEST),
    mergeMap(({ payload: { token, memberId } }) =>
      from($api.activate(token, memberId)).pipe(
        concatMap(() => [createToast('info', 'toasters.user_activation'), setLoader(false)]),

        catchError(handleUnknownError),
        catchError(() =>
          of(createToast('warning', 'toasters.user_activation_failed'), setLoader(false))
        ),

        startWith(setLoader(true))
      )
    )
  );
}

export function forgotPassword($action, $state, { api }) {
  const $api = api.getModuleByName('auth');

  return $action.pipe(
    ofType(FORGOT_PASSWORD_REQUEST),
    mergeMap(({ payload: { data, notifyForm } }) =>
      from($api.forgotPassword(data)).pipe(
        concatMap(() => {
          notifyForm();

          return [
            setModal('forgotPassword', false),
            setModal('forgotPasswordSuccess', true),
            setLoader(false)
          ];
        }),

        catchError(handleUnknownError),
        catchError(err => {
          const actions = [setLoader(false)];

          if (err.errors) {
            notifyForm(err.errors);
          } else {
            actions.push(createToast('warning', err.message));
            notifyForm();
          }

          return concat(actions);
        }),

        startWith(setLoader(true))
      )
    )
  );
}

export function resetPassword($action, $state, { api }) {
  const $api = api.getModuleByName('auth');

  return $action.pipe(
    ofType(RESET_PASSWORD_REQUEST),
    mergeMap(({ payload: { data, redirect, notifyForm } }) =>
      from($api.resetPassword(data)).pipe(
        concatMap(() => {
          notifyForm();
          redirect();

          return [createToast('info', 'toasters.password_reset'), setLoader(false)];
        }),

        catchError(handleUnknownError),
        catchError(err => {
          notifyForm({ message: err.message });

          return of(setLoader(false));
        }),

        startWith(setLoader(true))
      )
    )
  );
}
