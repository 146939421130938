/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable array-callback-return  */
/* eslint-disable jsx-a11y/anchor-is-valid  */
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function(date, onDateSelected) {
  const [t] = useTranslation();
  const [redLabelDate, setRedLabelDate] = useState(false);

  const onSelectDate = useCallback(
    valueFromSelect => {
      if (!valueFromSelect || !valueFromSelect.length) return;

      const { value, label } = valueFromSelect[0];

      setRedLabelDate(label !== t('common.today'));

      if (!value || value.toString() === date) {
        return;
      }

      onDateSelected(value);
    },
    [date, onDateSelected]
  );

  return {
    redLabelDate,
    onSelectDate
  };
}
