export const SEARCH_REQUEST = 'search/REQUEST';
export const SEARCH_SUCCESS = 'search/SUCCESS';

export const SEARCH_FILTERS_REQUEST = 'search/FILTERS_REQUEST';
export const SEARCH_FILTERS_SET = 'search/FILTERS_SET';

export const SEARCH_DATES_REQUEST = 'search/DATES_REQUEST';
export const SEARCH_DATES_SET = 'search/DATES_SET';

export const SEARCH_RESET = 'search/RESET';

export const FILTER_PAYLOAD = 'search/FILTER_PAYLOAD';
export const CLEAR_FILTER_PAYLOAD = 'search/CLEAR_FILTER_PAYLOAD';