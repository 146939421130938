import { from, concat } from 'rxjs';
import { catchError, mergeMap, startWith } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { handleErrors, setLoader, setError } from './deps';
import { setSession } from './actions';
import { SESSION_REQUEST } from './types';

/* eslint-disable-next-line */
export const fetchSession = ($action, $state, { api }) => {
  const $api = api.getModuleByName('sessions');

  return $action.pipe(
    ofType(SESSION_REQUEST),
    mergeMap(action => {
      const {
        payload: { id }
      } = action;

      return from($api.getSessionById(id)).pipe(
        mergeMap(session => concat([setSession(session), setLoader(false)])),
        ...handleErrors(action),

        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      );
    })
  );
};
