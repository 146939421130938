import styled from 'styled-components';
import { textTransform } from 'utils';

export default styled.div.attrs({
  className: 'b-mobile-nav'
})`
  display: none;
  width: 100%;
  height: ${({ theme }) => theme.breakPoints.sm.mobileNav};
  min-width: 320px;
  padding: 12px 0;
  position: fixed;
  z-index: 10;
  bottom: 0;
  background-color: ${({ theme }) => theme.color.bgSecondary};
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.2);

  .b-mobile-nav__inner {
    /* font-size: 0; */
    text-align: center;
  }

  .b-mobile-nav__link {
    width: 20%;

    &.active {
      pointer-events: none;

      .svg-movie,
      .svg-pin,
      .svg-search,
      .svg-events,
      .svg-user{
        fill: ${({ theme }) => theme.color.accent};
      }
      .svg-text{
        color:${({ theme }) => theme.color.accent};
      }
    }
  }
  .svg-text{
    color: #a1a1a1;
    font-family:sans-serif;
    font-size:14px;
    text-transform: ${textTransform};
    padding-top: 2px;
    font-weight: bold;
  }
  .svg-movie,
  .svg-pin,
  .svg-search,
  .svg-events,
  .svg-user {
    width: 28px;
    height: 28px;
    fill: ${({ theme }) => theme.color.rgbaWhite('.8')};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    ${({ isVisible }) => isVisible && 'display: block;'}
    position:sticky;
    .toast-menu-icon{
      float:right;
      display: flex;
      flex-direction: column;
    }
  }
  @media screen and (max-width: 550px){
    .svg-text{
      /* font:400 1rem Cabin, sans-serif; !important; */
      font:400 1rem 'Open Sans', sans-serif; !important;
    }

  }
`;
