import { mergeMap, catchError, concatMap, startWith } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of, from } from 'rxjs';
import { setLoader, createToast, handleErrors } from './deps';
import {
  setWatchlist,
  setWatchlistMovies,
  setWatchlistEvents,
  setWatchlistError,
  analyticsStatus
} from './actions';
import {
  REQUEST_WATCHLIST,
  REQUEST_WATCHLIST_ADD_MOVIE,
  REQUEST_WATCHLIST_ADD_EVENT,
  REQUEST_WATCHLIST_REMOVE_MOVIE,
  REQUEST_WATCHLIST_REMOVE_EVENT
} from './types';

export const fetchWatchlist = ($action, $state, { api }) => {
  const $apiWatchlist = api.getModuleByName('watchlist');

  return $action.pipe(
    ofType(REQUEST_WATCHLIST),
    mergeMap(action =>
      from($apiWatchlist.fetchWatchlist()).pipe(
        concatMap(({ movies, events }) => [
          setWatchlistMovies(movies),
          setWatchlistEvents(events),
          setLoader(false)
        ]),

        ...handleErrors(action),

        catchError(error => of(createToast('warning', error.message), setLoader(false))),

        startWith(setLoader(true))
      )
    )
  );
};

export const addMovieToWatchlist = ($action, $state, { api }) => {
  const $apiWatchlist = api.getModuleByName('watchlist');

  return $action.pipe(
    ofType(REQUEST_WATCHLIST_ADD_MOVIE),
    mergeMap(({ payload: { movieId } }) =>
      from($apiWatchlist.addMovieToWatchlist(movieId)).pipe(
        concatMap(watchlist => [analyticsStatus({ favorite: true }), setWatchlist(watchlist)]),

        catchError(error =>
          of(
            createToast('warning', error.message.replace(':', '')),
            setWatchlistError(movieId, error)
          )
        )
      )
    )
  );
};

export const removeMovieFromWatchlist = ($action, $state, { api }) => {
  const $apiWatchlist = api.getModuleByName('watchlist');

  return $action.pipe(
    ofType(REQUEST_WATCHLIST_REMOVE_MOVIE),
    mergeMap(({ payload: { movieId } }) =>
      from($apiWatchlist.removeMovieFromWatchlist(movieId)).pipe(
        concatMap(watchlist => [analyticsStatus({ favorite: false }), setWatchlist(watchlist)]),
        catchError(error =>
          of(
            createToast('warning', error.message.replace(':', '')),
            setWatchlistError(movieId, error)
          )
        )
      )
    )
  );
};

export const addEventToWatchlist = ($action, $state, { api }) => {
  const $apiWatchlist = api.getModuleByName('watchlist');

  return $action.pipe(
    ofType(REQUEST_WATCHLIST_ADD_EVENT),
    mergeMap(({ payload: { eventId } }) =>
      from($apiWatchlist.addEventToWatchlist(eventId)).pipe(
        concatMap(watchlist => [analyticsStatus({ favorite: true }), setWatchlist(watchlist)]),

        catchError(error =>
          of(
            createToast('warning', error.message.replace(':', '')),
            setWatchlistError(eventId, error)
          )
        )
      )
    )
  );
};

export const removeEventFromWatchlist = ($action, $state, { api }) => {
  const $apiWatchlist = api.getModuleByName('watchlist');

  return $action.pipe(
    ofType(REQUEST_WATCHLIST_REMOVE_EVENT),
    mergeMap(({ payload: { eventId } }) =>
      from($apiWatchlist.removeEventFromWatchlist(eventId)).pipe(
        concatMap(watchlist => [analyticsStatus({ favorite: false }), setWatchlist(watchlist)]),
        catchError(error =>
          of(
            createToast('warning', error.message.replace(':', '')),
            setWatchlistError(eventId, error)
          )
        )
      )
    )
  );
};
