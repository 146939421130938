import { from } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { handleErrors, setError, setLoader } from './deps';
import { searchSuccess, searchFiltersSet, searchDatesSet } from './actions';
import { SEARCH_REQUEST, SEARCH_FILTERS_REQUEST, SEARCH_DATES_REQUEST } from './types';

export const findMovies = ($action, $state, { api }) => {
  const $apiMovies = api.getModuleByName('movies');

  return $action.pipe(
    ofType(SEARCH_REQUEST),
    mergeMap(action => {
      const {
        payload: { body, params }
      } = action;

      return from($apiMovies.find(body, params)).pipe(
        map(movies => searchSuccess(body.title, movies)),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)])
      );
    })
  );
};

export const fetchFiltres = ($action, $state, { api }) => {
  const $apiMovies = api.getModuleByName('movies');

  return $action.pipe(
    ofType(SEARCH_FILTERS_REQUEST),
    mergeMap(action => {
      return from($apiMovies.fetchFilters()).pipe(
        map(filters => searchFiltersSet(filters)),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)])
      );
    })
  );
};

export const fetchDates = ($action, $state, { api }) => {
  const $apiMovies = api.getModuleByName('movies');

  return $action.pipe(
    ofType(SEARCH_DATES_REQUEST),
    mergeMap(action => {
      return from($apiMovies.fetchDatesList()).pipe(
        map(filters => searchDatesSet(filters)),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)])
      );
    })
  );
};
