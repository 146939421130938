import React, { useMemo } from "react";
import { Input } from "../form";
import Button from "../Button";
import { useTranslation } from "react-i18next";
import { getPreValidationError } from "app/utils";

const NewsLetterFormHtml = ({ newsLetter, handleSubmit, type, newsData, isShowAgree, focusOff }) => {
  const [t, i18n] = useTranslation();
  const { language } = i18n;
  const languageContents = useMemo(
    () => ({
      description: language === 'en' ? 'descriptionEN' : 'description',
      button: language === 'en' ? 'buttonEN' : 'button'
    }),
    [language]
  );
  return (
    <>
      {isShowAgree ?
        (<>
          <Input
            id="newsLetter"
            type="email"
            placeholder={t("common.newsletter_placeholder")}
            className="footer__main-inner-newsLetter-input"
          />
          <Button
            className="footer__main-inner-newsLetter-subscribe"
            isPrimary
          >
            {type === "default" ? t('common.subscribe') : newsData?.[languageContents.button]}

          </Button>
        </>
        ) : (
          <>
            <Input
              id="newsLetter"
              type="email"
              {...newsLetter.input}
              placeholder={t('common.newsletter_placeholder')}
              className="footer__main-inner-newsLetter-input"
              error={getPreValidationError(newsLetter, t)}
            />
            <Button
              className="footer__main-inner-newsLetter-subscribe"
              isPrimary
              onClick={() => handleSubmit()}
              disabled={getPreValidationError(newsLetter, t) || newsLetter.input.value > 1}
            // disabled={isShowAgree}
            >
              {type === "default" ? t('common.subscribe') : newsData?.[languageContents.button]}
            </Button>
          </>)}

    </>
  )
}

export default NewsLetterFormHtml;