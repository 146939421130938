import BaseAPI from '../BaseAPI';

/**
 * Api class for processing seat plan
 */
class SeatPlan extends BaseAPI {
  /**
   * Gets a seat plan for an initial painting
   *
   * @param cinemaId {string}
   * @param sessionId {string}
   * @param orderId {string|undefined}
   * @return {Promise<*|Error>}
   */
  getPlan(cinemaId, sessionId, orderId) {
    const url = `/seat-plan/${cinemaId}/${sessionId}`;
    const params = orderId ? { orderId } : {};

    return this.get({ url, params });
  }

  /**
   * Gets a chairs's types definition
   *
   * @param cinemaId {string}
   * @param sessionId {string}
   * @returns {Promise<*|Error>}
   */
  getLegend(cinemaId, sessionId) {
    const url = `/cinemas/${cinemaId}/sessions/${sessionId}/area-categories`;

    return this.get({ url });
  }
}

export default SeatPlan;
