import {
  MOVIES_FETCH_SUCCESS,
  SET_UPCOMING_MOVIES,
  MOVIES_SET_DATE,
  MOVIES_RESET_STATE,
  SET_DAYS_SESSIONS,
  SET_MOVIE,
  MOVIES_FILTER_SUCCESS,
  UPCOMING_MOVIES_FILTER_SUCCESS,
  SET_DATE_LIST,
  SET_SESSIONS,
  ADVERTISEMENT_SUCCESS,
  SET_NEW_SESSIONS,
  EVENT_WEB_SEND_SUCCESS
} from './types';

const initialState = {
  films: [],
  movieDetails: {},
  movieFilterDetails: [],
  upcomingMovieFilterDetails: [],
  sessions: [],
  datesDetails: [],
  dates: [],
  date: null,
  upcomingDates: [],
  daysSessions: [],
  newSessions: [],
  advertisement: [],
  eventWeb: []
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case MOVIES_FETCH_SUCCESS: {
      return {
        ...state,
        films: payload.films || [],
        dates: payload.dates || []
      };
    }

    case SET_UPCOMING_MOVIES: {
      return {
        ...state,
        films: payload.films || [],
        upcomingDates: payload.upcomingDates || []
      };
    }

    case MOVIES_SET_DATE: {
      return {
        ...state,
        date: payload.date
      };
    }

    case MOVIES_RESET_STATE: {
      return {
        ...state,
        films: [],
        dates: [],
        upcomingDates: []
      };
    }

    case SET_DAYS_SESSIONS: {
      return {
        ...state,
        daysSessions: payload.sessions || []
      };
    }

    case SET_MOVIE: {
      return {
        ...state,
        movieDetails: payload.movie
      };
    }
    case MOVIES_FILTER_SUCCESS: {
      // console.log('data28822', payload?.movies)
      return {
        ...state,
        movieFilterDetails: payload?.movies
      };
    }

    case SET_DATE_LIST: {
      return {
        ...state,
        datesDetails: payload.dates
      };
    }

    case SET_SESSIONS: {
      return {
        ...state,
        sessions: payload.sessions
      };
    }

    case SET_NEW_SESSIONS: {
      return {
        ...state,
        newSessions: payload.newSessions
      };
    }

    case ADVERTISEMENT_SUCCESS: {
      return {
        ...state,
        advertisement: payload?.params?.data
      };
    }

    case EVENT_WEB_SEND_SUCCESS: {
      return {
        ...state,
        eventWeb: payload?.eventWeb
      };
    }

    default: {
      return state;
    }
  }
}
