import { useMemo } from 'react';
import useRouter from 'app/hooks/useRouter';

export default (...params) => {
  const {
    location: { search }
  } = useRouter();

  return useMemo(() => {
    const urlSearchParams = new URLSearchParams(search);
    const hash = {};

    if (!params.length) return null;

    for (let i = 0; i < params.length; i += 1) {
      const key = params[i];

      if (urlSearchParams.has(key)) {
        hash[key] = urlSearchParams.get(key);
      }
    }

    return hash;
  }, [search, params]);
};
