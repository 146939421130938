import styled from 'styled-components';

/* eslint-disable-next-line */
export const CookiesAgreementComponent = styled.div.attrs({
  className: 'b-cookies-agreement'
})`
  width: 100%;
  min-width: 320px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.17);
  background-color: rgba(36, 36, 36, 0.9);
  text-align: center;
  position: fixed;
  bottom: 0;
  z-index: 11;

  & > .container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .b-cookies-agreement__text {
    padding: ${({ theme }) => theme.indent.m} 0;
    margin-right: ${({ theme }) => theme.indent.m};
    font-size: 1.6rem;
    color: ${({ theme }) => theme.color.textPrimary};
    text-align: left;
  }

  .b-cookies-agreement__link {
    font-size: 1.6rem;
    text-decoration: underline;
    color: ${({ theme }) => theme.color.textPrimary};

    &:hover {
      text-decoration: none;
    }
  }

  .b-cookies-agreement__buttons {
    display: flex;
    justify-content: flex-end;

    button {
      &:last-child {
        margin-left: ${({ theme }) => theme.indent.m};
      }
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    bottom: ${({ theme }) => theme.breakPoints.sm.mobileNav};

    & > .container {
      display: block;
    }

    .b-cookies-agreement__text {
      margin-right: 0;
    }

    .b-cookies-agreement__buttons {
      margin-bottom: ${({ theme }) => theme.indent.m};
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
    .b-cookies-agreement__buttons {
      flex-direction: column;

      button {
        &:last-child {
          margin: ${({ theme }) => theme.indent.m} 0 0 0;
        }
      }
    }
  }
`;
