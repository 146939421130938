import styled from 'styled-components';

export const List = styled.ul.attrs({
  className: 'l-toasters'
})`
  max-width: 640px;
  min-width: 288px;
  margin-top: ${({ theme }) => theme.indent.s};
  position: fixed;
  z-index: 13;
  top: ${({ theme }) => theme.breakPoints.xl.header};
  left: 50%;
  transform: translateX(-50%);

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
    top: ${({ theme }) => theme.breakPoints.sm.header};
  }
`;

export const Item = styled.li.attrs({
  className: 'l-toasters__item'
})`
  width: 100%;
  padding: ${({ theme }) => theme.indent.m};
  border-radius: ${({ theme }) => theme.indent.s};
  box-shadow: 0 4px 8px 0 ${({ theme }) => theme.color.rgbaBlack(0.41)},
    0 0 4px 0 ${({ theme }) => theme.color.rgbaBlack(0.4)};
  background-color: ${({ theme }) => theme.color.bgSecondary};
  text-align: center;
  font-size: 1.8rem;
  line-height: 1.33;
  backface-visibility: hidden;
  overflow: visible;

  & + & {
    margin-top: ${({ theme }) => theme.indent.s};
  }

  &._warning {
    color: ${({ theme }) => theme.color.accent};
  }

  &._info {
    color: ${({ theme }) => theme.color.textPrimary};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    padding: 12px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
    font-size: 1.6rem;
  }
`;
