/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable array-callback-return  */
/* eslint-disable jsx-a11y/anchor-is-valid  */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { addHours, format } from 'date-fns';
import { Select, Svg } from 'app/components';
import { dateValidator, getFormattedDate } from 'app/utils';
import useUtils from 'app/hooks/useUtils';
import { useLocation } from 'react-router-dom';
import FilterWrapper from './styles';

const propTypes = {
  dates: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedDate: PropTypes.string,
  onSelectDate: PropTypes.func.isRequired,
  redLabelDate: PropTypes.bool,

  locations: PropTypes.arrayOf(PropTypes.object),
  selectedLocation: PropTypes.string,
  onSelectLocation: PropTypes.func,
  redLabelLocation: PropTypes.bool,

  cinemas: PropTypes.arrayOf(PropTypes.object),
  selectedCinema: PropTypes.string,
  onSelectCinema: PropTypes.func,
  redLabelCinema: PropTypes.bool,

  isUpcoming: PropTypes.bool,
  isAsMonths: PropTypes.bool,
  withPadding: PropTypes.bool,

  sessions: PropTypes.oneOfType([PropTypes.array, PropTypes.any]).isRequired,
};

const defaultProps = {
  selectedDate: null,
  redLabelDate: false,

  locations: null,
  selectedLocation: null,
  onSelectLocation: null,
  redLabelLocation: false,

  cinemas: null,
  selectedCinema: null,
  redLabelCinema: null,
  onSelectCinema: false,

  isUpcoming: false,
  isAsMonths: false,
  withPadding: true
};

const MoviesDatesFilter = ({
  dates,
  selectedDate,
  onSelectDate,
  redLabelDate,

  locations,
  selectedLocation,
  redLabelLocation,
  onSelectLocation,

  cinemas,
  selectedCinema,
  redLabelCinema,
  onSelectCinema,

  isUpcoming,
  isAsMonths,
  isAsAllDates,
  withPadding,
  withAllDateOption,

  sessions
}) => {
  const [t] = useTranslation();
  const isDateValid = ((isAsMonths || isAsAllDates) && selectedDate === 'all') || dateValidator(selectedDate);
  const [datesForDropdown, setDates] = useState([]);
  const [selectedDateValue, setSelectedDate] = useState(null);
  const [locationsForDropdown, setLocations] = useState([]);
  const [selectedLocationValue, setSelectedLocation] = useState(null);
  const [cinemasForDropdown, setCinemas] = useState([]);
  const [selectedCinemaValue, setSelectedCinema] = useState(null);
  const { getDateWithOffset } = useUtils();
  const location = useLocation();



  // console.log("sessions", sessions);
  const moviesId = [];

  const dataCinema = sessions?.map((item) => {
    if (item?.sessions?.length > 0) {
      moviesId.push(item?.id);
    }
  })
  // console.log("moviesId", moviesId);
  const searchCinemaData = moviesId?.map((user) => {
    const searchCinema = cinemas.find((cinema) => (user === cinema?.id));


return searchCinema;
  });

  useEffect(() => {
    if (!isDateValid) return;
    // LIST OF OPTIONS WITH THE YEAR

    const years = [];

    for (const date of dates) {
      if (years.length === 0) {
        years.push(new Date(date).getFullYear());
      } else if (!years.find(e => e === new Date(date).getFullYear())) {
        years.push(new Date(date).getFullYear());
      }
    }
    let options = [];

    if (withAllDateOption) {
      options.push({ label: 'All', value: 'all', key: 'ALL_DATES' });
    }

    for (const year of years) {
      if (year) {
        options.push({ label: year.toString(), value: year.toString(), disabled: true, key: year });
        for (const date of dates) {
          const dateWithOffset = process.env.REACT_APP_COUNTRY === 'grc' ? addHours(getDateWithOffset(new Date(date)), 2) : getDateWithOffset(new Date(date));

          const formatedDate = format(new Date(dateWithOffset), 'yyyy-MM-dd');
          const splitDate = formatedDate.split('-');

          if (splitDate[0] === year.toString()) {
            options.push({
              label: getFormattedDate(isAsMonths, new Date(dateWithOffset), t, getDateWithOffset),
              value: format(getDateWithOffset(new Date(dateWithOffset)), 'yyyy-MM-dd'),
              key: date
            });
          }
        }
      }
    }
    /* let options = dates.map(date => ({
      label: getFormattedDate(isAsMonths, date, t, getDateWithOffset),
      value: format(getDateWithOffset(date), 'yyyy-MM-dd')
    }));
    */
    if (isAsMonths) {
      options = [{ label: t('common.all_months'), value: 'all' }, ...options];
    }

    setDates(options);
    if (isAsAllDates) {
      setSelectedDate({
        label:
          selectedDate === 'all'
            ? t('common.all')
            : getFormattedDate(isAsMonths, selectedDate, t, getDateWithOffset),
        value: selectedDate === 'all' ? 'all' : format(getDateWithOffset(selectedDate), 'yyyy-MM-dd')
      });
    } else {
      setSelectedDate({
        label:
          selectedDate === 'all'
            ? t('common.all_months')
            : getFormattedDate(isAsMonths, selectedDate, t, getDateWithOffset),
        value: selectedDate === 'all' ? 'all' : format(getDateWithOffset(selectedDate), 'yyyy-MM-dd')
      });
    }
  }, [dates, selectedDate, isAsMonths, isAsAllDates]);

  useEffect(() => {
    if (!locations || !locations.length) {
      return;
    }

    /* eslint-disable no-param-reassign */
    const reducedLocations = locations.reduce(
      (acc, loc) => {
        const forSelect = {
          label: loc.name,
          value: loc.id
        };

        acc.forSelect.push(forSelect);

        if (loc.id.toString() === selectedLocation) {
          acc.selected = forSelect;
        }

        return acc;
      },
      {
        forSelect: [
          {
            label: t('common.all_states'),
            value: 'all'
          }
        ],
        selected: {
          label: t('common.all_states'),
          value: 'all'
        }
      }
    );
    /* eslint-enable no-param-reassign */

    // console.log("reducedLocations_Select",reducedLocations);
    // console.log("reducedLocations_Selected",reducedLocations.selected);
    setLocations(reducedLocations.forSelect);
    setSelectedLocation(reducedLocations.selected);
  }, [locations, selectedLocation]);

  useEffect(() => {
    if (!cinemas || !cinemas.length) {
      return;
    }


    /* eslint-disable no-param-reassign */
    if (location?.search.split("&")[1] === 'daily=1' || (location?.search.split("&")[0].split("?")[1] === 'location=all' && location?.search.split("&")[1] !== 'event=1')) {
      const reducedCinemas = searchCinemaData.reduce(
        (acc, loc) => {
          const forSelect = {
            label: loc.name,
            value: loc.id
          };

          acc.forSelect.push(forSelect);

          if (loc.id === selectedCinema) {
            acc.selected = forSelect;
          }

          return acc;
        },
        {
          forSelect: [
            {
              label: t('common.all_cinemas'),
              value: 'all'
            }
          ],
          selected: {
            label: t('common.all_cinemas'),
            value: 'all'
          }
        }
      );

      // console.log("reducedCinemas.forSelect", reducedCinemas.forSelect)
      // reducedCinemas.forSelect = reducedCinemas.forSelect.sort(function (a, b) {
      //   if (a.label < b.label) {
      //     return -1;
      //   }
      //   if (a.label > b.label) {
      //     return 1;
      //   }
      //   return 0;
      // });
      setCinemas(reducedCinemas.forSelect);
      setSelectedCinema(reducedCinemas.selected);
    }
    else {
      const reducedCinemas = cinemas.reduce(
        (acc, loc) => {
          const forSelect = {
            label: loc.name,
            value: loc.id
          };

          acc.forSelect.push(forSelect);

          if (loc.id === selectedCinema) {
            acc.selected = forSelect;
          }

          return acc;
        },
        {
          forSelect: [
            {
              label: t('common.all_cinemas'),
              value: 'all'
            }
          ],
          selected: {
            label: t('common.all_cinemas'),
            value: 'all'
          }
        }
      );
      /* eslint-enable no-param-reassign */
      // reducedCinemas.forSelect = reducedCinemas.forSelect.sort(function (a, b) {
      //   if (a.label < b.label) {
      //     return -1;
      //   }
      //   if (a.label > b.label) {
      //     return 1;
      //   }
      //   return 0;
      // });

      setCinemas(reducedCinemas.forSelect);
      setSelectedCinema(reducedCinemas.selected);
    }
  }, [cinemas, selectedCinema]);

  const [windowSize, setWindowSize] = useState();

  window.addEventListener('resize', function () {
    setWindowSize(window.innerWidth);
  }, true)

  return (
    <FilterWrapper withPadding={withPadding}>
      <div className={windowSize <= 992 ? "" : ""}>
        {selectedLocationValue ? (
          <Select
          isHidden={isUpcoming}
            options={locationsForDropdown}
            values={[selectedLocationValue]}
            onChange={onSelectLocation}
            isRedLabel={redLabelLocation}
            isLocation
          >
            <Svg id="location" />
          </Select>
        ) : null}

        {selectedCinemaValue ? (
          <Select
            isHidden={isUpcoming}
            options={cinemasForDropdown}
            values={[selectedCinemaValue]}
            onChange={onSelectCinema}
            isRedLabel={redLabelCinema}
            isCinema
          >
            <Svg id="location" />
          </Select>
        ) : null}

        {selectedDateValue ? (
          <Select
            className='cinema-details__datepicker'
            options={datesForDropdown}
            onChange={onSelectDate}
            values={[selectedDateValue]}
            isRedLabel={redLabelDate}
            isCalendar
            disabledLabel="Disabled"
          >
            <Svg id="calendar" />
          </Select>
        ) : null}
      </div>
    </FilterWrapper>
  );
};

MoviesDatesFilter.propTypes = propTypes;
MoviesDatesFilter.defaultProps = defaultProps;
export default MoviesDatesFilter;
