import { concat, from } from 'rxjs';
import { mergeMap, concatMap, startWith, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { handleErrors, setLoader, createToast } from './deps';
import { byBookingSuccess, byOrderSuccess, setPDF } from './actions';
import { BY_BOOKING_REQUEST, BY_ORDER_REQUEST } from './types';

export function fetchByOrder($action, $state, { api }) {
  const $api = api.getModuleByName('pdf');

  return $action.pipe(
    ofType(BY_ORDER_REQUEST),
    mergeMap(action => {
      const {
        payload: { orderId }
      } = action;

      return from($api.fetchByOrderId(orderId)).pipe(
        concatMap(pdf => [setPDF(pdf), byOrderSuccess(pdf), setLoader(false)]),

        ...handleErrors(action),

        catchError(err => concat([createToast('warning', err.message), setLoader(false)])),

        startWith(setLoader(true))
      );
    })
  );
}

export function fetchByBooking($action, $state, { api }) {
  const $api = api.getModuleByName('pdf');

  return $action.pipe(
    ofType(BY_BOOKING_REQUEST),
    mergeMap(action => {
      const {
        payload: { cinemaId, bookingId }
      } = action;

      return from($api.fetchByBookingId(cinemaId, bookingId)).pipe(
        concatMap(pdf => [setPDF(pdf), byBookingSuccess(pdf), setLoader(false)]),

        ...handleErrors(action),

        catchError(err => concat([createToast('warning', err.message), setLoader(false)])),

        startWith(setLoader(true))
      );
    })
  );
}
