export default theme => `
  #svg-sprite {
    display: block;
    width: 0;
    height: 0;
    overflow: hidden;
    clip: rect(-1px, -1px, -1px, -1px);
  }
  
  #vapc {
    height: 100%;
  }
  
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  ::-webkit-scrollbar-track {
    border-radius: 8px;
    background: #2d2d2d;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.16);
    border-radius: 8px;
    transition: background .2s ease-out;
  
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  
  .ReactModal__Body--open {
    overflow: hidden;
  }
  
  .ReactModal__Overlay {
    animation: smoothAppearance 0.25s ease-out;
  }
  
  .ReactModal__Overlay::after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%;
  }
  
  .svg-preloader {
    opacity: 0;
    animation: preloaderRotate .8s infinite;
  }
  
  
  @keyframes smoothAppearance {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes bg-transparent {
    to {
      color: #fff;
      background-color: transparent;
    }
  }
  
  @keyframes bounce {
    0% {
      transform: scale(1, 1);
    }
  
    30% {
      transform: scale(1.25, 0.75);
    }
  
    40% {
      transform: scale(0.75, 1.25);
    }
  
    50% {
      transform: scale(1.15, 0.85);
    }
  
    65% {
      transform: scale(.95, 1.05);
    }
  
    75% {
      transform: scale(1.05, .95);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  @keyframes scaleAndBounce {
    0% {
      opacity: 0;
      transform: scale(0);
    }
  
    50% {
      transform: scale(1.3);
    }
  
    80% {
      transform: scale(.9);
    }
  
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @keyframes preloaderRotate {
    from {
      opacity: 1;
    }
  
    to {
      opacity: 1;
      transform: rotateZ(360deg);
    }
  }
  
  @keyframes rotate {
    to {
      transform: rotateY(180deg);
    }
  }


  
  @media screen and (max-width: ${theme.breakPoints.md.width}) {
    ::-webkit-scrollbar {
      display: none;
    }
    
    html {
      height: auto;
    }
  }

  @media screen and (min-width: ${theme.breakPoints.sm.width}) {
    .sm-visible {
      display: none !important;
    }
  }

  @media screen and (max-width: ${theme.breakPoints.sm.width}) {
    .sm-hidden {
      display: none !important;
    }
  }
`;
