import {
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SEARCH_FILTERS_REQUEST,
  SEARCH_FILTERS_SET,
  SEARCH_DATES_REQUEST,
  SEARCH_DATES_SET,
  SEARCH_RESET,
  FILTER_PAYLOAD,
  CLEAR_FILTER_PAYLOAD
} from './types';

export const searchRequest = (body, params) => ({
  type: SEARCH_REQUEST,
  payload: {
    body,
    params
  }
});
export const searchSuccess = (request, movies) => ({
  type: SEARCH_SUCCESS,
  payload: {
    request,
    movies
  }
});

export const searchFilterRequest = () => ({
  type: SEARCH_FILTERS_REQUEST
});
export const searchFiltersSet = filters => ({
  type: SEARCH_FILTERS_SET,
  payload: {
    filters
  }
});

export const searchDatesRequest = () => ({
  type: SEARCH_DATES_REQUEST
});
export const searchDatesSet = dates => ({
  type: SEARCH_DATES_SET,
  payload: {
    dates
  }
});

export const searchReset = () => ({
  type: SEARCH_RESET
});

export const filterPayloadSet = payload => ({
  type: FILTER_PAYLOAD,
  payload
});

export const clearFilterPayloadSet = () => ({
  type: CLEAR_FILTER_PAYLOAD
});
