import React from 'react';
import { withRouter } from 'react-router-dom';
import RouterContext from 'app/context/router';

// eslint-disable-next-line
const RouterContextComponent = ({ history, location, children }) => (
  <RouterContext.Provider value={{ history, location }}>{children}</RouterContext.Provider>
);

export default withRouter(RouterContextComponent);
