import BaseAPI from '../BaseAPI';

/**
 * Api class for giftcards
 */

class Giftcards extends BaseAPI {
  /**
   * Creates Rewards instance
   *
   * @param props {Object}
   * @return {Giftcards}
   */
  constructor(props) {
    super(props);

    this.baseUrl = '/users/giftcard';
  }

  /**
   * Gets giftcard list
   * @return {Promise<*|Error>}
   */
  getGiftcards() {
    const url = `${this.baseUrl}`;

    return this.get({ url });
  }

  /**
   * Gets restrictions
   * @return {Promise<*|Error>}
   */
  getRestrictions() {
    const url = `/restrictions`;

    return this.get({ url });
  }

  getLocation() {
    const url = `/locations`;

    return this.get({ url });
  }

  addGiftcard(giftcardNumber) {
    const url = `${this.baseUrl}/addgiftcard/${giftcardNumber}`;

    return this.post({ url });
  }

  addGiftcardLogout(giftcardNumber) {
    const url = `${this.baseUrl}/getgiftcardvalue/${giftcardNumber}`;

    return this.post({ url });
  }
}

export default Giftcards;
