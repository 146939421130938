import BaseAPI from '../BaseAPI';

class Advertising extends BaseAPI {
  constructor(props) {
    super(props);
    this.baseUrl = `/list?country=${process.env.REACT_APP_COUNTRY?.toUpperCase()}`;
  }

  /**
   * Gets a list of cinema advertising
   *
   * @returns {Promise<*|Error>}
   */
  fetchCinemaAll() {
    const url = `/cinema-advertising${this.baseUrl}`;

    return this.get({ url });
  }

  /**
   * Gets a list of online advertising
   *
   * @returns {Promise<*|Error>}
   */
  fetchOnlineAll() {
    const url = `/online-advertising${this.baseUrl}`;

    return this.get({ url });
  }

  /**
   * Gets a list of press info
   *
   * @returns {Promise<*|Error>}
   */
  fetchPressInfoAll() {
    const url = `/press-info${this.baseUrl}`;

    return this.get({ url });
  }
}

export default Advertising;
