// import { createMuiTheme } from '@material-ui/core';
import { createTheme } from '@mui/material/styles';
import theme from 'app/theme';
import { textTransform } from 'utils';

export default createTheme({
  typography: {
    useNextVariants: true
  },

  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          display: 'none'
        }
      }
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: theme.color.bgSecondary
        },
      },
    },

    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          height: '95px',
          backgroundColor: '#343434'
        },

      },
    },

    MuiTypography: {
      styleOverrides: {
        overline: {
          display: 'none'
        },

        h3: {
          color: `${theme.color.rgbaWhite('.54')} !important`,
          fontSize: '5rem',
          '&.Mui-selected': {
            color: '#fff !important',
            fontSize: '5rem',
            fontWeight: 300
          }
        },

        subtitle2: {
          color: `${theme.color.rgbaWhite('.54')} !important`,
          fontSize: '17px',
          '&.Mui-selected': {
            color: '#fff !important',
            fontSize: '17px',
          }
        }
      }
    },

    MuiButton: {
      styleOverrides: {
        textPrimary: {
          // textTransform: 'uppercase',
          textTransform: `${textTransform}`,
          fontSize: '1.4rem',
          fontWeight: '700',
          color: theme.color.textPrimary,
          borderRadius: '20px',

          '&:hover': {
            backgroundColor: '#343434'
          },
        }
      }
    },

    MuiPickersArrowSwitcher: {
      styleOverrides: {
        button: {
          display: 'none'
        }
      }
    },

    MuiPickersToolbarButton: {
      styleOverrides: {
        toolbarBtnSelected: {
          color: theme.color.textPrimary,
          fontSize: '5rem',
          fontWeight: '300'
        }
      }
    },

    MuiClockNumber: {
      styleOverrides: {
        root: {
          color: theme.color.textPrimary,
          fontSize: '1.5rem'
        }
      }
    },

    MuiClock: {
      styleOverrides: {
        pin: {
          backgroundColor: theme.color.accentDark
        }
      }
    },

    MuiClockPointer: {
      styleOverrides: {
        root: {
          backgroundColor: theme.color.accentDark
        },
        pointer: {
          backgroundColor: theme.color.accentDark
        },
        thumb: {
          border: `14px solid ${theme.color.accentDark}`,
          backgroundColor: theme.color.accentDark
        },
        noPoint: {
          backgroundColor: theme.color.accentDark
        }
      }
    },

    MuiPickersModal: {
      styleOverrides: {
        dialogRoot: {
          minWidth: '328px',
          minHeight: '470px',
          boxShadow: '0 15px 12px 0 rgba(0, 0, 0, 0.22)',
          backgroundColor: theme.color.bgPopup,
          color: theme.color.textPrimary,

          '@media screen and (max-width: 740px)': {
            minWidth: '300px',
            minHeight: '465px'
          }
        }
      }
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          margin: '0',
          padding: '8px 12px 15px'
        }
      }
    },

    MuiDialog: {
      styleOverrides: {
        container: {
          overflowY: 'auto',
          padding: '25px 0'
        },

        paper: {
          margin: 'auto 0'
        }
      }
    },
  }
});
