import 'react-app-polyfill/ie11';
import './polyfills';

import React, { Suspense, useEffect } from 'react';
import ClearCache from 'react-clear-cache';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import ReactModal from 'react-modal';
import TagManager from 'react-gtm-module';
import i18n from 'app/i18n';
import theme from 'app/theme';
import configureStore from 'app/configureStore';
import Routes from 'app/router';
import API from 'app/services/api';
import storage from 'app/services/storage';
import session from 'app/services/session';
import ConfigContext from 'app/context/config';
import UtilsContext from 'app/context/utils';
import SessionContext from 'app/context/session';
import GoogleAnalytics from 'app/services/GoogleAnalytics';
import AnalyticsReporter from 'app/services/AnalyticsReporter';
import NoopAnalytics from 'app/services/NoopAnalytics';
import { getConfiguredUtils } from 'app/hooks/useUtils';
import CookieBot from 'react-cookiebot';

export default function (config, appElement) {
  const {
    api: { endpoint },
    rootElementId,
    intl,
    serverTimeZone,
    gtmId,
    domainGroupId
  } = config;
  const api = new API(config, storage);
  const configuredUtils = getConfiguredUtils(endpoint, serverTimeZone, theme);
  const canUseCookies = storage.getCookiesAgreementStatus() === 'true';
  const Analytics = canUseCookies ? GoogleAnalytics : NoopAnalytics;
  const ga = new Analytics({
    id: config.googleAnalyticKey,
    date: configuredUtils.getDateWithOffset()
  });
  const reporter = new AnalyticsReporter(config.routes, ga);
  const store = configureStore(config, api, reporter, session, storage);
  // window['gtag_enable_tcf_support'] = false
  // window['analytics_storage'] = 'denied'

  ReactModal.setAppElement(`#${rootElementId}`);
  i18n(intl);

  const tagManagerArgs = {
    gtmId
  };

  TagManager.initialize(tagManagerArgs);

  window.onload = function () {
    return <CookieBot domainGroupId={domainGroupId} language={storage?.storage?.i18nextLng || "EN"} />
  }
  render(
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ClearCache>
            {({ isLatestVersion, emptyCacheStorage }) => {
              useEffect(() => {
                if (!isLatestVersion) {
                  console.log("LOG TO CHECK THE REBUILD BEFORE RELOAD PAGE")
                  emptyCacheStorage().then(() => {
                    window.location.reload(true);
                    console.log("LOG TO CHECK THE REBUILD AFTER RELOAD PAGE")
                  });
                }
              }, [isLatestVersion, emptyCacheStorage]);
              return (
                <Suspense fallback={<div />}>
                  <ConfigContext.Provider value={config}>
                    <UtilsContext.Provider value={configuredUtils}>
                      <SessionContext.Provider value={session}>
                        <Routes sendVisitedScreens={reporter.sendPage} />
                      </SessionContext.Provider>
                    </UtilsContext.Provider>
                  </ConfigContext.Provider>
                </Suspense>
              );
            }}
          </ClearCache>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>,
    appElement
  );
}
