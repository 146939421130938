import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { textTransform } from 'utils';

export const NavTabsList = styled.ul.attrs({
  className: 'l-nav-tabs'
})`
  font-size: 0;
  text-align: center;
  box-shadow: 0 2px 4px 0 ${({ theme }) => theme.color.rgbaBlack('.2')};
  border-bottom: 1px solid ${({ theme }) => theme.color.borderDark};
  position: relative;

  &::before {
    content: '';
    width: calc(100% + 10px);
    height: 100%;
    background-color: ${({ theme }) => theme.color.bgPrimary};
    position: absolute;
    left: -5px;
    top: 0;
  }
`;

export const NavTabsLinkBtn = styled(({ isSelected, ...props }) => {
  if (props.to) {
    return <NavLink {...props} />;
  }

  return <button type="button" {...props} />;
}).attrs({
  className: 'l-nav-tabs__link-btn'
})`
  padding: ${({ theme }) => theme.indent.m};
  position: relative;
  transition: background-color 0.2s ease-out;
  cursor: pointer;
  
  &::before {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 3px;
    background-color: ${({ theme }) => theme.color.accent};
    transition: transform 0.2s ease-out, opacity 0.2s ease-in;
    transform: scaleX(0);
    opacity: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.rgbaWhite('.05')};
    &::before {
      opacity: 1;
      transform: scaleX(1);
    }

    & > .l-tabs__item-text {
      color: #fff;
    }
  }

  & > .l-tabs__item-text {
    // font: 700 1.4rem/1 Cabin, sans-serif;
    font: 700 1.4rem/1 'Open Sans', sans-serif;
    color: ${({ theme }) => theme.color.borderLight};
    text-transform: ${textTransform};
    transition: color 0.2s ease-out;
  }

  ${({ theme, isSelected }) =>
    isSelected &&
    `
    pointer-events: none;

    &::before {
      opacity: 1;
      transform: scaleX(1);
    }

    & > .l-tabs__item-text {
      color: ${theme.color.textPrimary};
    }
  `}

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.xs.width}) {
    padding: ${({ theme }) => `${theme.indent.m} ${theme.indent.s}`};
  }
  @media screen and (max-width: 320px) {
    padding: ${({ theme }) => `6px ${theme.indent.s}`};
  }
`;
