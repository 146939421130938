import {
  INFORMATION_LEGAL_REQUEST,
  INFORMATION_LEGAL_SET,
  INFORMATION_IMPRESSUM_REQUEST,
  INFORMATION_IMPRESSUM_SET,
  INFORMATION_B2B_REQUEST,
  INFORMATION_B2B_SET,
  INFORMATION_PRICELIST_REQUEST,
  INFORMATION_PRICELIST_SET,
  INFORMATION_BONUSCARD_REQUEST,
  INFORMATION_BONUSCARD_SET,
  INFORMATION_FAMILYFILMCLUB_REQUEST,
  INFORMATION_FAMILYFILMCLUB_SET,
  INFORMATION_BIRTHDAYROOM_REQUEST,
  INFORMATION_BIRTHDAYROOM_SET,
  INFORMATION_COMPETITIONS_REQUEST,
  INFORMATION_COMPETITIONS_SET,
  SLIDER_CONTENT_REQUEST,
  SLIDER_CONTENT_SUCCESS,
  SEND_QUESTION_FORM_DATA,
  SUCCESS_QUESTION_FORM_DATA,
  REQUEST_COMPETITION_DETAIL,
  REQUEST_COMPETITION_DETAIL_SUCCESS,
  COMPETITION_EVENTS_REQUEST,
  COMPETITION_EVENTS_SUCCESS,
  INFORMATION_FAQ_REQUEST,
  INFORMATION_FAQ_SUCCESS,
  LANDING_INFO_REQUEST,
  LANDING_INFO_SUCCESS,
  HALLTICKET_BOOK_REQUEST,
  HALLTICKET_BOOK_SUCCESS,
  INFORMATION_PRICELIST_GASTRONOMY_REQUEST,
  INFORMATION_PRICELIST_GASTRONOMY_SET,
  FAQS_REGISTRATION_REQUEST,
  FAQS_REGISTRATION_SUCCESS,
  FOOTER_NEWSLETTER_REGISTRATION_REQUEST,
  FOOTER_NEWSLETTER_REGISTRATION_SUCCESS,
  GET_FFCSIGNUP_NOTE_REQUEST,
  GET_FFCSIGNUP_NOTE_SUCCESS,
  GET_MAIN_URL_REQUEST,
  GET_MAIN_URL_SUCCESS
} from './types';

export const legalInfoContentRequest = () => ({
  type: INFORMATION_LEGAL_REQUEST
});
export const setLegalInfoContent = legalInfoContent => ({
  type: INFORMATION_LEGAL_SET,
  payload: {
    legalInfoContent
  }
});

export const impressumContentRequest = () => ({
  type: INFORMATION_IMPRESSUM_REQUEST
});
export const setImpressumContent = impressumContent => ({
  type: INFORMATION_IMPRESSUM_SET,
  payload: {
    impressumContent
  }
});

export const b2bContentRequest = () => ({
  type: INFORMATION_B2B_REQUEST
});
export const setB2bContent = b2bContent => ({
  type: INFORMATION_B2B_SET,
  payload: {
    b2bContent
  }
});

export const priceListContentRequest = paramas => ({
  type: INFORMATION_PRICELIST_REQUEST,
  payload: {
    paramas
  }
});
export const setPriceListContent = priceListContent => ({
  type: INFORMATION_PRICELIST_SET,
  payload: {
    priceListContent
  }
});

export const priceListGastronomyContentRequest = paramas => ({
  type: INFORMATION_PRICELIST_GASTRONOMY_REQUEST,
  payload: {
    paramas
  }
});
export const setPriceListGastronomyContent = priceListContentGastronomy => ({
  type: INFORMATION_PRICELIST_GASTRONOMY_SET,
  payload: {
    priceListContentGastronomy
  }
});

export const bonusCardInfoRequest = () => ({
  type: INFORMATION_BONUSCARD_REQUEST
});

export const setBonusCardInfoContent = bonusCardInfo => ({
  type: INFORMATION_BONUSCARD_SET,
  payload: {
    bonusCardInfo
  }
});

export const familyFilmClubRequest = () => ({
  type: INFORMATION_FAMILYFILMCLUB_REQUEST
});

export const setFamilyFilmClubContent = familyFilmClub => ({
  type: INFORMATION_FAMILYFILMCLUB_SET,
  payload: {
    familyFilmClub
  }
});

export const birthdayRoomRequest = () => ({
  type: INFORMATION_BIRTHDAYROOM_REQUEST
});

export const setBirthdayRoomContent = birthdayRoom => ({
  type: INFORMATION_BIRTHDAYROOM_SET,
  payload: {
    birthdayRoom
  }
});

export const competitionsRequest = () => ({
  type: INFORMATION_COMPETITIONS_REQUEST
});

export const setCompetitionsContent = competitions => ({
  type: INFORMATION_COMPETITIONS_SET,
  payload: {
    competitions
  }
});

export const sliderContentRequest = params => ({
  type: SLIDER_CONTENT_REQUEST,
  payload: { params }
});

export const setSliderContent = slider => ({
  type: SLIDER_CONTENT_SUCCESS,
  payload: {
    slider
  }
});

export const sendQuestionFormData = params => ({
  type: SEND_QUESTION_FORM_DATA,
  payload: { params }
});

export const successForSendFormData = data => ({
  type: SUCCESS_QUESTION_FORM_DATA,
  payload: {
    data
  }
});

export const competitionsDetailRequest = params => ({
  type: REQUEST_COMPETITION_DETAIL,
  payload: { params }
});

export const competitionsDetailSuccess = data => ({
  type: REQUEST_COMPETITION_DETAIL_SUCCESS,
  payload: {
    data
  }
});

export const competitionEventsRequest = () => ({
  type: COMPETITION_EVENTS_REQUEST
});

export const competitionEventsSuccess = competitionEvents => ({
  type: COMPETITION_EVENTS_SUCCESS,
  payload: {
    competitionEvents
  }
});

export const faqInfoRequest = () => ({
  type: INFORMATION_FAQ_REQUEST
});

export const setFaqInfoContent = FaqInfo => ({
  type: INFORMATION_FAQ_SUCCESS,
  payload: {
    FaqInfo
  }
});

export const landingInfoRequest = paramas => ({
  type: LANDING_INFO_REQUEST,
  payload: { paramas }
});

export const setLandingInfoContent = LandingInfo => ({
  type: LANDING_INFO_SUCCESS,
  payload: {
    LandingInfo
  }
});

export const hallTicketRequest = params => ({
  type: HALLTICKET_BOOK_REQUEST,
  payload: { params }
});

export const setHallInfoContent = hallTicket => ({
  type: HALLTICKET_BOOK_SUCCESS,
  payload: { hallTicket }
});


export const sendFAQSRegistrationRequest = body => ({
  type: FAQS_REGISTRATION_REQUEST,
  payload: { body }
});

export const sendFaqsRegistrationSuccess = faqsRegistration => ({
  type: FAQS_REGISTRATION_SUCCESS,
  payload: { faqsRegistration }
});

export const fetchFooterNewsletterRegistration = body => ({
  type: FOOTER_NEWSLETTER_REGISTRATION_REQUEST,
  payload: { body }
});

export const sendFooterNewsLetterRegistrationSuccess = newsLetterRegistration => ({
  type: FOOTER_NEWSLETTER_REGISTRATION_SUCCESS,
  payload: { newsLetterRegistration }
});

export const requestFfcNote = () => ({
  type: GET_FFCSIGNUP_NOTE_REQUEST,
});

export const successFfcNote = ffcNote => (
  {
  type: GET_FFCSIGNUP_NOTE_SUCCESS,
  payload: { ffcNote }
});

export const requestMainUrl = params => ({
  type: GET_MAIN_URL_REQUEST,
  payload: {params},
});

export const successMainUrl = urlData => (
  {
  type: GET_MAIN_URL_SUCCESS,
  payload: { urlData }
});