import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Container, Button, Svg } from 'app/components';
import { Wrapper, Title } from './styles';

const propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired
  }).isRequired
};

const NoInternet = ({ history: { goBack } }) => {
  const [t] = useTranslation();

  return (
    <Wrapper>
      <Container>
        <Title>{t('no_internet.title')}</Title>
        <Svg id="no-internet" />
        <Button isDefault onClick={goBack}>
          {t('no_internet.go_back')}
        </Button>
      </Container>
    </Wrapper>
  );
};

NoInternet.propTypes = propTypes;
export default NoInternet;
