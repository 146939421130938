import {
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  REGISTER_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  RESET_PASSWORD_REQUEST,
  REFRESH_TOKEN_REQUEST,
  ACTIVATE_REQUEST,
  REGISTER_SUCCESS
} from './types';

/**
 * LOGIN
 */
export const loginRequest = (data, notifyForm) => ({
  type: LOGIN_REQUEST,
  payload: {
    data,
    notifyForm
  }
});

/**
 * LOGOUT
 */
export const logoutRequest = () => ({
  type: LOGOUT_REQUEST
});

/**
 * REGISTER
 */
export const registerRequest = (data, notifyForm) => ({
  type: REGISTER_REQUEST,
  payload: { data, notifyForm }
});
export const registerSuccess = request => ({
  type: REGISTER_SUCCESS,
  payload: {
    request
  }
});

/**
 * REFRESH_TOKEN
 */
export const refreshTokenRequest = (type, payload) => ({
  type: REFRESH_TOKEN_REQUEST,
  payload: {
    type,
    payload
  }
});

/**
 * ACTIVATE_REQUEST
 */
export const activateRequest = (token, memberId) => ({
  type: ACTIVATE_REQUEST,
  payload: {
    token,
    memberId
  }
});

/**
 * FORGOT_PASSWORD
 */
export const forgotPasswordRequest = (data, notifyForm) => ({
  type: FORGOT_PASSWORD_REQUEST,
  payload: {
    data,
    notifyForm
  }
});

/**
 * RESET_PASSWORD
 */
export const resetPasswordRequest = (data, redirect, notifyForm) => ({
  type: RESET_PASSWORD_REQUEST,
  payload: {
    data,
    redirect,
    notifyForm
  }
});
