import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Calendar } from 'app/components';
import useUtils from 'app/hooks/useUtils';
import Input from '../Input';

const propTypes = {
  id: PropTypes.string.isRequired,

  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,

  value: PropTypes.instanceOf(Date),
  inputValue: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};

const defaultProps = {
  inputValue: null,
  value: null,
  error: null,
  placeholder: null,
  minDate: null,
  maxDate: null,
};

const InputCalendar = ({
  id,
  value,
  inputValue,
  error,
  onChange,
  placeholder,
  maxDate,
  minDate,
  ...props
}) => {
  const [isOpen, setVisibility] = useState(false);
  const onCloseCalendar = useCallback(() => setVisibility(false), []);
  const onOpenCalendar = useCallback(() => setVisibility(true), []);
  const { getDateWithOffset } = useUtils();

  return (
    <React.Fragment>
      <Input
        {...props}
        readOnly
        id={id}
        value={inputValue}
        defaultValue={inputValue}
        onClick={onOpenCalendar}
        error={error}
        placeholder={placeholder}
        isCalendar
      />

      <Calendar
        value={value || getDateWithOffset()}
        isOpen={isOpen}
        onClose={onCloseCalendar}
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
      />
    </React.Fragment>
  );
};

InputCalendar.propTypes = propTypes;
InputCalendar.defaultProps = defaultProps;
export default InputCalendar;
