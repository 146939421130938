import React from 'react';
import styled, { ThemeConsumer, css } from 'styled-components';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { textTransform } from 'utils';

const propTypes = {
  children: PropTypes.element.isRequired,
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  isSmall: PropTypes.bool,
  isMiddleWidth: PropTypes.bool
};

const defaultProps = {
  title: '',
  isSmall: false,
  isMiddleWidth: false
};

// const Title = styled.div`
//   position: relative;
//   padding: 7px 46px 7px ${({ theme }) => theme.indent.l};
//   border-bottom: ${({ theme }) => theme.color.borderDark} solid 1px;
//   color: ${({ theme }) => theme.color.accent};
//   font-size: 2rem;
//   font-weight: 700;
//   text-transform: capitalize;
// `;

const CloseBtn = styled.button.attrs({
  type: 'button'
})`
margin-bottom:26px;
min-width: 200px;
/* font: 700 1.4rem/1 Cabin,sans-serif; */
font: 700 1.4rem/1 'Open Sans',sans-serif;
text-transform: ${textTransform};
color: #fff;
border-radius: 2em;
padding: 0.79em 1.15em;
background-color: #de1619;
box-shadow: 0 3px 16px 0 rgb(222 22 25 / 16%);
`;

const Content = styled.div`

  label {
    text-align: left;
    font-size: 18px;
  }

  ${props =>
    props.small
      ? css`
          padding: 24px 16px;
        `
      : css`
          // padding: 24px 16px 48px;
          padding: 24px 16px 26px;
        `}

  ${props =>
    props.middleWidth &&
    css`
      text-align: left;
    `}

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
    padding: 16px 16px 24px;
  }
`;

const Modal = ({ children, title, close, isOpen, isSmall, isMiddleWidth }) => (
  <ThemeConsumer>
    {theme => {
      const styles = {
        overlay: {
          minWidth: '320px',
          padding: '0 10px',
          zIndex: 12,
          overflowY: 'auto',
          backgroundColor: theme.color.rgbaBlack(0.5),
          textAlign: 'center'
        },
        content: {
          display: 'inline-block',
          verticalAlign: 'middle',
          width: '100%',
          maxWidth: isMiddleWidth ? 640 : 480,
          margin: '30px 0',
          padding: 0,
          position: 'relative',
          top: 'auto',
          left: 'auto',
          bottom: 'auto',
          right: 'auto',
          borderRadius: theme.indent.s,
          border: 0,
          boxShadow: `0 3px 16px 0 ${theme.color.rgbaBlack(0.5)}`,
          backgroundColor: theme.color.bgPopup
        }
      };

      return (
        <ReactModal isOpen={isOpen} style={styles} onRequestClose={close}>
          <Content small={isSmall} middleWidth={isMiddleWidth}>
            {children}
          </Content>
          <CloseBtn onClick={close}>
            ok
          </CloseBtn>
        </ReactModal>
      );
    }}
  </ThemeConsumer>
);

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;
export default React.memo(Modal);
