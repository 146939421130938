import {
  CINEMA_RENT_INQUIRY_REQUEST_REQUEST,
  CINEMA_RENT_INQUIRY_REQUEST_SUCCESS,
  CINEMA_RENT_REQUEST,
  CINEMA_RENT_SUCCESS
} from './types';

export const sendCinemaRentInquiryRequest = body => ({
  type: CINEMA_RENT_INQUIRY_REQUEST_REQUEST,
  payload: { body }
});

export const sendCinemaRentInquirySuccess = cinemaRentInquiry => ({
  type: CINEMA_RENT_INQUIRY_REQUEST_SUCCESS,
  payload: { cinemaRentInquiry }
});

export const cinemaRentRequest = () => ({
  type: CINEMA_RENT_REQUEST
});

export const cinemaRentSuccess = cinemaRent => ({
  type: CINEMA_RENT_SUCCESS,
  payload: { cinemaRent }
});
