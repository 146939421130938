export const BOOKED_TICKETS_REQUEST = 'bookings/BOOKED_TICKETS_REQUEST';
export const BOOKED_TICKETS_SUCCESS = 'bookings/BOOKED_TICKETS_SUCCESS';
export const BOOKED_TICKETS_CLEAR = 'bookings/BOOKED_TICKETS_CLEAR';

export const CANCEL_RESERVATION_REQUEST = 'bookings/CANCEL_RESERVATION_REQUEST';
export const CANCEL_RESERVATION_SUCCESS = 'bookings/CANCEL_RESERVATION_SUCCESS';
export const CANCEL_RESERVATION_CLEAR = 'bookings/CANCEL_RESERVATION_CLEAR';

export const BUY_RESERVATION_REQUEST = 'bookings/BUY_RESERVATION_REQUEST';
export const BUY_RESERVATION_SUCCESS = 'bookings/BUY_RESERVATION_SUCCESS';

export const BOOKING_REQUEST = 'bookings/BOOKING_REQUEST';
export const BOOKING_SET = 'bookings/BOOKING_SET';
export const BOOKING_CLEAR = 'bookings/BOOKING_CLEAR';

export const QR_CODE_REQUEST = 'bookings/QR_CODE_REQUEST';
export const BAR_CODE_REQUEST = 'bookings/BAR_CODE_REQUEST';
export const CODE_SET = 'bookings/CODE_SET';
export const CODE_CLEAR = 'bookings/CODE_CLEAR';

export const REFUND_TICKETS_REQUEST = 'bookings/REFUND_TICKETS_REQUEST';
export const REFUND_TICKETS_SUCCESS = 'bookings/REFUND_TICKETS_SUCCESS';

export const REFUND_ANONYMOUS_TICKETS_REQUEST = 'bookings/REFUND_ANONYMOUS_TICKETS_REQUEST';
export const REFUND_ANONYMOUS_TICKETS_SUCCESS = 'bookings/REFUND_ANONYMOUS_TICKETS_SUCCESS';

export const BOOKING_REFUND = 'bookings/BOOKING_REFUND';