import React, { useState, useCallback } from 'react';
import { Trigger, Expander } from 'app/components';

export default () => {
  const [isExpanded, setExpand] = useState(false);
  const setExpandCb = useCallback(() => setExpand(!isExpanded), [isExpanded]);

  return {
    Trigger: () => (
      <Trigger isExpanded={isExpanded} onClick={setExpandCb}>
        <span className="_with-chevron" />
      </Trigger>
    ),

    // eslint-disable-next-line
    Expander: ({ children }) => <Expander isExpanded={isExpanded}>{children}</Expander>,

    isExpanded
  };
};
