export default () => ({
  country: 'svn',
  intl: {
    fallbackLng: 'sl',
    locales: [
      {
        locale: 'sl',
        label: 'Slovenija'
      }
    ],
    defaultLocales: [
      {
        locale: 'sl',
        label: 'Slovenija'
      }
    ]
  },
  api: {
    user: {
      workZipCode: 'SVN'
    }
  },

  serverTimeZone: 2,

  footer: {
    copyright: '© Cineplexx.SI d.o.o.',
    apps: {
      android: 'https://play.google.com/store/apps/details?id=sl.cineplexx.app',
      ios: 'https://itunes.apple.com/at/app/cineplexx-sl/id1499639819'
    }
  },

  supportInfo: {
    contactUs: {
      email: 'kino@cineplexx.si'
    }
  },

  form: {
    validation: {
      zip: 4
    }
  },

  wizard: {
    stepTickets: {
      warnings: {
        en: [
          {
            title:
              '<strong>Cineplexx Titan || <span class="_accent">Attention!</span></strong> The 1 + 1 offer is only available at <strong>Cineplexx Titan (Bucharest)</strong> for tickets purchased at the desk, not online!',
            description:
              'Pay at the cinema to receive a bonus ticket for every cinema ticket purchased'
          }
        ],
        de: [
          {
            title:
              '<strong>Cineplexx Titan || <span class="_accent">Atenție!</span></strong> Oferta 1 + 1 este disponibilă doar la <strong>Cineplexx Titan (București)</strong> pentru biletele achiziționate la casele de bilete, nu și online!',
            description:
              'Pentru a beneficia de un bilet bonus pentru fiecare bilet de film cumpărat, plătește la cinema.'
          }
        ]
      }
    },
    stepCheckout: {
      messages: {
        en: [
          ''
        ],
        de: ['']
      }
    }
  },
  showingOptions: {
    member: true,
    giftcards: false,
    streaming: false,
    whatchList: true,
    cinemaRent: true,
    advertising: false,
    newsletter: false,
    newsletterfooter: false,
    newsletterpopup: false,
    wishToReceiveSMS: true,
    contactByThirdParty: true,
    information: true,
    followUs: true,
    myBonusCard: true,
    displayTechnologies: true,
    b2bAdvertising: true,
    privacyPolicy: true,
    weeklynewsletter: true,
    familynewsletter: false,
    operanewsletter: false,
    ageratingvisible: false,
    schoolcinema: true,
    childtickets: true,
    company: {
      press: true,
      portrait: true,
      jobs: false
    },
    myGiftCard: false,
    refundTickets: false,
    club: {
      bonusCard: true,
      familyFilmclub: true,
      competitions: false
    },
    giftCards: {
      giftcardShop: false,
      giftcardInfo: false,

    }
  }
});
