import BaseAPI from '../BaseAPI';

/**
 * Api class for handling sessions
 */
class Sessions extends BaseAPI {
  /**
   * Creates Sessions instance
   *
   * @param props {Object}
   * @return {Sessions}
   */
  constructor(props) {
    super(props);

    this.baseUrl = '/sessions';
  }

  /**
   * Finds session details
   *
   * @param id {string}
   * @return {Promise<*|Error>}
   */
  getSessionById(id) {
    const url = `${this.baseUrl}/${id}`;

    return this.get({ url });
  }
}

export default Sessions;
