import layout from 'app/store/layout';
import seatPlan from 'app/store/seatPlan';
import { handleErrors } from 'app/store/utils';
import sessionSeatPlan from 'app/store/sessionsTickets';
import paymentModule from 'app/store/payment';

const {
  buyRequest,
} = paymentModule.actions;

const { setLoader, createToast, setErrorApi } = layout.actions;
const { setPlan: setSeatPlan } = seatPlan.actions;
const { sessionSeatPlan: setSesseionsSeatPlan } = seatPlan.actions;

export { setLoader, createToast, handleErrors, setErrorApi, setSeatPlan, setSesseionsSeatPlan, buyRequest };
