import styled from 'styled-components';
import { ShadowBoxDark } from 'app/components';

export default styled(ShadowBoxDark).attrs({
  className: 'b-cinema-info'
})`
  display: flex;
  padding: 12px;
  font-size: 0;

  & + .b-session-state {
    margin-top: ${({ theme }) => theme.indent.m};
  }

  .b-cinema-info__icon {
    margin-right: 8px;

    & > .svg-info {
      width: 24px;
      height: 24px;
      fill: ${({ theme }) => theme.color.accent};
    }
  }

  .b-cinema-info__text {
    font-size: 1.6rem;
  }
`;
