import { PORTRAIT_REQUEST, PORTRAIT_SUCCESS } from './types';

export const portraitRequest = () => ({
  type: PORTRAIT_REQUEST
});

export const portraitSuccess = portrait => ({
  type: PORTRAIT_SUCCESS,
  payload: { portrait }
});
