/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable array-callback-return  */
/* eslint-disable jsx-a11y/anchor-is-valid  */
import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { NavLink, generatePath } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, useField } from "react-final-form-hooks";
import classNames from "classnames";
import useConfig from "app/hooks/useConfig";
import bonusCardButoon from "app/assets/images/bonusCardButton.png";
import {
  useUrlParams,
  useEffectFormReset,
  useRouter,
  useWindowSize,
  useModal
} from "app/hooks";
import { Container, Svg, Image, Input, Button } from "app/components";
import { getPreValidationError } from "app/utils";
import withFormValidation from "app/HOC/withFormValidation";
import buttonLegalInfo1 from "./utils/Button1.png";
import buttonLegalInfo2 from "./utils/Button2.png";
import {
  BlockMainWrapper,
  BlockMainLinks,
  ListLinks,
  ListLinksItem,
  LocationItem,
  PopUpButton,
  BonusCardContainer,
  PopUpForm,
  DivMain,
  Title,
  CloseBtn,
  PopupContent,
  NewsLetterForm,
  CardContainer,
  CardImg,
  Card
} from "./styles";
import NewsLetterFormHtml from "./NewsLetterForm";
import { useEffect } from "react";
import BonusModal from "../BonusModal";

const schema = {
  newsLetter: [{ rule: "email" }]
};

const propTypes = {
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  savedLocation: PropTypes.string.isRequired,
  validateWithSchema: PropTypes.func.isRequired,
  saveLocation: PropTypes.func.isRequired,
  newsLetterContent: PropTypes.array.isRequired,
  newsLetterResult: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  barCode: PropTypes.string
};

const moviesToBase = routes => (category, date, location) => ({
  pathname: routes.movies.pattern,
  search: `?category=${category}${location ? `&location=${location}` : ""}${date ? `&date=${date}` : ""
    }`
});

const BlockMain = ({
  locations,
  savedLocation,
  validateWithSchema,
  saveLocation,
  setNewsLetter,
  pathname,
  newsLetterContent,
  newsLetterResult,
  user,
  barCode
}) => {
  const [t, i18n] = useTranslation();
  const [id, setCurrentPath] = useState("");
  const getNewsData = sessionStorage.getItem("newsletter");
  const [popup, setpopup] = useState(
    sessionStorage.getItem("newsletter") === "false" ? true : false
  );
  const [focusOff, setFocusOff] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showSideNewsletterBar, setSideNewsletterBarVisibility] = useState(
    false
  );
  const {
    history: { replace }
  } = useRouter();

  useEffect(() => {
    if (user !== null) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [user]);

  const handleFocus = () => {
    setFocusOff(false);
  };
  let getCurrentPath = window.location.pathname.split("/");
  let popLastPath = getCurrentPath.pop();

  useEffect(() => {
    setCurrentPath(popLastPath);
    if (user && user?.sendNewsletter) {
      setSideNewsletterBarVisibility(true);
    } else {
      setSideNewsletterBarVisibility(false);
    }
  }, [popLastPath, user]);

  const WholePath = [
    "/sign-up",
    "/sign-in",
    "/account/user",
    "/account/tickets",
    "/account/bonus-card",
    "/account/gift-card",
    "/account/history",
    "/account/watch-list",
    `/purchase/payment-failure/${id}`,
    `/purchase/payment-success/${id}`,
    `/movies/${id}`,
    `/cinemas/${id}`,
    `/purchase/reservation-success/${id}`,
    `/purchase/reservation-failure/${id}`,
    `/newsletter/registration/${id}`
  ];
  const bonusCardPath = [
    "/movies",
    "/events",
    "/cinemas",
    "/search",
    "/account/user"
  ];

  const { routes, companyName, footer, showingOptions } = useConfig();
  const { club, giftCards, displayTechnologies, information, newsletter, company, newsletterfooter, newsletterpopup } = showingOptions
  const { press, portrait, jobs } = company
  const { country, cineplexUrl } = useConfig();
  const { date } = useUrlParams("date");
  const { width } = useWindowSize();

  const [cardType, setCardType] = useState(null);
  const [cardTitle, setCardTitle] = useState(null);
  const [isShowAgree, setIsShowAgree] = useState(false);

  const { language } = i18n;

  const [bonusCardfuction, showBonuspopup, hideBonuspopup] = useModal(
    cardTitle
  );

  const sendRegistration = values => {
    const payload = {
      websiteUrl: cineplexUrl,
      email: values?.newsLetter || "",
      locale:
        i18n.language === "en-US"
          ? "en"
          : i18n.language === "de-DE"
            ? "de"
            : i18n.language === "de"
              ? "de"
              : (i18n.language === "en" && "en") || "",
      country: "AUT",
      to: values?.newsLetter || ""
    };
    setNewsLetter(payload);
  };

  useEffect(() => {
    if (user?.memberLevelId === 2) {
      setCardType("memberLevel_2");
      setCardTitle("Bonus Card");
    } else if (user?.memberLevelId === 3) {
      setCardType("memberLevel_3");
      setCardTitle("Bonus Card Black");
    } else if (user?.memberLevelId === 4) {
      setCardType("memberLevel_4");
      setCardTitle("Family Film Club Card");
    } else {
      setCardType(null);
      setCardTitle(null);
    }
  }, [user?.memberLevelId]);

  const {
    form,
    handleSubmit,
    submitErrors: errors,
    submitSucceeded,
    submitting
  } = useForm({
    onSubmit: sendRegistration,
    validate: validateWithSchema
  });

  const newsLetter = useField("newsLetter", form);

  const [newsData, setNewsData] = useState(newsLetterContent?.[0]);

  useEffectFormReset(form, errors, submitSucceeded, submitting);

  const moviesTo = moviesToBase(routes);
  const isLinkActive = searchParam => (match, params) =>
    `${params.pathname}${params.search}`.indexOf(searchParam) >= 0;

  useEffect(() => {
    if (getNewsData === null) {
      setTimeout(() => {
        setIsShowAgree(true);
        if (window.innerWidth > 480) {
          setpopup(true);
        } else {
          sessionStorage.setItem("newsletter", true);
          setIsShowAgree(false);
          setpopup(false);
        }
      }, 10000);
    }

    if (window.innerWidth > 480) {
      setTimeout(() => sessionStorage.setItem("newsletter", true), 5000);
    }
  }, [popup]);

  useEffect(() => {
    if (newsLetterContent?.length > 0) {
      setNewsData(newsLetterContent?.[0]);
    }
  }, [newsLetterContent]);
  const languageContents = useMemo(
    () => ({
      description: language === "en" ? "descriptionEN" : "description",
      button: language === "en" ? "buttonEN" : "button"
    }),
    [language]
  );

  const popupOff = () => {
    if (newsLetter.input.value.length > 1) {
      if (window.innerWidth < 480) {
        sessionStorage.setItem("newsletter", false);
      }
      setpopup(popup ? false : true);
      form.reset();
    }
  };

  const showStreamingFunction = () => {
    form.reset();
    sessionStorage.setItem("newsletter", true);
    setpopup(popup ? false : true);
    setIsShowAgree(isShowAgree => !isShowAgree);
    setFocusOff(false);
  };
  const hideStreamingFunction = () => {
    form.reset();

    // sessionStorage.setItem('newsletter', true)
    if (window.innerWidth < 480 && getNewsData === "true") {
      setpopup(true);
      sessionStorage.setItem("newsletter", false);
    } else {
      setpopup(popup ? false : true);
      // sessionStorage.setItem('newsletter', true);
    }
    setIsShowAgree(false);
    setFocusOff(false);
  };

  useEffect(() => {
    if (newsLetterResult?.sent === true) {
      setIsShowAgree(false);
    }
  }, [newsLetterResult]);

  // useEffect(() => {
  //   window.addEventListener('CookiebotOnAccept', function () {
  //     if (window?.Cookiebot?.consent?.marketing) {
  //       setSideNewsletterBarVisibility(true)
  //     } else {
  //       setSideNewsletterBarVisibility(false)
  //     }
  //   }, false)
  //   window.addEventListener('CookiebotOnDecline', function () {
  //     if (window?.Cookiebot?.consent?.marketing) {
  //       setSideNewsletterBarVisibility(true)
  //     } else {
  //       setSideNewsletterBarVisibility(false)
  //     }
  //   }, false)
  // }, [])

  return (
    <BlockMainWrapper>
      <div className="fotterTop">
        <Container>
          <BlockMainLinks>
            {newsletterfooter && (
              <div className="footer__main-inner-input-wrapper">
                <h5 className="footer__main-links-title">
                  {t("common.newsletter")}
                </h5>
                <h3 className="footer__main-links-subtitle">
                  {t("common.newsletter_subtitle")}
                </h3>
                <div className="footer__main-inner-newsLetter-wrapper">
                  <NewsLetterFormHtml
                    newsLetter={newsLetter}
                    isShowAgree={isShowAgree}
                    focusOff={focusOff}
                    handleSubmit={handleSubmit}
                    type="default"
                  />
                </div>
              </div>
            )}
            <div className="footer__main-inner">
              <h5 className="footer__main-links-title">{t("common.movies")}</h5>
              <ListLinks>
                <ListLinksItem>
                  <NavLink
                    to={moviesTo("top", date, savedLocation)}
                    isActive={isLinkActive("top")}
                    className="l-footer-main__link"
                    activeClassName="_active"
                  >
                    {t("common.top_movies")}
                  </NavLink>
                </ListLinksItem>
                <ListLinksItem>
                  <NavLink
                    to={moviesTo("now", date, savedLocation)}
                    isActive={isLinkActive("now")}
                    className="l-footer-main__link"
                    activeClassName="_active"
                  >
                    {t("common.now_playing")}
                  </NavLink>
                </ListLinksItem>
                <ListLinksItem>
                  <NavLink
                    to={moviesTo("upcoming", "all")}
                    isActive={isLinkActive("upcoming")}
                    className="l-footer-main__link"
                    activeClassName="_active"
                  >
                    {t("common.upcoming")}
                  </NavLink>
                </ListLinksItem>
              </ListLinks>
            </div>

            <div className="footer__main-inner">
              <h5 className="footer__main-links-title">
                {t("common.cinemas")}
              </h5>
              <ListLinks>
                {locations.length ? (
                  locations.map(({ id, name }) => (
                    <ListLinksItem key={name}>
                      <LocationItem
                        role="button"
                        key={id}
                        className={classNames("l-footer-main__link", {
                          _active: id.toString() === savedLocation
                        })}
                        onClick={() => {
                          saveLocation(id);
                          replace({
                            pathname: generatePath(routes.cinemas.pattern),
                            search: `?${date ? `date=${date}` : ""
                              }&location=${id}`
                          });
                        }}
                      >
                        {name}
                      </LocationItem>
                    </ListLinksItem>
                  ))
                ) : (
                  <ListLinksItem>{t("cinemas.no_cinemas")}</ListLinksItem>
                )}
              </ListLinks>
            </div>
            {information && (
              <div className="footer__main-inner-wrapper">
                {club?.bonusCard || club?.familyFilmclub || giftCards?.giftcardInfo || displayTechnologies || process.env.REACT_APP_COUNTRY === "aut" ?
                  <div className="footer__main-inner footer__main-inner-sub">
                    <h5 className="footer__main-links-title">
                      {t("common.information")}
                    </h5>
                    <ListLinks>
                      {displayTechnologies &&
                        <ListLinksItem>
                          <NavLink
                            to={routes.technologies.pattern}
                            isActive={isLinkActive("technologies")}
                            className="l-footer-main__link"
                            activeClassName="_active"
                          >
                            {t("common.technolgies")}
                          </NavLink>
                        </ListLinksItem>
                      }
                      {giftCards?.giftcardInfo &&
                        <ListLinksItem>
                          <NavLink
                            to={routes.giftCardInfo.pattern}
                            isActive={isLinkActive("/info/gift-card")}
                            className="l-footer-main__link"
                            activeClassName="_active"
                          >
                            {t("common.giftcard_info")}
                          </NavLink>
                        </ListLinksItem>
                      }
                      {club?.bonusCard &&
                        <ListLinksItem>
                          <NavLink
                            to={routes.bonusCardInfo.pattern}
                            isActive={isLinkActive("/info/bonus-card")}
                            className="l-footer-main__link"
                            activeClassName="_active"
                          >
                            {t("common.card_info")}
                          </NavLink>
                        </ListLinksItem>
                      }
                      {club?.familyFilmclub &&
                        <ListLinksItem>
                          <NavLink
                            to={routes.familyFilmClub.pattern}
                            isActive={isLinkActive("/info/family-film-club")}
                            className="l-footer-main__link"
                            activeClassName="_active"
                          >
                            {t("common.family_film_club_info")}
                          </NavLink>
                        </ListLinksItem>
                      }
                      {process.env.REACT_APP_COUNTRY === "aut" &&
                        <ListLinksItem>
                          <NavLink
                            to={'/content/dot'}
                            isActive={isLinkActive("/content/dot")}
                            className="l-footer-main__link"
                            activeClassName="_active"
                          >
                            {t("common.dot_magazine")}
                          </NavLink>
                        </ListLinksItem>
                      }
                    </ListLinks>
                  </div>
                  : null}
                {width > 740 ? (
                  <div>
                    {showingOptions?.cinemaRent || showingOptions?.b2bAdvertising || process.env.REACT_APP_COUNTRY === "aut" ?
                      <div className="footer__main-inner">
                        <h5 className="footer__main-links-title">
                          {t("common.b2b")}
                        </h5>
                        <ListLinks>
                          {showingOptions?.cinemaRent &&
                            <ListLinksItem>
                              <NavLink
                                to={routes.cinemaRent.pattern}
                                isActive={isLinkActive("/cinema-rent")}
                                className="l-footer-main__link"
                                activeClassName="_active"
                              >
                                {t("common.cinema_rent")}
                              </NavLink>
                            </ListLinksItem>
                          }
                          {showingOptions?.b2bAdvertising &&
                            <ListLinksItem>
                              <NavLink
                                to={routes.cinemaAdvertising.pattern}
                                isActive={isLinkActive("/cinema-advertising")}
                                className="l-footer-main__link"
                                activeClassName="_active"
                              >
                                {t("common.cinema_advertising")}
                              </NavLink>
                            </ListLinksItem>
                          }
                          {showingOptions?.schoolcinema && (
                            <ListLinksItem>
                              <NavLink
                                to={routes.schoolCinema.pattern}
                                isActive={isLinkActive("/schulkino")}
                                className="l-footer-main__link"
                                activeClassName="_active"
                              >
                                {t("common.school_cinema")}
                              </NavLink>
                            </ListLinksItem>
                          )}
                        </ListLinks>
                      </div>
                      : null}
                  </div>
                ) : (
                  <div className="footer__main-inner footer__main-inner-sub">
                    <h5 className="footer__main-links-title">
                      {t("common.follow_us")}
                    </h5>
                    <ListLinks>
                      {process.env.REACT_APP_FACEBOOK_URL && (
                        <ListLinksItem>
                          <a
                            href={process.env.REACT_APP_FACEBOOK_URL}
                            className="l-footer-main__link"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {t("common.facebook")}
                          </a>
                        </ListLinksItem>
                      )}
                      {process.env.REACT_APP_INSTAGRAM_URL && (
                        <ListLinksItem>
                          <a
                            href={process.env.REACT_APP_INSTAGRAM_URL}
                            className="l-footer-main__link"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {t("common.instagram")}
                          </a>
                        </ListLinksItem>
                      )}
                      {process.env.REACT_APP_YOUTUBE_URL && (
                        <ListLinksItem>
                          <a
                            href={process.env.REACT_APP_YOUTUBE_URL}
                            className="l-footer-main__link"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {t("common.youtube")}
                          </a>
                        </ListLinksItem>
                      )}
                      {process.env.REACT_APP_TIKTOK_URL && (
                        <ListLinksItem>
                          <a
                            href={process.env.REACT_APP_TIKTOK_URL}
                            className="l-footer-main__link"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {t("common.tiktok")}
                          </a>
                        </ListLinksItem>
                      )}
                      {process.env.REACT_APP_LINKEDIN_URL && (
                        <ListLinksItem>
                          <a
                            href={process.env.REACT_APP_LINKEDIN_URL}
                            className="l-footer-main__link"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {t("common.linkedIn")}
                          </a>
                        </ListLinksItem>
                      )}
                    </ListLinks>
                  </div>
                )}
              </div>
            )}

            {showingOptions?.followUs && (
              <div className="footer__main-inner-wrapper">
                {width > 740 ? (
                  <div className="footer__main-inner-sub footer__main-inner-sub">
                    <h5 className="footer__main-links-title">
                      {t("common.follow_us")}
                    </h5>
                    <ListLinks>
                      {process.env.REACT_APP_FACEBOOK_URL && (
                        <ListLinksItem>
                          <a
                            href={process.env.REACT_APP_FACEBOOK_URL}
                            className="l-footer-main__link"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {t("common.facebook")}
                          </a>
                        </ListLinksItem>
                      )}
                      {process.env.REACT_APP_INSTAGRAM_URL && (
                        <ListLinksItem>
                          <a
                            href={process.env.REACT_APP_INSTAGRAM_URL}
                            className="l-footer-main__link"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {t("common.instagram")}
                          </a>
                        </ListLinksItem>
                      )}
                      {process.env.REACT_APP_YOUTUBE_URL && (
                        <ListLinksItem>
                          <a
                            href={process.env.REACT_APP_YOUTUBE_URL}
                            className="l-footer-main__link"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {t("common.youtube")}
                          </a>
                        </ListLinksItem>
                      )}
                      {process.env.REACT_APP_TIKTOK_URL && (
                        <ListLinksItem>
                          <a
                            href={process.env.REACT_APP_TIKTOK_URL}
                            className="l-footer-main__link"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {t("common.tiktok")}
                          </a>
                        </ListLinksItem>
                      )}
                      {process.env.REACT_APP_LINKEDIN_URL && (
                        <ListLinksItem>
                          <a
                            href={process.env.REACT_APP_LINKEDIN_URL}
                            className="l-footer-main__link"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {t("common.linkedIn")}
                          </a>
                        </ListLinksItem>
                      )}
                    </ListLinks>
                  </div>
                ) : (
                  <div className="footer__main-inner-sub">
                    <div className="footer__main-inner">
                      <h5 className="footer__main-links-title">
                        {t("common.b2b")}
                      </h5>
                      <ListLinks>
                        <ListLinksItem>
                          <NavLink
                            to={routes.cinemaRent.pattern}
                            isActive={isLinkActive("`")}
                            className="l-footer-main__link"
                            activeClassName="_active"
                          >
                            {t("common.cinema_rent")}
                          </NavLink>
                        </ListLinksItem>
                        <ListLinksItem>
                          <NavLink
                            to={routes.cinemaAdvertising.pattern}
                            isActive={isLinkActive("`")}
                            className="l-footer-main__link"
                            activeClassName="_active"
                          >
                            {t("common.cinema_advertising")}
                          </NavLink>
                        </ListLinksItem>
                        <ListLinksItem>
                          <NavLink
                            to={routes.schoolCinema.pattern}
                            isActive={isLinkActive("/schulkino")}
                            className="l-footer-main__link"
                            activeClassName="_active"
                          >
                            {t("common.school_cinema")}
                          </NavLink>
                        </ListLinksItem>
                      </ListLinks>
                    </div>
                  </div>
                )}
                <div className="footer__main-inner-sub">
                  {press || portrait || jobs ?
                    <div className="footer__main-inner">
                      <h5 className="footer__main-links-title">
                        {t("common.company")}
                      </h5>
                      <ListLinks>
                        {press &&
                          <ListLinksItem>
                            <NavLink
                              to={routes.cinemaAdvertising.pattern}
                              isActive={isLinkActive("/cinema-advertising")}
                              className="l-footer-main__link"
                              activeClassName="_active"
                            >
                              {t("common.press")}
                            </NavLink>
                          </ListLinksItem>
                        }
                        {portrait &&
                          <ListLinksItem >
                            <NavLink
                              to={routes.portrait.pattern}
                              isActive={isLinkActive("/portrait")}
                              className="l-footer-main__link"
                              activeClassName="_active"
                            >
                              {t("common.portrait")}
                            </NavLink>
                          </ListLinksItem>
                        }
                        {jobs &&
                          <ListLinksItem>
                            <NavLink
                              to={routes.jobs.pattern}
                              isActive={isLinkActive("/jobs")}
                              className="l-footer-main__link"
                              activeClassName="_active"
                            >
                              {t("common.jobs")}
                            </NavLink>
                          </ListLinksItem>
                        }
                      </ListLinks>
                    </div>
                    : null}
                </div>
              </div>
            )}

            <div className="footer__main-inner _store-links" id="divid">
              <h5 className="footer__main-links-title">{`${companyName} apps`}</h5>
              <ListLinks withSvg>
                <ListLinksItem withSvg>
                  <a
                    href={footer.apps.ios}
                    target="_blank"
                    className="l-footer-main__link"
                    rel="noopener noreferrer"
                  >
                    <Svg id="appstore" />
                  </a>
                </ListLinksItem>
                <ListLinksItem withSvg>
                  <a
                    href={footer.apps.android}
                    target="_blank"
                    className="l-footer-main__link"
                    rel="noopener noreferrer"
                  >
                    <Svg id="playstore" />
                  </a>
                </ListLinksItem>
              </ListLinks>
              {country === "rou" && (
                <ListLinks withSvg>
                  <ListLinksItem withSvg>
                    <a
                      href={footer.buttons.legalInfo1}
                      target="_blank"
                      className="l-footer-main__link"
                      rel="noopener noreferrer"
                    >
                      <Image src={buttonLegalInfo1} className="_button-white" />
                    </a>
                  </ListLinksItem>
                  <ListLinksItem withSvg>
                    <a
                      href={footer.buttons.legalInfo2}
                      target="_blank"
                      className="l-footer-main__link"
                      rel="noopener noreferrer"
                    >
                      <Image src={buttonLegalInfo2} className="_button-white" />
                    </a>
                  </ListLinksItem>
                </ListLinks>
              )}
            </div>
            {/* {country === "rou" && (
              <div
                style={{ marginTop: "22px" }}
                className="footer__main-inner _store-links"
              >
                <ListLinks>
                  <ListLinksItem>
                    <a
                      href={footer.buttons.legalInfo1}
                      target="_blank"
                      className="l-footer-main__link"
                      rel="noopener noreferrer"
                    >
                      <Image src={buttonLegalInfo1} className="_button-white" />
                    </a>
                  </ListLinksItem>
                  <ListLinksItem>
                    <a
                      href={footer.buttons.legalInfo2}
                      target="_blank"
                      className="l-footer-main__link"
                      rel="noopener noreferrer"
                    >
                      <Image src={buttonLegalInfo2} className="_button-white" />
                    </a>
                  </ListLinksItem>
                </ListLinks>
              </div>
            )} */}
          </BlockMainLinks>
        </Container>
        {/* NewsLetter popup box*/}
        {WholePath.some(x => x === pathname) === false &&
          !showSideNewsletterBar && newsletterpopup && (
            <div
              className={`popup-container ${popup ? "mobilePopup" : "offPopup"
                }`}
            >
              {getNewsData && (
                <div className="newsLetterPosition">
                  <PopUpForm
                    className={`newsPopup ${isShowAgree ? " showPopUp " : " noPopUp"
                      }`}
                  >
                    <DivMain>
                      <Title>
                        <CloseBtn
                          onClick={hideStreamingFunction}
                          onMouseOver={handleFocus}
                        >
                          <Svg id="close" />
                        </CloseBtn>
                      </Title>
                      <PopupContent
                        dangerouslySetInnerHTML={{
                          __html: newsData?.[languageContents.description]
                        }}
                      />
                      <NewsLetterForm>
                        <Input
                          id="newsLetter"
                          type="email"
                          autoFocus={focusOff}
                          {...newsLetter.input}
                          onMouseOver={() => setFocusOff(true)}
                          placeholder={t("common.newsletter_placeholder")}
                          className="footer__main-inner-newsLetter-input"
                          error={getPreValidationError(newsLetter, t)}
                        />
                        <Button
                          className="footer__main-inner-newsLetter-subscribe"
                          isPrimary
                          onClick={() => {
                            handleSubmit();
                            popupOff();
                          }}
                          // onMouseOver={handleFocus}
                          disabled={getPreValidationError(newsLetter, t)}
                        >
                          {newsData?.[languageContents.button]}
                        </Button>
                      </NewsLetterForm>
                    </DivMain>
                  </PopUpForm>
                </div>
              )}
              <PopUpButton
                className={popup ? "newsBtn" : "newsBtn offButton"}
                onClick={showStreamingFunction}
              >
                {newsData?.[languageContents.button]}
              </PopUpButton>
            </div>
          )}
      </div>
      {
        width < 480 && (
          <BonusModal
            isOpen={bonusCardfuction}
            close={hideBonuspopup}
            title={cardTitle}
          >
            <CardContainer>
              {user?.memberLevelId === 2 ? (
                <div className="commanCss logoSvgCapWhite"></div>
              ) : (
                <div className="commanCss logoSvgCap"></div>
              )}
              <div className={cardType}></div>
              <CardImg className="cardImg"></CardImg>

              <Card
                className={user?.memberLevelId !== 4 ? "card otherCard" : "card"}
              >
                <div></div>
                <div className="barcode-container">
                  <div className="barcode">
                    <img src={barCode} alt="barcode-logo" />
                  </div>
                  <div className="barcode-number">{user?.cardNumber}</div>
                </div>
                <div
                  className={
                    user?.memberLevelId === 4
                      ? "black username"
                      : "white username"
                  }
                >
                  {user?.fullName}
                </div>
              </Card>
            </CardContainer>
          </BonusModal>
        )
      }
      {
        width < 480 &&
        bonusCardPath.includes(pathname) &&
        isLoggedIn &&
        user?.memberLevelId &&
        cardType &&
        cardTitle && (
          <BonusCardContainer className="bonuscard_container">
            <div className="bonuscardButton">
              <img
                src={bonusCardButoon}
                onClick={showBonuspopup}
                alt="bonus-button-logo"
              />
            </div>
          </BonusCardContainer>
        )
      }
    </BlockMainWrapper >
  );
};

BlockMain.propTypes = propTypes;
export default withFormValidation(schema)(BlockMain);
