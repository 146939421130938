/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable array-callback-return  */
/* eslint-disable jsx-a11y/anchor-is-valid  */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm, useField } from 'react-final-form-hooks';
import { useTranslation } from 'react-i18next';
import { Container, TitleAccent, Label, Input, Button, Modal, ModalTypical } from 'app/components';
import { getPreValidationError, getPostValidationError } from 'app/utils';
import { useModal, useEffectFormReset } from 'app/hooks';
import { Background, Title } from '../styles';
import { RefundModalContent } from '../../account/Tickets/styles';
import { Page, RefundTicketsForm } from './styles';

const propTypes = {
  refunded: PropTypes.bool,
  validateWithSchema: PropTypes.func.isRequired,
  refundTicket: PropTypes.func.isRequired
};

const defaultProps = {
  refunded: false
};

const RefundTickets = ({ validateWithSchema, refundTicket }) => {
  const [t] = useTranslation();
  const refundCalled = useRef(false);

  const { refunded } = useSelector(state => state.bookings);

  const [validationError, setValidationError] = useState('');

  const [isShowRefund, showRefund, hideRefund] = useModal('attention');
  const [isShowError, showError, hideError] = useModal('errorConfirm');
  const [isShowSuccess, showSuccess, hideSuccess] = useModal('success');

  const sendRefund = useCallback(({ bookingNumber, email }, form, notifyErrors) => {
    refundTicket(bookingNumber, email, notifyErrors);
  }, []);

  const { form, handleSubmit, submitErrors: errors, submitSucceeded, submitting } = useForm({
    onSubmit: sendRefund,
    validate: validateWithSchema
  });

  const bookingNumber = useField('bookingNumber', form);
  const email = useField('email', form);

  useEffect(() => {
    if (refunded) {
      showSuccess();
    } else if (!refunded && refundCalled.current) {
      showError();
    }
  }, [refunded]);

  useEffect(() => {
    if (errors?.message) {
      setValidationError(errors?.message);
    }
  }, [errors?.message]);

  useEffectFormReset(form, errors, submitSucceeded, submitting);

  const handleRefund = () => {
    hideRefund();
    refundCalled.current = true;
    handleSubmit();
  };

  return (
    <Background>
      <Container>
        <Page>
          <Title>{t('common.refund_of_tickets')}</Title>
          <RefundTicketsForm>
            <TitleAccent>{t('common.needed_information')}:</TitleAccent>
            <div className="f-refund-tickets__form-wrap">
              <div className="f-refund-tickets__content">
                <div className="f-default__field bookingnumber">
                  <Label htmlFor={bookingNumber.input.name}>{t('common.booking_number')}</Label>
                  <Input
                    {...bookingNumber.input}
                    id={bookingNumber.input.name}
                    error={
                      getPreValidationError(bookingNumber, t) ||
                      getPostValidationError(errors, bookingNumber.input.name, t)
                    }
                  />
                </div>
                <div className="f-default__field email">
                  <Label htmlFor={email.input.name}>{t('form.email')}</Label>
                  <Input
                    {...email.input}
                    id={email.input.name}
                    error={
                      getPreValidationError(email, t) ||
                      getPostValidationError(errors, email.input.name, t)
                    }
                  />
                </div>
                <Button type="submit" isPrimary onClick={showRefund}>
                  {t('common.refund_tickets')}
                </Button>
              </div>
            </div>
          </RefundTicketsForm>
        </Page>
        <Modal
          isOpen={isShowRefund}
          title={t('purchase.refund_confirm_modal.title')}
          close={hideRefund}
        >
          <RefundModalContent>
            <div className="refund_modal__modal_message">
              <h3>{t('purchase.refund_confirm_modal.label')}</h3>
              <h3>{t('purchase.refund_confirm_modal.info')}</h3>
            </div>
            <div className="refund_modal__modal_btns">
              <Button isDefault onClick={hideRefund}>
                {t('common.cancel')}
              </Button>
              <Button isPrimary onClick={handleRefund}>
                {t('common.ok')}
              </Button>
            </div>
          </RefundModalContent>
        </Modal>
        <Modal
          isSmall
          isOpen={isShowError}
          close={hideError}
          title={t('purchase.refund_failed_modal.title')}
        >
          <ModalTypical
            iconId="cross-circled"
            title={t('purchase.refund_failed_modal.label')}
            message={
              <span>
                <span>{validationError}</span>
              </span>
            }
          >
            <Button
              style={{ width: '200px' }}
              href={`mailto:${email}`}
              as="a"
              isPrimary
              onClick={hideError}
            >
              {t('common.ok')}
            </Button>
          </ModalTypical>
        </Modal>
        <Modal
          isSmall
          isOpen={isShowSuccess}
          close={hideSuccess}
          title={t('purchase.refund_success_modal.title')}
        >
          <ModalTypical
            iconId="complete"
            title={t('purchase.refund_success_modal.others_label', {
              which: refunded
            })}
            message={t('purchase.refund_success_modal.cbc_info')}
          >
            <Button style={{ width: '200px' }} isPrimary onClick={hideSuccess}>
              {t('common.ok')}
            </Button>
          </ModalTypical>
        </Modal>
      </Container>
    </Background>
  );
};

RefundTickets.propTypes = propTypes;
RefundTickets.defaultProps = defaultProps;

export default RefundTickets;
