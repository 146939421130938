import { catchError, mergeMap, concatMap, startWith } from 'rxjs/operators';
import { from } from 'rxjs';
import { ofType } from 'redux-observable';

import { setLoader, handleErrors, setError } from './deps';
import { SURVEY_REQUEST } from './types';
import { surveySuccess } from './actions';

const fetchSurvey = ($action, $state, { api }) => {
  const $apiSurvey = api.getModuleByName('survey');

  return $action.pipe(
    ofType(SURVEY_REQUEST),
    mergeMap(action => {
      return from($apiSurvey.fetchIframeData()).pipe(
        concatMap(res => [surveySuccess(res), setLoader(false)]),

        ...handleErrors(action),

        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      );
    })
  );
};

export default fetchSurvey;
