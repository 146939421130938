import { loadScript } from 'utils';

window.dataLayer = window.dataLayer || [];

function gtag() {
  // eslint-disable-next-line
  window.dataLayer.push(arguments);
}

class GoogleAnalytics {
  constructor({ id, date }) {
    this.url = `https://www.googletagmanager.com/gtag/js?id=${id}`;
    this.id = id;
    this.ga = gtag;

    this.init(date);
  }

  init(date) {
    loadScript(this.url).then(() => {
      this.ga('js', date);
    });
  }

  sendPage(title, path) {
    this.ga('config', this.id, {
      page_title: title,
      page_path: path
    });
  }

  sendEvent(name, params) {
    this.ga('event', name, params);
  }
}

export default GoogleAnalytics;
