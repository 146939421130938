import { mergeMap, startWith, concatMap, catchError } from 'rxjs/operators';
import { from } from 'rxjs';
import { ofType } from 'redux-observable';
import { setLoader, handleErrors, setError } from './deps';
import { BIRTHDAY_ROOM_REQUEST, FAMILY_FILM_CLUB_REQUEST } from './types';
import { successBirthdayRoom, successFamilyFilmClub } from './actions';

function fetchBirthdayRoomContent($action, $state, { api }) {
  const $apiFamilyFilmClub = api.getModuleByName('familyFilmClub');

  return $action.pipe(
    ofType(BIRTHDAY_ROOM_REQUEST),
    mergeMap(action =>
      from($apiFamilyFilmClub.fetchBirthdayRoomContent()).pipe(
        concatMap(res => [successBirthdayRoom(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      )
    )
  );
}

export function fetchFamilyFilmClubContent($action, $state, { api }) {
  const $apiFamilyFilmClub = api.getModuleByName('familyFilmClub');

  return $action.pipe(
    ofType(FAMILY_FILM_CLUB_REQUEST),
    mergeMap(action =>
      from($apiFamilyFilmClub.fetchAll()).pipe(
        concatMap(res => [successFamilyFilmClub(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      )
    )
  );
}

export default fetchBirthdayRoomContent;
