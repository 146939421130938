import BaseAPI from '../BaseAPI';

class Barcode extends BaseAPI {
  constructor(props) {
    super(props);

    this.baseUrl = '/barcode';
  }

  /**
   * Fetches barcode
   * @param code
   * @return {Promise<*|Error>}
   */
  fetchBarcode(code) {
    const url = `${this.baseUrl}/${code}`;

    return this.get({ url });
  }

  /**
   * Fetches QR code
   * @param code
   * @return {Promise<*|Error>}
   */
  fetchQRCode(code) {
    const url = `${this.baseUrl}/qr/${code}`;

    return this.get({ url });
  }
}

export default Barcode;
