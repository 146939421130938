import React from 'react';
import PropTypes from 'prop-types';
import Svg from '../Svg';
import { Container, Page, PageContainer, AllRight, Right } from './styles';

const propTypes = {
  offset: PropTypes.number.isRequired,
  setOffset: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
};

const defaultProps = {};

const Pagination = ({ offset, setOffset, limit, total }) => {
  // const from = Math.min(offset + 1, total);
  // const to = Math.min(offset + limit, total);
  const pageCount = Math.ceil(total / limit);
  const currentPage = offset / limit + 1;
  const highestPossibleOffset = limit * (pageCount - 1);
  const pageArray = [-2, -1, 0, 1, 2]
    .map(v => currentPage + v)
    .filter(page => page > 0 && page <= pageCount);

  return (
    <Container>
      {total > 0 && (
        <PageContainer>
          {/* <Left onClick={() => setOffset(0)}>
            <Svg id="double-right-arrow" />
          </Left>
          <AllLeft onClick={() => setOffset((prev) => Math.max(prev - limit, 0))}>
            <Svg id="right-arrow" />
          </AllLeft> */}
          {!pageArray.includes(1) && (
            <>
              <Page
                isActive={currentPage === 1}
                onClick={() => {
                  setOffset(0);
                }}
              >
                1
              </Page>
              <div>...</div>
            </>
          )}
          {pageArray.map(page => {
            return (
              <Page
                isActive={page === currentPage}
                onClick={() => {
                  setOffset(limit * (page - 1));
                }}
              >
                {page}
              </Page>
            );
          })}
          {!pageArray.includes(pageCount) && (
            <>
              <div className="page-conainer__dots">...</div>
              <Page
                isActive={currentPage === pageCount}
                onClick={() => {
                  setOffset(highestPossibleOffset);
                }}
              >
                {pageCount}
              </Page>
            </>
          )}
          {pageArray?.length > 1 && (
            <>
              <AllRight
                onClick={() => setOffset(prev => Math.min(prev + limit, highestPossibleOffset))}
              >
                <Svg id="right-arrow" />
              </AllRight>
              <Right onClick={() => setOffset(highestPossibleOffset)}>
                <Svg id="double-right-arrow" />
              </Right>
            </>
          )}
        </PageContainer>
      )}
    </Container>
  );
};

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;

export default Pagination;
