import React from 'react';
import PropTypes from 'prop-types';
import { getDisplayNameHOC } from 'app/utils';

const propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

export const BEFORE_ROUTE_TRANSITION = 'beforeRouteTransition';
export const AFTER_ROUTE_TRANSITION = 'afterRouteTransition';

const emit = event => window.dispatchEvent(event);

const withRouteEvents = WrappedComponent =>
  class extends React.Component {
    static propTypes = propTypes;

    static displayName = `withRouteEvents(${getDisplayNameHOC(WrappedComponent)})`;

    shouldComponentUpdate(nextProps) {
      const { location } = this.props;

      const event = new CustomEvent(BEFORE_ROUTE_TRANSITION, {
        detail: {
          currentLocation: location,
          nextLocation: nextProps.location
        }
      });

      emit(event);

      return true;
    }

    componentDidUpdate(prevProps) {
      const { location } = this.props;

      if (location.pathname !== prevProps.location.pathname) {
        const event = new CustomEvent(AFTER_ROUTE_TRANSITION, {
          detail: {
            currentLocation: location,
            prevLocation: prevProps.location
          }
        });

        emit(event);
        return true;
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

export default withRouteEvents;
