import layout from 'app/store/layout';
import { handleErrors, separateErrors, authErrorHandleAfterManualCatch } from 'app/store/utils';

const { setLoader, createToast, setErrorApi, setError } = layout.actions;

export {
  setLoader,
  handleErrors,
  createToast,
  setErrorApi,
  separateErrors,
  authErrorHandleAfterManualCatch,
  setError
};
