import {
  BIRTHDAY_ROOM_REQUEST,
  BIRTHDAY_ROOM_SUCCESS,
  FAMILY_FILM_CLUB_REQUEST,
  FAMILY_FILM_CLUB_SUCCESS
} from './types';

export const requestBirthdayRoom = () => ({
  type: BIRTHDAY_ROOM_REQUEST
});

export const successBirthdayRoom = birthdayRoom => ({
  type: BIRTHDAY_ROOM_SUCCESS,
  payload: { birthdayRoom }
});

export const requestFamilyFilmClub = () => ({
  type: FAMILY_FILM_CLUB_REQUEST
});

export const successFamilyFilmClub = familyFilmClub => ({
  type: FAMILY_FILM_CLUB_SUCCESS,
  payload: { familyFilmClub }
});
