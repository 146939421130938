import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import validateFn from 'app/services/validator';
import ConfigContext from 'app/context/config';

const propTypes = {
  t: PropTypes.func.isRequired
};

const getDisplayName = Component => Component.displayName || Component.name || 'Component';

const withFormValidation = schema => WrappedComponent => {
  class Inner extends React.Component {
    static displayName = `withFormValidation(${getDisplayName(WrappedComponent)})`;

    constructor(props) {
      super(props);

      this.state = {
        validate: null
      };
    }

    componentDidMount() {
      const { t } = this.props;
      const {
        form: { validation }
      } = this.context;
      const scheme = typeof schema === 'function' ? schema(t, validation) : schema;

      this.setState({
        validate: validateFn(scheme)
      });
    }

    render() {
      const { validate } = this.state;

      return validate ? <WrappedComponent validateWithSchema={validate} {...this.props} /> : null;
    }
  }

  Inner.propTypes = propTypes;
  Inner.contextType = ConfigContext;

  return withTranslation()(Inner);
};

export default withFormValidation;
