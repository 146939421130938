import {
  REQUEST_WATCHLIST_ADD_MOVIE,
  REQUEST_WATCHLIST_ADD_EVENT,
  REQUEST_WATCHLIST_REMOVE_MOVIE,
  REQUEST_WATCHLIST_REMOVE_EVENT,
  SET_WATCHLIST,
  SET_WATCHLIST_MOVIES,
  SET_WATCHLIST_EVENTS,
  SET_WATCHLIST_ERROR,
  RESET_WATCHLIST
} from './types';

const initialState = {
  watchlist: {
    movies: [],
    events: []
  },
  movies: [],
  events: [],
  errorId: null,
  errorData: {}
};

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case REQUEST_WATCHLIST_ADD_MOVIE:
    case REQUEST_WATCHLIST_ADD_EVENT:
    case REQUEST_WATCHLIST_REMOVE_MOVIE:
    case REQUEST_WATCHLIST_REMOVE_EVENT: {
      return {
        ...state,
        errorId: null
      };
    }

    case SET_WATCHLIST: {
      return {
        ...state,
        watchlist: payload.watchlist
      };
    }

    case SET_WATCHLIST_MOVIES: {
      return {
        ...state,
        movies: payload.movies
      };
    }

    case SET_WATCHLIST_EVENTS: {
      return {
        ...state,
        events: payload.events
      };
    }

    case SET_WATCHLIST_ERROR: {
      return {
        ...state,
        errorId: payload.movieId,
        errorData: payload.errorData
      };
    }

    case RESET_WATCHLIST: {
      return {
        watchlist: {
          movies: [],
          events: []
        },
        movies: [],
        events: [],
        errorId: null,
        errorData: {}
      };
    }

    default: {
      return state;
    }
  }
}
