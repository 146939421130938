import styled from 'styled-components';
import { textTransform } from 'utils';

export default styled.div.attrs({
  className: 'b-title-with-poster'
})`
  display: flex;
  align-items: center;

  & + .carousel {
    margin-top: ${({ theme }) => theme.indent.m};
  }

  .b-image-with-loader {
    flex-shrink: 0;
    width: 100px;
    margin-right: ${({ theme }) => theme.indent.m};

    .l-entity & {
      width: 85px;
    }
  }
  .b-title-with-poster__inner{
    z-index: 1;
  }
  .b-title-with-poster__title {
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 1.17;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.color.textPrimary};
    text-transform: ${textTransform};

    .l-entity & {
      font-size: 2rem;
    }
  }
  .mobile-view-poster{
    position: relative;
    /* z-index: 99; */
    display: flex;
    align-items: center;
    bottom: 165px;
  }
  .mobile-view-poster,.b-title-with-poster__genre,.b-title-with-poster__duration {
    font-size:24px;
    bottom:4px;
  }
  .desktop-view-poster{
    display:flex;
    align-items:center;
    }
  .b-title-with-poster__genre {
    /* margin-top: 6px; */
    margin-top: 2px;
  }

  .b-title-with-poster__duration {
    /* margin-top: 5px; */
    margin-top: 2px;
  }
  .b-title-with-poster__comma{
    display: none;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    .b-title-with-poster__title {
      font-size: 2rem;
  }
  @media screen and (max-width: 774px){
    .mobile-view-poster {
      bottom:4px !important;
    }
  }
  @media screen and (max-width: 550px) {
    .mobile-view-poster {
      bottom:4px !important;
    }
  }
    /* .b-title-with-poster{
    position: fixed;
    width: 100%;
    z-index: 10;
    top: 140px;
    left: 0;
    } */
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.xs.width}) {
    .b-image-with-loader {
      width: 75px;
    }

    .b-title-with-poster__title {
      font-size: 1.6rem;
    }

    .b-title-with-poster__genre {
      font-size: 1.4rem;
    }

    .b-title-with-poster__duration {
      font-size: 1.4rem;
    }
    .b-title-with-poster__comma{
      display: block;
    }
    .b-title-with-poster__duration-genere-group{
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
  }
`;
