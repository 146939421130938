import React from 'react';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useConfig, useRouter } from 'app/hooks';
import { Svg } from 'app/components';
import { TicketsList, TicketsListItem, TicketsListItemLink } from './styles';

const propTypes = {
  tickets: PropTypes.arrayOf(
    PropTypes.shape({
      screenName: PropTypes.string,
      sessionId: PropTypes.string.isRequired,
      showTime: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      technologies: PropTypes.string.isRequired,
      tech: PropTypes.array.isRequired
    }).isRequired
  ).isRequired
};

const TicketsListComponent = ({ tickets, isAboId, aboId }) => {
  const [t] = useTranslation();
  const { routes } = useConfig();
  const {
    location: { pathname, search }
  } = useRouter();

  return (
    <TicketsList>
      {tickets.map(ticket => {
        const { status, showTime, screenName, sessionId } = ticket;
        let svgId;

        let itemTitle;

        if (status === 'yellow') {
          svgId = 'tickets-noreserve';
          itemTitle = t('movies.only_online');
        } else if (status === 'red') {
          svgId = 'tickets-desk';
          itemTitle = t('movies.only_desk');
        } else if (status === 'grey') {
          svgId = 'tickets-locked';
          itemTitle = t('movies.locked');
        }
        return (
          <TicketsListItem key={sessionId} title={itemTitle}>
            <TicketsListItemLink
              to={{
                pathname: generatePath(routes.wizard.pattern, { movieCinemaId: sessionId }),
                state: {
                  route: `${pathname}${search}`,
                  isAboId: isAboId ? isAboId : null,
                  aboId: aboId ? aboId : null,
                }
              }}
              status={status}
            >
              <div className="l-tickets__inner-top">
                {svgId ? <Svg id={svgId} /> : null}
                <p className="l-tickets__item-time">{showTime}</p>
              </div>
              <div className="l-tickets__inner-bottom">
                <p className="l-tickets__item-cinema">{screenName}</p>
                <p className="l-tickets__item-info">{ticket?.tech?.[0]?.join(', ')}</p>
                <p className="b-entity-content__link" style={{ color: 'red' }}>
                  {ticket?.tech?.[1]?.join(', ')}
                </p>
                <p className="l-tickets__item-info">{ticket?.tech?.[2]}</p>
                {/* {technologies ? <p className="l-tickets__item-info">{technologies}</p> : null} */}
              </div>
            </TicketsListItemLink>
          </TicketsListItem>
        );
      })}
    </TicketsList>
  );
};

TicketsListComponent.propTypes = propTypes;
export default TicketsListComponent;
