import BaseAPI from '../BaseAPI';

/**
 * Api class for handling bookings
 */
class Bookings extends BaseAPI {
  /**
   * Creates Bookings instance
   *
   * @param props {Object}
   * @return {Bookings}
   */
  constructor(props) {
    super(props);

    this.baseUrl = '/users/bookings';
  }

  /**
   * Retrieves bookings
   * @return {Promise<*|Error>}
   */
  getBookings() {
    const url = `${this.baseUrl}`;

    return this.get({ url });
  }

  /**
   * Retrieves a single booking
   *
   * @param cinemaId {string}
   * @param bookingId {string}
   * @return {Promise<*|Error>}
   */
  fetchBooking(cinemaId, bookingId) {
    const url = `${this.baseUrl}/get-single/${cinemaId}/${bookingId}`;

    return this.get({ url });
  }

  /**
   * Cancels tickets reservation
   *
   * @param cinemaId {string}
   * @param bookingNumber {string}
   * @return {Promise<*|Error>}
   */
  cancelReservation(cinemaId, bookingNumber) {
    const url = `${this.baseUrl}/cancel/${cinemaId}/${bookingNumber}`;

    return this.get({ url });
  }

  /**
   * Buys reserved ticket
   *
   * @param bookingNumber {string}
   * @return {Promise<*|Error>}
   */
  buyReservation(bookingNumber) {
    const url = `${this.baseUrl}/${bookingNumber}/payment`;

    return this.post({ url });
  }

  /**
   * Refund Tickets
   *
   * @param cinemaId
   * @param bookingNumber
   * @return {Promise<*|Error>}
   */
  refundTicket(cinemaId, bookingNumber) {
    const url = `${this.baseUrl}/refund/${cinemaId}/${bookingNumber}`;

    return this.get({ url });
  }

  /**
   * Refund Anonymous Tickets
   *
   * @param bookingNumber
   * @param email
   * @return {Promise<*|Error>}
   */
   refundAnonymousTicket(bookingNumber, email) {
    const url = `${this.baseUrl}/refundanonym/${bookingNumber}`;

    const body = { email }

    return this.post({ url, body });
  }
}

export default Bookings;
