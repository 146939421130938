export const REQUEST_WATCHLIST = 'watchlist/REQUEST';
export const REQUEST_WATCHLIST_ADD_MOVIE = 'watchlist/REQUEST_ADD_MOVIE';
export const REQUEST_WATCHLIST_ADD_EVENT = 'watchlist/REQUEST_ADD_EVENT';
export const REQUEST_WATCHLIST_REMOVE_MOVIE = 'watchlist/REQUEST_REMOVE_MOVIE';
export const REQUEST_WATCHLIST_REMOVE_EVENT = 'watchlist/REQUEST_REMOVE_EVENT';

export const SET_WATCHLIST = 'watchlist/SET_WATCHLIST';
export const SET_WATCHLIST_MOVIES = 'watchlist/SET_MOVIES';
export const SET_WATCHLIST_EVENTS = 'watchlist/SET_EVENTS';
export const SET_WATCHLIST_ERROR = 'watchlist/SET_ERROR';
export const RESET_WATCHLIST = 'watchlist/RESET_WATCHLIST';

export const ANALYTICS_WATCHLIST_STATUS = 'watchlist/ANALYTICS_STATUS';
