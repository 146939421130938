import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { List } from './styles';
import Toaster from './Toaster.component';

const propTypes = {
  toasters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['info', 'warning']).isRequired,
      message: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(
          PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
              [PropTypes.string]: PropTypes.string
            })
          ])
        )
      ]).isRequired
    })
  ),

  remove: PropTypes.func.isRequired
};

const defaultProps = {
  toasters: []
};

const Toasters = ({ toasters, remove }) => {
  const [t] = useTranslation();

  return toasters.length ? (
    <List>
      {toasters.map(({ id, type, message }) => (
        <Toaster key={id} id={id} type={type} remove={remove}>
          {message instanceof Array ? t(...message) : t(message)}
        </Toaster>
      ))}
    </List>
  ) : (
    ''
  );
};

Toasters.defaultProps = defaultProps;
Toasters.propTypes = propTypes;
export default Toasters;
