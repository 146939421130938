import {
  BOOKED_TICKETS_SUCCESS,
  BOOKED_TICKETS_CLEAR,
  CANCEL_RESERVATION_SUCCESS,
  CANCEL_RESERVATION_CLEAR,
  BOOKING_SET,
  BOOKING_CLEAR,
  CODE_SET,
  CODE_CLEAR,
  REFUND_TICKETS_SUCCESS,
  REFUND_ANONYMOUS_TICKETS_SUCCESS,
  BOOKING_REFUND
} from './types';

const initialState = {
  booking: null,
  tickets: [],
  cancelledReservation: null,
  code: null,
  refunded: null
};

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case BOOKED_TICKETS_SUCCESS: {
      const { tickets } = payload;

      const ticketList = tickets
        .filter(item => 'session' in item)
        .sort((a, b) => {
          const d1 = new Date(a.session.showtime).getTime();
          const d2 = new Date(b.session.showtime).getTime();

          if (d1 > d2) return 1;
          if (d1 < d2) return -1;

          return 0;
        });

      return {
        ...state,
        tickets: ticketList
      };
    }

    case BOOKED_TICKETS_CLEAR: {
      return {
        ...state,
        tickets: []
      };
    }

    case CANCEL_RESERVATION_SUCCESS: {
      return {
        ...state,
        cancelledReservation: payload.reservation
      };
    }

    case CANCEL_RESERVATION_CLEAR: {
      return {
        ...state,
        cancelledReservation: null
      };
    }

    case BOOKING_SET: {
      return {
        ...state,
        booking: payload.booking
      };
    }

    case BOOKING_CLEAR: {
      return {
        ...state,
        booking: null
      };
    }

    case CODE_SET: {
      return {
        ...state,
        code: payload.code
      };
    }

    case CODE_CLEAR: {
      return {
        ...state,
        code: null
      };
    }

    case REFUND_TICKETS_SUCCESS: {
      return {
        ...state,
        refunded: payload.refundTicket
      };
    }

    case REFUND_ANONYMOUS_TICKETS_SUCCESS: {
      return {
        ...state,
        refunded: payload.refundAnonymousTicket
      };
    }

    case BOOKING_REFUND: {
      return {
        ...state,
        refunded: null
      };
    }

    default: {
      return state;
    }
  }
}
