/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable array-callback-return  */
/* eslint-disable jsx-a11y/anchor-is-valid  */
import { useEffect } from 'react';

export default function(form, errors, isSubmitSuccess, submitting) {
  useEffect(() => {
    if ((!errors || isSubmitSuccess) && !submitting) {
      form.reset();
    }
  }, [errors, isSubmitSuccess, submitting]);
}
