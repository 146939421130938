import styled from 'styled-components';

export const ListSocial = styled.ul.attrs({
  className: 'l-social'
})`
  flex-shrink: 0;
  margin: 0 -8px;
  font-size: 0;
`;

export const ListSocialItem = styled.li.attrs({
  className: 'l-social__item'
})`
  .l-social__item-link {
    padding: 8px;

    &:hover {
      & > .svg-facebook,
      & > .svg-instagram,
      & > .svg-youtube,
      & > .svg-twitter {
        opacity: 0.9;
        transition: transform 0.25s ease-out, opacity 0.25s ease-in, box-shadow 0.25s ease-out;
        transform: scaleX(-1) rotateY(180deg);
        box-shadow: 0 3px 16px 0 ${({ theme }) => theme.color.rgbaWhite('.16')};
      }
      & > .svg-tiktok
      {
        filter: brightness(140%); 
       transition: transform 0.25s ease-out, opacity 0.25s ease-in, box-shadow 0.25s ease-out;
       transform: scaleX(-1) rotateY(180deg);
       box-shadow: 0 3px 16px 0 ${({ theme }) => theme.color.rgbaWhite('.16')};
     }
    }

    & > .svg-facebook,
    & > .svg-instagram,
    & > .svg-youtube,
    & > .svg-twitter {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      transition: opacity 0.2s ease-out, box-shadow 0.2s ease-out;
      opacity: 0.6;
      fill: #fff;
    }
    & .svg-tiktok{
      width: 32px;
      height: 32px;
      border-radius: 50%;
      transition: opacity 0.2s ease-out, box-shadow 0.2s ease-out;
      fill: #fff;
    }
  }
`;
