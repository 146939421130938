import { TECHNOLOGIES_SUCCESS, TECHNOLOGY_EVENTS_SUCCESS, NEWSLETTER_POPUP_SUCCESS, PRIVACY_POLICY_SUCCESS, TECHNOLOGY_REDIRECT_SUCCESS } from './types';

const initialState = {
  technologies: [],
  technologyEvents: [],
  newsLetter: [],
  privacyPolicy: [],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case TECHNOLOGIES_SUCCESS: {
      return {
        ...state,
        technologies: payload.technologies?.data
      };
    }

    case TECHNOLOGY_EVENTS_SUCCESS: {
      return {
        ...state,
        technologyEvents: payload.events?.data[0]?.events
      };
    }

    case TECHNOLOGY_REDIRECT_SUCCESS: {
      console.log("payload", payload.redirecurl?.data[0]?.domainRedirects)
      return {
        ...state,
        domainRedirectURL: payload.redirecurl?.data[0]?.domainRedirects
      };
    }

    case NEWSLETTER_POPUP_SUCCESS: {
      return {
        ...state,
        newsLetter: payload.newsletter?.data
      };
    }
    case PRIVACY_POLICY_SUCCESS: {
      return {
        ...state,
        privacyPolicy: payload.privacyPolicy?.data
      };
    }

    default: {
      return state;
    }
  }
}
