import { connect } from 'react-redux';
import layoutModule from 'app/store/layout';
import information from 'app/store/information';
import Error from './Error.component';

const { clearError, reportError, requestLocations } = layoutModule.actions;
const { requestMainUrl } = information.actions;

const mapStateToProps = ({ layout, information }) => ({
  error: layout.error || undefined,
  mainUrl: information?.mainUrl,
  locations: layout.locations,
});

const mapDispatchToProps = {
  clearError,
  reportError,
  fetchMainUrl: requestMainUrl,
  getLocations: requestLocations,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Error);
