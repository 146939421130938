import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Svg, Trigger } from 'app/components';
import SessionState from './styles';

export default () => {
  const [t] = useTranslation();
  const [isVisible, toggleVisibility] = useState(false);

  return (
    <SessionState>
      <button
        type="button"
        className="b-session-state__action"
        onClick={() => toggleVisibility(!isVisible)}
      >
        <span>{t('movies.session_state')}</span>
        <span>
          {isVisible ? null : (
            <Fragment>
              <Svg id="tickets-noreserve" />
              <Svg id="tickets-desk" />
              <Svg id="tickets-locked" />
            </Fragment>
          )}
          <object>
            <Trigger isExpanded={isVisible}>
              <span className="_with-chevron" />
            </Trigger>
          </object>
        </span>
      </button>
      <div className={`b-session-state__inner${isVisible ? ' _visible' : ''}`}>
        <p className="b-session-state__description">
          <Svg id="tickets-noreserve" />
          <span className="b-session-state__description-text">{t('movies.only_online')}</span>
        </p>
        <p className="b-session-state__description">
          <Svg id="tickets-desk" />
          <span className="b-session-state__description-text">{t('movies.only_desk')}</span>
        </p>
        <p className="b-session-state__description">
          <Svg id="tickets-locked" />
          <span className="b-session-state__description-text">{t('movies.locked')}</span>
        </p>
      </div>
    </SessionState>
  );
};
