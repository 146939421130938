import {
  SET_PFD,
  CLEAR_PDF,
  BY_ORDER_REQUEST,
  BY_ORDER_SUCCESS,
  BY_BOOKING_REQUEST,
  BY_BOOKING_SUCCESS
} from './types';

export const setPDF = pdf => ({
  type: SET_PFD,
  payload: {
    pdf
  }
});

export const clearPDF = () => ({
  type: CLEAR_PDF
});

export const requestByOrder = orderId => ({
  type: BY_ORDER_REQUEST,
  payload: {
    orderId
  }
});
export const byOrderSuccess = pdf => ({
  type: BY_ORDER_SUCCESS,
  payload: {
    pdf
  }
});

export const requestByBooking = (cinemaId, bookingId) => ({
  type: BY_BOOKING_REQUEST,
  payload: {
    cinemaId,
    bookingId
  }
});
export const byBookingSuccess = pdf => ({
  type: BY_BOOKING_SUCCESS,
  payload: {
    pdf
  }
});
