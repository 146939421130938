import { compose } from 'redux';
import { connect } from 'react-redux';
import withFormValidation from 'app/HOC/withFormValidation';
import BookingsModule from 'app/store/bookings';
import { schema } from './RefundTickets.schema';
import RefundTickets from './RefundTickets.component';

const { refundAnonymousTicketRequest } = BookingsModule.actions;

const mapDispatchToProps = {
  refundTicket: refundAnonymousTicketRequest
};

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  withFormValidation(schema)
)(RefundTickets);
