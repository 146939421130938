const ACCESS_TOKEN = 'accessToken';
const REFRESH_TOKEN = 'refreshToken';
const LOCATION = 'location';
const DEFAULT_LOCATION = 'all';
const CINEMA = 'cinemaId';
const DEFAULT_CINEMA = 'all';
const ORDER_ID = 'orderId';
const COOKIES_AGREEMENT = 'cookiesAgreement';
const LANGUAGE = 'i18nextLng';
const NEXT_LANGUAGE = 'i18nextLng';

class Storage {
  constructor() {
    this.storage = localStorage;
  }

  getAccessToken() {
    return this.storage.getItem(ACCESS_TOKEN);
  }

  setAccessToken(value) {
    this.storage.setItem(ACCESS_TOKEN, value);
  }

  removeAccessToken() {
    this.storage.removeItem(ACCESS_TOKEN);
  }

  getRefreshToken() {
    return this.storage.getItem(REFRESH_TOKEN);
  }

  setRefreshToken(value) {
    this.storage.setItem(REFRESH_TOKEN, value);
  }

  removeRefreshToken() {
    this.storage.removeItem(REFRESH_TOKEN);
  }

  getLocation() {
    return this.storage.getItem(LOCATION) || DEFAULT_LOCATION;
  }

  setLocation(value) {
    this.storage.setItem(LOCATION, value);
  }
  
  getCinema() {
    return this.storage.getItem(CINEMA) || DEFAULT_CINEMA;
  }

  setCinema(value) {
    this.storage.setItem(CINEMA, value);
  }

  getOrderId() {
    return this.storage.getItem(ORDER_ID);
  }

  setOrderId(value) {
    return this.storage.setItem(ORDER_ID, value);
  }

  removeOrderId() {
    this.storage.removeItem(ORDER_ID);
  }

  acceptCookiesAgreement() {
    this.storage.setItem(COOKIES_AGREEMENT, 'true');
  }

  declineCookiesAgreement() {
    this.storage.setItem(COOKIES_AGREEMENT, 'false');
  }

  getCookiesAgreementStatus() {
    return this.storage.getItem(COOKIES_AGREEMENT);
  }

  getLanguage() {
    return this.storage.getItem(LANGUAGE);
  }

  getNextLanguage() {
    window.addEventListener('CookiebotOnAccept', function () {
      if(window?.Cookiebot?.consent?.preferences){
        return this.storage.getItem(NEXT_LANGUAGE);
      }
    }, false)
    window.addEventListener('CookiebotOnDecline', function () {
      if(window?.Cookiebot?.consent?.preferences){
        return this.storage.getItem(NEXT_LANGUAGE);
      }
    }, false)
    // return this.storage.getItem(NEXT_LANGUAGE);
  }
}

export default new Storage();
