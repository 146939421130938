/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable array-callback-return  */
/* eslint-disable jsx-a11y/anchor-is-valid  */
import React, { useEffect, useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { NavLink, generatePath, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ConfigContext from 'app/context/config';
import useUrlParams from 'app/hooks/useUrlParams';
import { onHoverBehavior, onClickBehavior } from 'app/utils';
import { useConfig, useUtils } from 'app/hooks';
import { NavTag, NavList, NavListItem, SubList, SubListItem } from './styles';
import { Modal } from 'app/components';
import useModal from 'app/hooks/useModal';

const propTypes = {
  cinemas: PropTypes.arrayOf(PropTypes.object).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  savedLocation: PropTypes.string.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  // userName: PropTypes.string.isRequired
};

const BlockNav = ({ cinemas, locations, savedLocation, isLoggedIn }) => {
  const [t, i18n] = useTranslation();
  const { routes, country, showingOptions } = useContext(ConfigContext);
  const { date } = useUrlParams('date');
  const [cinemasToShow, setCinemas] = useState([]);
  const locationPath = useLocation();
  const {
    // giftCardShop, 
    streamingUrl,
    giftCardShopEN,
    giftCardShopDE,
    vodNotLoggedInUrlEN,
    vodNotLoggedInUrlDE
  } = useConfig();
  // eslint-disable-next-line no-unused-vars
  const [streamingFunction, showStreamingFunction, hideStreamingFunction] = useModal('Streaming');
  const refreshToken = localStorage.getItem('refreshToken');
  const streamingUrlSet = streamingUrl + "?refresh_token=" + refreshToken;
  const { getFallbackDate } = useUtils();

  const clubList = useMemo(
    () => [
      {
        id: 1,
        label: 'bonusCard',
        name: t('common.bonus_card'),
        route: routes.bonusCardInfo.pattern
      },
      {
        id: 2,
        label: 'familyFilmclub',
        name: t('common.family_film_club'),
        route: routes.familyFilmClub.pattern
      },
      {
        id: 3,
        label: 'competitions',
        name: t('common.competitions'),
        route: routes.competitionInfo.pattern
      }
    ],
    [t]
  );

  const giftcardList = useMemo(
    () => [
      {
        id: 1,
        label: 'giftcardShop',
        name: t('common.giftcard_shop'),
        route: routes.bonusCardInfo.pattern
      },
      {
        id: 2,
        label: 'giftcardInfo',
        name: t('common.giftcard_info'),
        route: routes.giftCardInfo.pattern
      }
    ],
    [t]
  );

  const NavListProps = useMemo(
    () => ({
      ...(country !== 'aut' && onHoverBehavior),
      ...(country !== 'aut' && { chevron: true })
    }),
    [country, onHoverBehavior]
  );

  useEffect(() => {
    if (savedLocation === 'all') {
      setCinemas(cinemas);
    } else {
      /* eslint-disable no-param-reassign */
      const items = locations.reduce((res, l) => {
        if (l.id.toString() === savedLocation) {
          res = l.items;
        }

        return res;
      }, []);
      /* eslint-enable no-param-reassign */

      if (items.length) {
        setCinemas(cinemas.filter(c => items.some(i => i.toString() === c.id)));
      }
    }
  }, [locations, cinemas, savedLocation]);


  const toTitleCase = (str) => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <NavTag>
      <NavList>
        <Modal
          isOpen={streamingFunction}
          close={hideStreamingFunction}
          title={"Streaming"}
        >
          <h3>{t('common.streaming_condition')}</h3>
        </Modal>
        <NavListItem isLoggedIn={isLoggedIn}>
          <NavLink
            to={{
              pathname: routes.movies.pattern,
              search: `?category=now&location=${savedLocation}${date ? `&date=${date}` : ''}`
            }}
            activeClassName="_active"
            exact
          >
            {t('common.movies')}
          </NavLink>
        </NavListItem>
        <NavListItem isLoggedIn={isLoggedIn}>
          <NavLink
            to={{
              pathname: routes.events.pattern,
              search: `?location=${savedLocation}&date=all`
            }}
            activeClassName="_active"
            exact
          >
            {t('common.events')}
          </NavLink>
        </NavListItem>
        <NavListItem {...NavListProps} isLoggedIn={isLoggedIn}>
          <NavLink
            to={{
              pathname: routes.cinemas.pattern,
              search: `?location=${savedLocation}${(date === 'all' || date === undefined) ? `&date=${getFallbackDate([])}` : `&date=${date}`}`
            }}
            activeClassName="_active"
            exact
          >
            {t('common.cinemas')}
          </NavLink>
        </NavListItem>
        {(!showingOptions?.club?.bonusCard && !showingOptions?.club?.familyFilmClub && !showingOptions?.club?.competitions) ? null : showingOptions?.member && (
          <NavListItem {...onHoverBehavior} chevron isLoggedIn={isLoggedIn}>
            <NavLink
              to={{
                pathname: routes.bonusCardInfo.pattern
              }}
              activeClassName="_active"
              exact
            // className="_with-chevron"
            >
              {t('common.club')}
            </NavLink>
            {/* <span className="_with-chevron">{t('common.club')}</span> */}
            <SubList>
              {clubList.map(
                ({ id, label, name, route }) =>
                  showingOptions?.club?.[label] && (
                    <SubListItem key={id} {...onClickBehavior}>
                      <NavLink
                        className="l-sublist__link"
                        to={{
                          pathname: generatePath(route)
                        }}
                        activeClassName="_active"
                        exact
                      >
                        {/* {toTitleCase(name)} */}
                        {name}
                      </NavLink>
                    </SubListItem>
                  )
              )}
            </SubList>
          </NavListItem>
        )}
        {(!showingOptions?.giftCards?.giftcardShop && !showingOptions?.giftCards?.giftcardInfo) ? null : showingOptions?.giftcards && (
          <NavListItem {...onHoverBehavior} chevron isLoggedIn={isLoggedIn}>
            <a href={i18n.language === 'en' || i18n.language === 'en-US' ? giftCardShopEN : giftCardShopDE}
              // className="_with-chevron"
              activeClassName="_active">
              {t('common.giftcards')}
            </a>
            <SubList>
              {giftcardList.map(
                ({ id, label, name, route }) =>
                  showingOptions?.giftCards?.[label] && (
                    <SubListItem key={id} {...onClickBehavior}>
                      {name === t('common.giftcard_shop') ? (
                        <a
                          href={i18n.language === 'en' || i18n.language === 'en-US' ? giftCardShopEN : giftCardShopDE}
                          className="l-sublist__link"
                          activeClassName="_active"
                          exact="true"
                        >
                          {/* {toTitleCase(name)} */}
                          {name}
                        </a>
                      ) : (
                        <NavLink
                          className="l-sublist__link"
                          to={{
                            pathname: generatePath(route)
                          }}
                          activeClassName="_active"
                          exact
                        >
                          {/* {toTitleCase(name)} */}
                          {name}
                        </NavLink>
                      )}
                    </SubListItem>
                  )
              )}
            </SubList>
          </NavListItem>
        )}
        {showingOptions?.cinemaRent && (
          <NavListItem isLoggedIn={isLoggedIn}>
            <NavLink
              to={{
                pathname: routes.cinemaRent.pattern
              }}
              activeClassName="_active"
              exact
            >
              {t('common.cinema_rent')}
            </NavLink>
          </NavListItem>
        )}
        {showingOptions?.streaming && (
          <NavListItem isLoggedIn={isLoggedIn}>
            {isLoggedIn ?
              <a
                activeClassName="_active"
                exact="true"
                href={streamingUrlSet}
              >
                {t('common.streaming')}
              </a>
              : <a
                activeClassName="_active"
                exact="true"
                href={18n.language === 'en' ? vodNotLoggedInUrlEN : vodNotLoggedInUrlDE}
              >
                {t('common.streaming')}
              </a>
            }
          </NavListItem>
        )}
      </NavList>
    </NavTag>
  );
};

BlockNav.propTypes = propTypes;
export default BlockNav;
