import { SET_SESSION, CLEAR_SESSION, WIZARD_CLEAR, WIZARD_SET } from './types';

const initialState = {
  session: null,
  wizard: {
    isBonus: false,
    child: 0,
    adult: 0,
    reduced: 0,
    packageRegular: 0,
    packageChild: 0,
    packageReduced: 0,
    packageClub: 0,
    isGiftCardApplied: false
  }
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case SET_SESSION: {
      return {
        ...state,
        session: { ...payload.sessionData }
      };
    }

    case CLEAR_SESSION: {
      return {
        ...state,
        session: null
      };
    }

    case WIZARD_SET: {
      return {
        ...state,
        wizard: payload.wizard
      };
    }

    case WIZARD_CLEAR: {
      return {
        ...state,
        wizard: {}
      };
    }

    default: {
      return state;
    }
  }
}
