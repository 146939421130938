import styled from 'styled-components';

const Error = styled.p`
  margin: 10px 0 0 16px;
  line-height: 1;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.color.accent};
`;

export default Error;
