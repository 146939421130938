import _rules from './rules';
import _messages from './messages';

const ruleIsInvalid = (val, { rule, options }, values) => _rules[rule](val, options, values);
const getDefaultMessage = ({ rule, options }) => _messages[rule](options);

const validateForm = schema => values => {
  const errors = {};
  const fields = Object.keys(schema);

  for (let i = 0; i < fields.length; i += 1) {
    const rules = schema[fields[i]];
    const value = values[fields[i]] || '';

    for (let j = 0; j < rules.length; j += 1) {
      const rule = rules[j];

      if (ruleIsInvalid(value, rule, values)) {
        errors[fields[i]] = rule.message || getDefaultMessage(rule);
        break;
      }
    }
  }

  return errors;
};

export default validateForm;
