import styled from 'styled-components';
import { textTransform } from 'utils';

export const Container = styled.div`
  text-align: center;

  > svg {
    width: 48px;
    height: 48px;
    margin-bottom: ${({ theme }) => theme.indent.m};
  }

  .b-modal-default__title {
    margin-bottom: ${({ theme }) => theme.indent.m};
  }

  .b-modal-default__message {
    margin-bottom: ${({ theme }) => theme.indent.l};
  }

  .b-modal-default__btn {
    min-width: 200px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    .title {
      margin-bottom: 12px;
    }
  }
`;

export const Title = styled.h4.attrs({
  className: 'b-modal-default__title'
})`
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.33;
  text-transform: ${textTransform};

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
    font-size: 1.6rem;
  }
`;

export const Message = styled.div.attrs({
  className: 'b-modal-default__message'
})`
  font-size: 1.6rem;
  line-height: 1.5;
`;

export const Heading = styled.div.attrs({
  className: 'b-modal-default__message'
})`
  font-size: 2.8rem;
  line-height: 1.3; 
  text-transform: ${textTransform};
`;

export const ButtonsRow = styled.div`
  text-align: right;

  button + button {
    min-width: 128px;
    margin: 0 0 0 ${({ theme }) => theme.indent.m};
  }
`;
