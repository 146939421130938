import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { sublistItemInner, withChevron, withChevronHoverMobile } from 'app/theme';
import { textTransform } from 'utils';

export const LoggedWrapper = styled.div.attrs({ className: 'header__logged' })`
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 0px;
  cursor: default;
  ${({ chevron }) => chevron && withChevron}

  & > .svg-user {
    vertical-align: middle;
    width: 24px;
    height: 24px;
    margin-top: 2px;
    margin-right: ${({ theme }) => theme.indent.s};
    fill: ${({ theme }) => theme.color.rgbaWhite('.8')};
  }

  & > span {
    vertical-align: middle;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.color.textPrimary};
  }
  @media screen and (max-width:${({ theme }) => theme.breakPoints.sm.width}){
    & > span {
      font-size:1.5rem;
    }
  }
  & > .l-sublist {
    right: 0;
    left: auto;
  }

  &._hover {
    & > span {
      ${({ chevron }) => chevron && withChevronHoverMobile}
    }

    & > .l-sublist {
      opacity: 1;
      pointer-events: auto;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    position: static;

    & > .svg-user {
      display: none;
    }

    & > .l-sublist {
      top: 50px;
      right: 16px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
    & > .l-sublist {
      right: 8px;
    }
  }
`;

export const SearchLink = styled(NavLink)`
  /* margin-right: ${({ isloggein }) => (isloggein === 'true' ? '24px' : '8px')}; */
  margin-right: ${({ isloggein }) => (isloggein === 'true' ? '24px' : '18px')};

  & > .svg-search {
    width: 25px;
    height: 25px;
    margin-top: 2px;
    fill: ${({ theme }) => theme.color.rgbaWhite('.8')};
    transition: fill 0.2s ease-out;
  }

  &:hover,
  &._active {
    & > .svg-search {
      fill: ${({ theme }) => theme.color.rgbaWhite('1')};
    }
  }

  &._active {
    pointer-events: none;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    display: none;
  }
`;

export const BlockControlWrapper = styled.div.attrs({
  className: 'header__control'
})`
  display: flex;
  align-items: center;
  margin-right: -18px;
  
`;

export const LanguageSwitcher = styled.div.attrs({
  className: 'header__language-switcher'
})`
  display: flex;
  align-items: center;
  position: relative;
  font-size: 2rem;
  cursor: default;
  ${({ chevron }) => chevron && withChevron}

  .svg-language {
    width: 24px;
    height: 24px;
    margin-top: 2px;
    fill: ${({ theme }) => theme.color.rgbaWhite('.8')};
  }

  .header__logged + & {
    margin-left: ${({ theme }) => theme.indent.l};
    // padding-right:10px;
  }

  .header__loggedout + & {
    // margin-left: 8px;
    margin-left: 4px;
  }

  & > span {
    display: inline-block;
    width: 40px;
    font-size: 1.6rem;
    margin-top: 0px;
    font-weight: 700;
    text-transform: ${textTransform};
    margin-left: 4px;
  }

  & > .l-sublist {
    right: 18px;
    left: auto;
  }

  &._hover {
    & > span {
      ${({ chevron }) => chevron && withChevronHoverMobile}
    }

    & > .l-sublist {
      opacity: 1;
      pointer-events: auto;
    }
  }
`;

export const NavTag = styled.nav.attrs({
  className: 'header__nav'
})`
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    display: none;
  }
`;

export const IsMobile = styled.div.attrs({
  className: 'header_mobile_nav'
})`
@media screen and (min-width: ${({ theme }) => theme.breakPoints.md.width}) {
  display: none;
}
float:right
/* margin-right: 15px; */
margin-bottom: 1px;
img.mobileMenu.LeftArrowIcon {
  mix-blend-mode: color-dodge;
  margin-bottom: 0px;
  margin-right: 15px;
}
img.mobileMenu {
  margin-bottom: 4px;
}
img.mobileMenu {
  /* width: 30px; */
    width: 28px;
    height: 25px;    
    margin-bottom: 1px;
}
img.mobileMenuClose {
  width: 26px;
  padding: 4px;
}
li.l-header-nav__item:last-child {
  border: none;
}
li._hover {
  padding: 0px !important;
}
li._hover span._with-chevron {
  padding: 10px 0px 10px 10px;
  background: #2D2D2D !important;
  border-radius: 5px 5px 0px 0px;
}
li._hover ul.l-sublist a._active {
  border-radius: unset;
}
li._hover ul.l-sublist li.l-sublist__item a.l-sublist__link {
  color: #a1a1a1;
  font-size: 16px;
  /* padding-left: 50px; */
}
`;

export const NavListMobile = styled.div.attrs({
  className: 'mobile_nav'
})`
  /* background: #222222;
  font-size: 14px;
  position: absolute;
  top: 100%;
  width: 80%;
  border-radius: 5px !important;
  margin-top: 5px;
  box-shadow: 0px 6px 11px 6px #00000080; */

    background: #222222;
    font-size: 14px;
    position: absolute;
    bottom: 106%;
    width: 50%;
    right: 6%;
    border-radius: 5px !important;
    margin-top: 5px;
    box-shadow: 0px 6px 11px 6px #00000080;
    
  a._active{
    color: #de1619 !important;
  }
  ul li {
    display: block !important;
  }
  .l-header-nav__item a, span._with-chevron {
    color: #fff;
    font-weight: 100;
  }
  .l-sublist {
    box-shadow: none;
    border-radius: 0px;
    width: auto;
    background: transparent;
  }
  .l-header-nav__item {
    margin-left: 0px !important;
    padding: 10px;
    border-bottom: 1px solid #4c4c4c;
  }
  .l-header-nav ._hover .l-sublist{
    display: block;
    position: initial;
  }
  .l-sublist{
    display: block;
  }
  li._hover span._with-chevron{
      width: 100%;
      display: block;
  }
  span.chevron_arrow {
    display: block;
}
._with-chevron::after{
  margin-left: 10px;
  right: unset !important;
}
.l-header-nav > ._hover span::after {
  transform: rotate(315deg) translate(-4px,-9px);
  top: 50%;
  margin-left: 20px;
}
.l-header-nav .l-header-nav__item .chevron_arrow span::after{
  transform: rotate(135deg);
}
._hover .chevron_arrow{
  border-bottom: 1px solid #4c4c4c;
}
`;

export const NavList = styled.ul.attrs({
  className: 'l-header-nav'
})`
    text-align:start;
`;
export const NavListItem = styled.li.attrs({
  className: 'l-header-nav__item'
})`
  font-weight: 700;
  line-height: 1.5;
  color: #fff;
  text-transform: ${textTransform};
  position: relative;

  & > a,
  & > span {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.color.textSecondary};
    transition: color 0.2s ease-out;
    cursor: pointer;
  }

  a._active {
    color: ${({ theme }) => theme.color.textPrimary};
    pointer-events: none;
  }

  & > .l-sublist {
    left: 0;
  }

  & + & {
    margin-left: ${({ isLoggedIn }) => (isLoggedIn ? '14px' : '10px')};
  }

  ${({ chevron }) => chevron && withChevron};

  &:hover {
    & > a,
    & > span {
      color: ${({ theme }) => theme.color.textPrimary};
      ${({ chevron }) => chevron && withChevronHoverMobile};
    }
  }

  &._hover {
    & > .l-sublist {
      opacity: 1;
      pointer-events: auto;
    }
  }
`;

export const SubList = styled.ul.attrs({
  className: 'l-sublist'
})`
  width: 288px;
  position: absolute;
  top: 46px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-color: ${({ theme }) => theme.color.bgPopup};
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-out;

  &::before {
    content: '';
    width: 100%;
    height: 30px;
    position: absolute;
    top: -30px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    top: 37px;
    &::before {
      display: none;
    }
  }
`;

export const SubListItem = styled.li.attrs({
  className: 'l-sublist__item'
})`
  display: block;

  & > .l-sublist__link {
    ${sublistItemInner}
    text-transform:none;

    &._active {
      background-color: ${({ theme }) => theme.color.rgbaWhite('.05')};
    }

    .header__logged & {
      justify-content: flex-start;
    }
  }

  .l-sublist__link {
    & > .svg-account,
    & > .svg-tickets,
    & > .svg-history,
    & > .svg-logout {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      fill: ${({ theme }) => theme.color.accent};
    }
  }

  & > button {
    & > .svg-checkmark {
      width: 12px;
      height: 12px;
      fill: ${({ theme }) => theme.color.accent};
    }
  }

  & + & {
    border-top: 1px solid ${({ theme }) => theme.color.borderDark};
  }

  &:first-child > .l-sublist__link {
    border-radius: 7px 7px 0 0;
  }

  &:last-child > .l-sublist__link {
    border-radius: 0 0 7px 7px;
  }

  &:only-child > .l-sublist__link {
    border-radius: 7px;
  }

`;
