import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import authModule from 'app/store/auth';

const { logoutRequest } = authModule.actions;

export default function() {
  const dispatch = useDispatch();

  return useCallback(() => dispatch(logoutRequest()), [dispatch]);
}
