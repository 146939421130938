import React from 'react';
import PropTypes from 'prop-types';
import { LocalizationProvider, MobileTimePicker  } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { MuiPickersUtilsProvider, TimePicker } from 'material-ui-pickers';
// import DateFnsUtils from '@date-io/date-fns';
// import { MuiThemeProvider } from '@material-ui/core';
import { ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import theme from './theme';

const propTypes = {
  value: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

const defaultProps = {
  maxDate: null
};

const TimePickerEl = ({ value, onChange, onClose, isOpen, maxDate }) => {
  const [t, i18n] = useTranslation();
  const { language } = i18n;

  const customButtonLocaleText = {
    okButtonLabel: "Ok",
    cancelButtonLabel: t('common.cancel')
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} localeText={customButtonLocaleText}>
      <ThemeProvider theme={theme}>
        <MobileTimePicker
          ampm={language !== 'de'}
          value={value}
          onChange={onChange}
          onClose={onClose}
          open={isOpen}
          openTo="hours"
          // ref={picker => picker && isOpen && picker.open()}
          // cancelLabel={t('common.cancel')}
        />
      </ThemeProvider>
    </LocalizationProvider>
  );
};

TimePickerEl.defaultProps = defaultProps;
TimePickerEl.propTypes = propTypes;

export default TimePickerEl;
