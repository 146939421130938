export const REWARDS_REQUEST = 'bonuscard/REWARDS_REQUEST';
export const REWARDS_SUCCESS = 'bonuscard/REWARDS_SUCCESS';

export const BALANCE_REQUEST = 'bonuscard/BALANCE_REQUEST';
export const BALANCE_SUCCESS = 'bonuscard/BALANCE_SUCCESS';

export const CONCESSIONS_REQUEST = 'bonuscard/CONCESSIONS_REQUEST';
export const CONCESSIONS_SUCCESS = 'bonuscard/CONCESSIONS_SUCCESS';

export const ORDER_CONCESSION_REQUEST = 'bonuscard/ORDER_CONCESSION_REQUEST';
export const ORDER_CONCESSION_SUCCESS = 'bonuscard/ORDER_CONCESSION_SUCCESS';

export const BONUS_CARD_INFO_REQUEST = 'bonuscard/BONUS_CARD_INFO_REQUEST';
export const BONUS_CARD_INFO_SUCCESS = 'bonuscard/BONUS_CARD_INFO_SUCCESS';
