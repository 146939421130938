export const LOGIN_REQUEST = 'auth/LOGIN_REQUEST';

export const LOGOUT_REQUEST = 'auth/LOGOUT_REQUEST';

export const REGISTER_REQUEST = 'auth/REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'auth/REGISTER_SUCCESS';

export const ACTIVATE_REQUEST = 'auth/ACTIVATE_REQUEST';

export const RESET_PASSWORD_REQUEST = 'auth/RESET_PASSWORD_REQUEST';

export const FORGOT_PASSWORD_REQUEST = 'auth/FORGOT_PASSWORD_REQUEST';

export const REFRESH_TOKEN_REQUEST = 'auth/REFRESH_TOKEN_REQUEST';
