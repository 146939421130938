import { SURVEY_SUCCESS } from './types';

const initialState = {
  survey: []
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case SURVEY_SUCCESS: {
      return {
        ...state,
        survey: payload?.survey?.data
      };
    }

    default: {
      return state;
    }
  }
}
