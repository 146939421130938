import BaseAPI from '../BaseAPI';

/**
 * Api class for handling payment
 */
class Payment extends BaseAPI {
  /**
   * Creates Payment instance
   *
   * @param props {Object}
   * @return {Payment}
   */
  constructor(props) {
    super(props);

    this.baseUrl = id => `/orders/${id}/payment`;
  }

  /**
   * Reserves tickets
   *
   * @param userSessionId {string}
   * @return {Promise<*|Error>}
   */
  reserve(userSessionId) {
    const url = `${this.baseUrl(userSessionId)}/reserveTickets`;

    return this.post({ url });
  }

  /**
   * Inits purchase of the tickets
   *
   * @param userSessionId {string}
   * @param email {string | undefined}
   * @return {Promise<*|Error>}
   */
  buy(userSessionId, email) {
    let url = `${this.baseUrl(userSessionId)}/start`;

    url = email ? `${url}?email=${email}` : url;

    return this.post({ url });
  }

  /**
   * Do purchase of the tickets with bonus card
   *
   * @param userSessionId {string}
   * @return {Promise<*|Error>}
   */
  buyWithBonusCard(userSessionId) {
    const url = `${this.baseUrl(userSessionId)}/with-bonus-card`;

    return this.post({ url });
  }

  /**
   * Do purchase of the tickets with bonus card
   *
   * @param userSessionId {string}
   * @param email {string | undefined}
   * @return {Promise<*|Error>}
   */
  buyWithGiftCard(userSessionId, payload, email) {
    let url = `${this.baseUrl(userSessionId)}/with-gift-card`;

    url = email ? `${url}?email=${email}` : url;

    const body = payload;

    return this.post({ url, body });
  }

  /**
   * Retrieves purchase details
   *
   * @param userSessionId {string}
   * @return {Promise<*|Error>}
   */
  getPurchase(userSessionId) {
    const url = `${this.baseUrl(userSessionId)}/tickets`;

    return this.get({ url });
  }

  /**
   * Get status of the purchase
   *
   * @param userSessionId {string} - Order id
   * @returns {Promise<*|Error>}
   */
  getStatusFinal(userSessionId) {
    const url = `${this.baseUrl(userSessionId)}/status-final`;

    return this.get({ url });
  }

  /**
   * Get status of a payment
   *
   * @param userSessionId {string} - Order id
   * @returns {Promise<*|Error>}
   */
  getStatus(userSessionId) {
    const url = `${this.baseUrl(userSessionId)}/status`;

    return this.get({ url });
  }

  /**
   * Applies giftcards to the payment
   *
   * @param userSessionId {string} - Order id
   * @param payload {[string]} - giftcard number
   * @returns {Promise<*|Error>}
   */
  applyGiftcards(userSessionId, payload) {
    const url = `${this.baseUrl(userSessionId)}/apply-gift-card`;

    const body = payload;

    return this.post({ url, body });
  }
}

export default Payment;
