import {
  GIFTCARDS_REQUEST,
  GIFTCARDS_SUCCESS,
  ADD_GIFTCARD_REQUEST,
  ADD_GIFTCARD_SUCCESS,
  RESTRICTIONS_REQUEST,
  RESTRICTIONS_SUCCESS,
  GIFT_CARD_LOCATION_REQUEST,
  GIFT_CARD_LOCATION_SET,
  CINEMAS_WITH_MOVIES_REQUEST_LOCATION,
  SET_CINEMAS_WITH_MOVIES_GET_LOCATION,
  DATES_REQUEST,
  SET_DATES,
  REQUEST_CONTENT_DATA,
  REQUEST_CONTENT_DATA_SUCCESS,
  ADD_GIFTCARD_REQUEST_LOGOUT,
  ADD_GIFTCARD_REQUEST_LOGOUT_SUCCESS,
  GIFTCARD_SESSION_CLEAR,
  ADD_GIFTCARD_REQUEST_TICKET,
  ADD_GIFTCARD_REQUEST_TICKET_SUCCESS,
  GIFTCARD_SET_AGAIN
} from './types';

export const giftcardsRequest = () => ({
  type: GIFTCARDS_REQUEST
});

export const giftcardsSuccess = giftcards => ({
  type: GIFTCARDS_SUCCESS,
  payload: { giftcards }
});

export const addGiftcardRequest = (giftcardNumber, notifyForm, t, userRestrictions) => ({
  type: ADD_GIFTCARD_REQUEST,
  payload: { giftcardNumber, notifyForm, t, userRestrictions }
});

export const addGiftcardSuccess = giftcard => ({
  type: ADD_GIFTCARD_SUCCESS,
  payload: { giftcard }
});

export const addLogoutGiftcardSend = (giftcardNumber, notifyForm) => ({
  type: ADD_GIFTCARD_REQUEST_LOGOUT,
  payload: { giftcardNumber, notifyForm }
});

export const addLogoutGiftcardSuccess = (giftcardData) => (
  {
    type: ADD_GIFTCARD_REQUEST_LOGOUT_SUCCESS,
    payload: { giftcardData }
  });

export const addTicketGiftcardRequest = (giftcardNumber, notifyForm, t, userRestrictions) => ({
  type: ADD_GIFTCARD_REQUEST_TICKET,
  payload: { giftcardNumber, notifyForm, t, userRestrictions }
});

export const addTicketGiftcardSuccess = (giftcardData) => (
  {
    type: ADD_GIFTCARD_REQUEST_TICKET_SUCCESS,
    payload: { giftcardData }
  });

export const dataSetAgainGiftcard = (giftcardDataAgain) => (
  {
    type: GIFTCARD_SET_AGAIN,
    payload: { giftcardDataAgain }
  });

export const restrictionsRequest = () => ({
  type: RESTRICTIONS_REQUEST
});

export const restrictionsSuccess = restrictions => ({
  type: RESTRICTIONS_SUCCESS,
  payload: { restrictions }
});

export const giftLocationInfoRequest = () => ({
  type: GIFT_CARD_LOCATION_REQUEST
});

export const setGiftLocationInfoContent = data => (
  {
    type: GIFT_CARD_LOCATION_SET,
    payload: { data }
  });

export const requestForCinemasWithMovies = (date, locationId) => (
  {
    type: CINEMAS_WITH_MOVIES_REQUEST_LOCATION,
    payload: { date, locationId }
  });

export const setCinemasWithMoviesLocation = (cinemas) => (
  {
    type: SET_CINEMAS_WITH_MOVIES_GET_LOCATION,
    payload: { cinemas }
  });

export const requestDates = ({ cinemaId, location } = {}) => ({
  type: DATES_REQUEST,
  payload: { cinemaId, location }
});
export const setDates = dates => ({
  type: SET_DATES,
  payload: { dates }
});

export const requestContentData = () => ({
  type: REQUEST_CONTENT_DATA
});

export const setContentData = data => (
  {
    type: REQUEST_CONTENT_DATA_SUCCESS,
    payload: { data }
  });

export const clearGiftcardSessionSend = () => ({
  type: GIFTCARD_SESSION_CLEAR
});