import styled from 'styled-components';

export const CustomDotWrapper = styled.div.attrs({
  className: 'custom-dot-wrapper'
})`
  .custom-dot-wrapper__custom-dot {
    border: none;
    margin: 10px 15px 27px;
    outline: none;
  }
  .custom-dot-wrapper__custom-dot--active {
    transform: scale(1.1);
    outline: auto;
    width: 108px;
    height: 70px;
  }

  .custom-dot-wrapper__custom-image {
    width: 108px;
    height: 70px;
    background-position: center;
    background-size: 400px 200px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
    .custom-dot-wrapper__custom-dot {
      margin: 8px 10px 20px;
    }
    .custom-dot-wrapper__custom-dot--active {
      transform: scale(1);
      outline: auto;
      width: 90px;
      height: 45px;
    }

    .custom-dot-wrapper__custom-image {
      width: 90px;
      height: 45px;
    }
  }
`;

export default CustomDotWrapper;
