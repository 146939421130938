import {
  REQUEST_WATCHLIST,
  REQUEST_WATCHLIST_ADD_MOVIE,
  REQUEST_WATCHLIST_ADD_EVENT,
  REQUEST_WATCHLIST_REMOVE_MOVIE,
  REQUEST_WATCHLIST_REMOVE_EVENT,
  SET_WATCHLIST,
  SET_WATCHLIST_MOVIES,
  SET_WATCHLIST_EVENTS,
  SET_WATCHLIST_ERROR,
  RESET_WATCHLIST,
  ANALYTICS_WATCHLIST_STATUS
} from './types';

/**
 * Watchlist actions
 */
export const requestWatchlist = () => ({
  type: REQUEST_WATCHLIST
});
export const setWatchlist = watchlist => ({
  type: SET_WATCHLIST,
  payload: {
    watchlist
  }
});
export const setWatchlistError = (id, error) => ({
  type: SET_WATCHLIST_ERROR,
  payload: {
    id,
    errorData: error
  }
});
export const resetWatchlist = () => ({
  type: RESET_WATCHLIST
});

/**
 *
 * Movies actions
 */
export const requestAddMovieToList = movieId => ({
  type: REQUEST_WATCHLIST_ADD_MOVIE,
  payload: {
    movieId
  }
});
export const setWatchlistMovies = movies => ({
  type: SET_WATCHLIST_MOVIES,
  payload: {
    movies
  }
});
export const requestRemoveMovieFromList = movieId => ({
  type: REQUEST_WATCHLIST_REMOVE_MOVIE,
  payload: {
    movieId
  }
});

/**
 *
 * Events actions
 */
export const requestAddEventToList = eventId => ({
  type: REQUEST_WATCHLIST_ADD_EVENT,
  payload: {
    eventId
  }
});
export const setWatchlistEvents = events => ({
  type: SET_WATCHLIST_EVENTS,
  payload: {
    events
  }
});
export const requestRemoveEventFromList = eventId => ({
  type: REQUEST_WATCHLIST_REMOVE_EVENT,
  payload: {
    eventId
  }
});

/**
 *
 * Analytics actions
 */

export const analyticsStatus = request => ({
  type: ANALYTICS_WATCHLIST_STATUS,
  payload: {
    request
  }
});
