import BaseAPI from '../BaseAPI';

class SessionsTicket extends BaseAPI {
  constructor(props) {
    super(props);

    this.baseUrl = '/season-pass-deals';
  }
  fetchSessionTickets({ permotionId, cinemaId }) {
    const url = `${this.baseUrl}/promotion/${permotionId}/${cinemaId}/sessions`;
    return this.get({ url });
  }

  fetchSessionBookingCount({ cinemaId, email, promotionId }) {
    const url = `${this.baseUrl}/past-bookings/cinema/${cinemaId}/abo/${promotionId}?email=${encodeURI(email)}`;
    return this.get({ url });
  }

  fetchSessionSeatPlan({ cinemaId, email, promotionId }) {
    const url = `/seat-plan/season-pass/cinema/${cinemaId}/abo/${promotionId}?email=${email}`;
    return this.get({ url });
  }
}

export default SessionsTicket;
