import styled from 'styled-components';
import { ShadowBox as BoxShadow } from 'app/components';
import cinema from 'cinema.jpg';
import { textTransform } from 'utils';

export const Background = styled.section`
  background: ${({ theme }) => theme.color.bgPrimary} url(${cinema}) 50% 0 no-repeat;
  background-size: cover;

  display: flex;
  flex: 1 0 auto;
  align-items: center;
`;

export const Title = styled.h1`
  margin-bottom: ${({ theme }) => theme.indent.m};
  font-size: 2.4rem;
  font-weight: 700;
  text-transform: ${textTransform};
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
    font-size: 2rem;
    line-height: 2.4rem;
  }
`;

export const ShadowBox = styled(BoxShadow)`
  background-color: rgba(25, 25, 25, 0.9);
`;
